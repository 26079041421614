import { Button, Form, InputNumber, PageHeader, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  IProductDetails,
  IProductPriceGroupResponse,
  IProductType,
  IProductUnits,
  ISearchProductPriceGroup,
  IUnits,
  IUpdateProductPrice
} from '../../../services/products/types';
import AppContent from '../../../components/Common/Content/Content';
import { ConvertObjectToURL } from '../../../utils/converturl';
import ProductSearch2 from '../../../components/Common/ProductSearch2/ProductSearch2';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { get_product_pricegroup_list, get_units_list } from '../../../services/products/queries';
import { useMutation } from '@tanstack/react-query';
import { update_product_pricegroup_mutation } from '../../../services/products/mutations';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { getProductById } from '@/services';
import redirectToNewTab from '@/utils/redirectToNewTab';

const { Option } = Select;

const PriceGroupDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const [productPriceInformation, setProductPriceInformation] =
    useState<IProductPriceGroupResponse>(Object);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [productDetails, setProductDetails] = useState<any>();

  const breadcrumbItems = [
    { label: 'Price Groups', link: '/price-groups' },
    { label: 'Details', link: '/price-groups/details/:id' }
  ];

  useEffect(() => {
    initializeUnits();
  }, []);

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const onSubmitFilter = async (filter: ISearchProductPriceGroup) => {
    setIsLoading(false);
    if (id) filter.priceGroupId = parseInt(id);
    const val = ConvertObjectToURL(filter);
    const product = await getProductById(filter.productId);
    const response = await get_product_pricegroup_list(val);
    // console.log('Response --->', response);
    if (response.length > 0) {
      form.setFieldValue(['sellingPrice'], response[0].sellingPrice);
      form.setFieldValue(['costPrice'], response[0].costPrice);
      setProductPriceInformation(response[0]);
    } else {
      // Selected products does not exist in this price group. Please add the product first
      CustomErrorModal({
        message: (
          <div>
            The selected product <span className="text-red-500">{product?.name}</span> does not have
            any price associated with it in this price group.
            <span className="block mt-2">
              Please check{' '}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={() => redirectToNewTab(`/price-groups/${id}`)}>
                here
              </span>{' '}
              if the product is correctly added with a price.
            </span>
          </div>
        )
      });
      form.resetFields();
    }
    setIsLoading(false);
  };

  const onProductChange = async (value: number) => {
    if (productSearch) {
      const selectedProduct = productSearch.find((val) => val.id == value);
      if (selectedProduct) {
        const unitIdList: IProductUnits[] = selectedProduct.productUnits;
        const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
        form.setFieldValue(['unitId'], defaultUnit?.unitId);
        let fetch = false;
        for (const value of unitIdList) {
          let unitData;
          try {
            unitData = await UnitsDB.getUnit(value.unitId);
          } catch (e) {
            console.log(e);
          }
          if (!unitData) {
            const response = await get_units_list();
            await UnitsDB.addUnits(response);
            fetch = true;
          }
        }
        if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      }
    }
  };

  const FilterUnits = () => {
    const checkCurrentProduct = form.getFieldValue(['productId']);
    if (checkCurrentProduct && productSearch) {
      const selectedProduct = productSearch.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      let filteredUnits;
      if (selectedProduct) {
        filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
          const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
          return data;
        });
      }
      return (
        <>
          {filteredUnits
            ? filteredUnits.map((value: any) => {
                if (!value) return null;
                return (
                  <Option value={value.id} key={value.id}>
                    {`${value.name}`}
                  </Option>
                );
              })
            : ''}
        </>
      );
    }
  };

  const handleSubmitClick = async () => {
    form
      .validateFields()
      .then(async (values: ISearchProductPriceGroup) => {
        // console.log('Values', values);
        const mutationArr: IUpdateProductPrice[] = [];
        const mutationObj: IUpdateProductPrice = {
          id: productPriceInformation.id,
          sellingPrice: values.sellingPrice,
          costPrice: values.costPrice
        };
        mutationArr.push(mutationObj);
        await updateProductPriceGroupMutation.mutateAsync(mutationArr);
      })
      .catch((e) => console.log(e));
  };

  const updateProductPriceGroupMutation = useMutation(update_product_pricegroup_mutation, {
    onSuccess: async () => {
      setIsLoading(false);
      message.success('Product Price Group updated successfully');
      form.resetFields();
      setProductPriceInformation(Object);
    },
    onError: (e: any) => {
      setIsLoading(false);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{ sellingPrice: 0, costPrice: 0 }}
          onFinish={onSubmitFilter}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off"
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}>
          <PageHeader
            title="Price Group"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <ProductSearch2
              formData={{ formName: 'productId', label: 'Product' }}
              required={true}
              onProductChange={async (val) => {
                await onProductChange(val);
                form.submit();
              }}
              productSearch={productSearch}
              setProductSearch={setProductSearch}
            />
            <Form.Item
              name={['unitId']}
              label="Unit"
              rules={[
                {
                  required: true,
                  message: 'Please choose a Unit!'
                }
              ]}>
              {
                <Select
                  placeholder="Select a Unit!"
                  onChange={() => form.submit()}
                  dropdownMatchSelectWidth={false}
                  allowClear={false}>
                  {FilterUnits()}
                </Select>
              }
            </Form.Item>
          </div>
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              name={['sellingPrice']}
              label="Selling Price"
              rules={[{ required: true, message: 'Please add selling price!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name={['costPrice']}
              label="Cost Price"
              rules={[{ required: true, message: 'Please add cost price!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" onClick={handleSubmitClick}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default PriceGroupDetails;
