import { LineType } from '@/services/report/enums';

export const DEFAULT_BORDER_RADIUS = 2;
export const DEFAULT_SHOW_FILTER = true;
export const DEFAULT_SHOW_ZOOM = true;
export const DEFAULT_SMOOTH = true;
export const DEFAULT_GRADIENT = false;
export const WIDGET_CUSTOMIZE_HEIGHT = 416;
export const DEFAULT_ENLARGE = true;
export * from './constant/compare.constant';
export * from './constant/default.constant';

export const filterFields = {
  paymentType: {
    title: 'Payment Type',
    options: [
      { label: 'Sell', value: 'sell' },
      { label: 'Purchase', value: 'purchase' }
    ]
  },
  transactionType: {
    title: 'Transaction Type',
    options: Object.keys(LineType).map((key) => ({
      label: key,
      value: LineType[key as keyof typeof LineType]
    }))
  }
};

export const ReportRequiredDateFields = {
  13: { startDate: ['startDate', 'sellStartDate'], endDate: ['endDate', 'sellEndDate'] },
  12: { startDate: ['startDate'], endDate: ['endDate'] },
  49: { startDate: ['startDate', 'sellStartDate'], endDate: ['endDate', 'sellEndDate'] },
  50: { startDate: ['startDate', 'purchaseStartDate'], endDate: ['endDate', 'purchaseEndDate'] },
  38: { locationId: 'from', startDate: ['startDate'], endDate: ['endDate'] }
} as Record<
  number,
  { startDate: string[]; endDate: string[]; isOneDay?: boolean; locationId?: string }
>;

export const ReportRequiredFields = {
  8: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  59: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  64: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  65: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  66: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  67: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  68: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  69: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  70: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  71: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }]
} as Record<
  number,
  {
    field: string;
    required: boolean;
    formOption: { title: string; options?: { label: string; value: string }[] };
  }[]
>;

const vendorField = { field: 'vendorId', name: 'vendorId', label: 'Supplier', required: false };
const productField = { field: 'productId', name: 'productId', label: 'Product', required: false };

const categoryField = {
  field: 'categoryId',
  name: 'categoryId',
  label: 'Product Category',
  required: false
};

export const ReportCustomSearchFields = {
  38: [{ field: 'locationId', name: 'to', label: 'Location (To)', required: false }],
  76: [{ field: 'customerId', name: 'customerId', label: 'Customer', required: false }],
  77: [vendorField],
  15: [categoryField],
  27: [categoryField],
  30: [categoryField],
  10: [categoryField],
  39: [categoryField],
  16: [categoryField],
  17: [categoryField]
} as Record<number, { field: string; name: string; required: boolean; label: string }[]>;
