import {
  Button,
  Form,
  FormInstance,
  PageHeader,
  Tabs,
  message,
  Pagination,
  Collapse,
  Spin,
  Alert
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import moment from 'moment';

import { download_report, get_report } from '@/services/report/queries';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { get_product_list_ids, get_units_list } from '@/services/products/queries';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { getInt } from '../pending-payment-sell-report/report';
import Modal from 'antd/lib/modal/Modal';
import { shortNameHiearchy } from '../../../sell/sell-order/view';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';

import {
  excelExportColumns1,
  excelExportColumns2,
  excelExportColumns3,
  getUpdatedData1,
  getUpdatedData2,
  getUpdatedData3,
  tableExportColumns1,
  tableExportColumns2,
  tableExportColumns3
} from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  count: any;
  data: any;
}
interface FooterData {
  totalSell: number;
  totalPurchase: number;
}
interface State {
  id: number[];
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  toSort?: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  toSort,
  children
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [data2, setData2] = useState<ReportData>({ count: 0, data: [] });
  const [data3, setData3] = useState<ReportData>({ count: 0, data: [] });
  const [size, setSize] = useState(100);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isGroup, setisGroup] = useState(false);
  const [page2, setPage2] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [normalFullExportURL, setNormalFullExportURL] = useState<any>(null);
  const [groupedFullExportURL, setGroupedFullExportURL] = useState<any>(null);
  const [groupedTotalFullExportURL, setGroupedTotalFullExportURL] = useState<any>(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [footer, setFooterData] = useState({
    detailAmount: 0,
    detailQuantity: '0',
    groupAmount: 0,
    groupQuantity: 0,
    totalQuantityPurchased: '0',
    totalQuantityPurchasedReturned: '0',
    totalQuantityReturnedSell: '0',
    totalQuantityTransferred: '0',
    totalQuantityTransferredOut: '0',
    totalQuantitySold: '0',
    totalQuantityAdjusted: '0',
    totalAvgQuantityPurchased: '0',
    totalAvgQuantityPurchasedReturned: '0',
    totalAvgQuantityReturnedSell: '0',
    totalAvgQuantityTransferred: '0',
    totalAvgQuantityTransferredOut: '0',
    totalAvgQuantitySold: '0',
    totalAvgQuantityAdjusted: '0'
  });
  const [footerTotalGrouped, setFooterTotalGrouped] = useState({
    detailAmount: 0,
    detailQuantity: '0',
    groupAmount: 0,
    groupQuantity: 0,
    totalQuantityPurchased: '0',
    totalQuantityPurchasedReturned: '0',
    totalQuantityReturnedSell: '0',
    totalQuantityTransferred: '0',
    totalQuantityTransferredOut: '0',
    totalQuantitySold: '0',
    totalQuantityAdjusted: '0'
  });

  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };
  const onSearch = async ({
    values,
    storePage = false,
    type
  }: {
    values: any;
    storePage?: boolean;
    type?: any;
  }) => {
    setIsloading(true);
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
      setPage2(1);
    }
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      // await downloadReport();
      showModal();
      setIsloading(false);
      return;
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const [normal, grouped, groupedTotal] = await Promise.all([
      getDetails(values),
      getGrouped(values),
      getGroupedTotal(values)
    ]);
    setIsloading(false);

    const payload = values.constraints;
    setNormalFullExportURL({ ...payload, size: normal.count });
    setGroupedFullExportURL({ ...payload, size: grouped.count });
    setGroupedTotalFullExportURL({ ...payload, size: groupedTotal.count });
  };

  const getDetails = async (values: any) => {
    const response = await get_report({ ...values.constraints }, state.id[0]);
    response.data = await calculateTotalDetail(response.data);

    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    setData(response.data);
    return response.data;
  };

  const getGrouped = async (values: any) => {
    const response2 = await get_report(
      {
        ...values.constraints,
        endDate: moment(values.constraints.endDate).subtract(1, 'days').utc().format()
      },
      state.id[1]
    );
    response2.data = await calculateTotalGroup(response2.data);
    setData2(response2.data);
    return response2.data;
  };

  const getGroupedTotal = async (values: any) => {
    const response3 = await get_report(
      {
        ...values.constraints,
        endDate: moment(values.constraints.endDate).subtract(1, 'days').utc().format()
      },
      state.id[2]
    );
    // console.log('Response 3', response3);
    response3.data = await calculateTotalGroup(response3.data, 'totalgrouped');
    setData3(response3.data);
    return response3.data;
  };

  async function onSearchAll(type: 0 | 1 | 2) {
    const exportURL = [normalFullExportURL, groupedFullExportURL, groupedTotalFullExportURL];

    const fullExportURL = exportURL[type];
    const id = state.id[type];
    const getUpdatedData = [getUpdatedData1, getUpdatedData2, getUpdatedData2][type];

    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }

      const response = await get_report(fullExportURL, id);
      if (type === 0) {
        response.data = await calculateTotalDetail(response.data);
      }

      if (type === 1) {
        response.data = await calculateTotalGroup(response.data);
      }

      if (type === 2) {
        response.data = await calculateTotalGroup(response.data, 'totalgrouped');
      }
      return getUpdatedData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const calculateTotalDetail = async (data: any) => {
    let total = 0;
    const totalq: any = {};
    for (let index = 0; index < data.data.length; index++) {
      let productDetails = await ProductsDB.getProduct(data.data[index].product_id);
      if (!productDetails) {
        const allProducts = await get_product_list_ids([
          ...new Set<number>(
            data.data.map((value: any) => {
              return value.product_id;
            })
          )
        ]);
        await ProductsDB.addProducts(allProducts.data.results);
        productDetails = await ProductsDB.getProduct(data.data[index].product_id);
      }

      if (typeof productDetails === 'object') {
        let defaultUnit = productDetails.productUnits.find((curr) => curr.isDefault);
        if (!defaultUnit) defaultUnit = productDetails.productUnits[0];
        let unitDetailsDefault = await UnitsDB.getUnit(defaultUnit.unitId);

        let unitInfo: any = await UnitsDB.getUnit(data.data[index].unit_id);
        // console.log('unit id', findUnit.unitId);
        if (!unitInfo || !unitDetailsDefault) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitInfo = await UnitsDB.getUnit(data.data[index].unit_id);
          unitDetailsDefault = await UnitsDB.getUnit(defaultUnit.unitId);
        }

        if (typeof unitDetailsDefault === 'object') {
          data.data[index].shortName = unitDetailsDefault.shortName;
          data.data[index].quantity =
            (data.data[index].quantity * unitInfo.baseUnitMultiplier) /
            unitDetailsDefault.baseUnitMultiplier;
          // total += data.data[index].amount;

          if (!totalq[unitInfo.shortName]) {
            totalq[unitInfo.shortName] = parseFloat(data.data[index].quantity);
          } else {
            totalq[unitInfo.shortName] += parseFloat(data.data[index].quantity);
          }
          total += getInt(data.data[index].total);
          // totalq += getInt(data.data[i].quantity);
        }
      }
    }

    const tqtystring = calculateTotalQuantityString(totalq);
    setFooterData((prev) => {
      return { ...prev, detailAmount: total, detailQuantity: tqtystring };
    });
    return data;
  };

  const calculateTotalGroup = async (data: any, type = 'grouped') => {
    let total = 0;
    let totalq = 0;
    const t_qty_adjusted: any = {};
    const t_qty_purchased: any = {};
    const t_qty_returned_purchase: any = {};
    const t_qty_returned_sell: any = {};
    const t_qty_sold: any = {};
    const t_qty_transferred: any = {};
    const t_qty_transferred_out: any = {};
    const t_avg_qty_adjusted: any = {};
    const t_avg_qty_purchased: any = {};
    const t_avg_qty_returned_purchase: any = {};
    const t_avg_qty_returned_sell: any = {};
    const t_avg_qty_sold: any = {};
    const t_avg_qty_transferred: any = {};
    const t_avg_qty_transferred_out: any = {};

    const searchProducts: any = {};
    for (const productPurchase of data.data) {
      if (productPurchase.location_id) {
        let locD: any = await LocationsDB.getLocation(productPurchase.location_id);
        if (!locD) {
          locD = await get_location_details(productPurchase.location_id);
          LocationsDB.addLocations([locD]);
        } else {
          // productPurchase.addedByName = user.name;
          productPurchase.locationName = locD.name;
        }
        // console.log('user', user);
      }

      if (!t_qty_adjusted[productPurchase.unit]) {
        t_qty_adjusted[productPurchase.unit] = Number(productPurchase.qty_adjusted);
      } else {
        t_qty_adjusted[productPurchase.unit] += Number(productPurchase.qty_adjusted);
      }

      if (!t_qty_purchased[productPurchase.unit]) {
        t_qty_purchased[productPurchase.unit] = Number(productPurchase.qty_purchased);
      } else {
        t_qty_purchased[productPurchase.unit] += Number(productPurchase.qty_purchased);
      }

      if (!t_qty_returned_purchase[productPurchase.unit]) {
        t_qty_returned_purchase[productPurchase.unit] = Number(
          productPurchase.qty_returned_purchase
        );
      } else {
        t_qty_returned_purchase[productPurchase.unit] += Number(
          productPurchase.qty_returned_purchase
        );
      }

      if (!t_qty_returned_sell[productPurchase.unit]) {
        t_qty_returned_sell[productPurchase.unit] = Number(productPurchase.qty_returned_sell);
      } else {
        t_qty_returned_sell[productPurchase.unit] += Number(productPurchase.qty_returned_sell);
      }

      if (!t_qty_transferred[productPurchase.unit]) {
        t_qty_transferred[productPurchase.unit] = Number(productPurchase.qty_transferred);
      } else {
        t_qty_transferred[productPurchase.unit] += Number(productPurchase.qty_transferred);
      }

      if (!t_qty_transferred_out[productPurchase.unit]) {
        t_qty_transferred_out[productPurchase.unit] = Number(productPurchase.qty_transferred_out);
      } else {
        t_qty_transferred_out[productPurchase.unit] += Number(productPurchase.qty_transferred_out);
      }

      if (!t_qty_sold[productPurchase.unit]) {
        t_qty_sold[productPurchase.unit] = Number(productPurchase.qty_sold);
      } else {
        t_qty_sold[productPurchase.unit] += Number(productPurchase.qty_sold);
      }

      if (!t_avg_qty_adjusted[productPurchase.unit]) {
        t_avg_qty_adjusted[productPurchase.unit] = parseFloat(productPurchase.qty_adjusted_price);
      } else {
        t_avg_qty_adjusted[productPurchase.unit] += parseFloat(productPurchase.qty_adjusted_price);
      }

      if (!t_avg_qty_purchased[productPurchase.unit]) {
        t_avg_qty_purchased[productPurchase.unit] = parseFloat(productPurchase.qty_purchased_price);
      } else {
        t_avg_qty_purchased[productPurchase.unit] += parseFloat(
          productPurchase.qty_purchased_price
        );
      }

      if (!t_avg_qty_returned_purchase[productPurchase.unit]) {
        t_avg_qty_returned_purchase[productPurchase.unit] = parseFloat(
          productPurchase.qty_returned_purchase_price
        );
      } else {
        t_avg_qty_returned_purchase[productPurchase.unit] += parseFloat(
          productPurchase.qty_returned_purchase_price
        );
      }

      if (!t_avg_qty_returned_sell[productPurchase.unit]) {
        t_avg_qty_returned_sell[productPurchase.unit] = parseFloat(
          productPurchase.qty_returned_sell_price
        );
      } else {
        t_avg_qty_returned_sell[productPurchase.unit] += parseFloat(
          productPurchase.qty_returned_sell_price
        );
      }

      if (!t_avg_qty_sold[productPurchase.unit]) {
        t_avg_qty_sold[productPurchase.unit] = parseFloat(productPurchase.qty_sold_price);
      } else {
        t_avg_qty_sold[productPurchase.unit] += parseFloat(productPurchase.qty_sold_price);
      }

      if (!t_avg_qty_transferred[productPurchase.unit]) {
        t_avg_qty_transferred[productPurchase.unit] = parseFloat(
          productPurchase.qty_transferred_price
        );
      } else {
        t_avg_qty_transferred[productPurchase.unit] += parseFloat(
          productPurchase.qty_transferred_price
        );
      }

      if (!t_avg_qty_transferred_out[productPurchase.unit]) {
        t_avg_qty_transferred_out[productPurchase.unit] = parseFloat(
          productPurchase.qty_transferred_out_price
        );
      } else {
        t_avg_qty_transferred_out[productPurchase.unit] += parseFloat(
          productPurchase.qty_transferred_out_price
        );
      }

      // total += productPurchase.amount;
      total += getInt(productPurchase.total_amount);
      totalq += getInt(productPurchase.quantity);
    }

    if (type == 'totalgrouped') {
      setFooterTotalGrouped((prev) => {
        return {
          ...prev,
          groupAmount: total,
          groupQuantity: totalq,
          totalQuantityPurchased: calculateTotalQuantityString(t_qty_purchased),
          totalQuantityPurchasedReturned: calculateTotalQuantityString(t_qty_returned_purchase),
          totalQuantityReturnedSell: calculateTotalQuantityString(t_qty_returned_sell),
          totalQuantityTransferred: calculateTotalQuantityString(t_qty_transferred),
          totalQuantityTransferredOut: calculateTotalQuantityString(t_qty_transferred_out),
          totalQuantitySold: calculateTotalQuantityString(t_qty_sold),
          totalQuantityAdjusted: calculateTotalQuantityString(t_qty_adjusted)
        };
      });
    } else {
      setFooterData((prev) => {
        return {
          ...prev,
          totalQuantityPurchased: calculateTotalQuantityString(t_qty_purchased),
          totalQuantityPurchasedReturned: calculateTotalQuantityString(t_qty_returned_purchase),
          totalQuantityReturnedSell: calculateTotalQuantityString(t_qty_returned_sell),
          totalQuantityTransferred: calculateTotalQuantityString(t_qty_transferred),
          totalQuantityTransferredOut: calculateTotalQuantityString(t_qty_transferred_out),
          totalQuantitySold: calculateTotalQuantityString(t_qty_sold),
          totalQuantityAdjusted: calculateTotalQuantityString(t_qty_adjusted),
          totalAvgQuantityAdjusted: calculateTotalQuantityStringWithoutUnit(t_avg_qty_adjusted),
          totalAvgQuantityPurchased: calculateTotalQuantityStringWithoutUnit(t_avg_qty_purchased),
          totalAvgQuantityPurchasedReturned: calculateTotalQuantityStringWithoutUnit(
            t_avg_qty_returned_purchase
          ),
          totalAvgQuantityReturnedSell:
            calculateTotalQuantityStringWithoutUnit(t_avg_qty_returned_sell),
          totalAvgQuantitySold: calculateTotalQuantityStringWithoutUnit(t_avg_qty_sold),
          totalAvgQuantityTransferred:
            calculateTotalQuantityStringWithoutUnit(t_avg_qty_transferred),
          totalAvgQuantityTransferredOut:
            calculateTotalQuantityStringWithoutUnit(t_avg_qty_transferred_out)
        };
      });
    }
    // console.log('Data', data);
    return data;
  };
  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch({ values: allValues, storePage: true, type: ['detail'] });
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const addPage2 = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage2(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage2(value);
      }
      onSearch({ values: allValues, storePage: true, type: ['detail', value] });
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async (group: number) => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    // console.log('Clicked');
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (group != 0) {
        if (
          check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)
        ) {
          // const response =
          values.constraints.customReportQueueName = 'Purchase Product Group Report';
          message.info({
            key: 'downloading',
            content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
          });
          await download_report(
            {
              ...values.constraints,
              endDate: moment(values.constraints.endDate).subtract(1, 'days').utc().format(),
              page: 1
            },
            state.id[group]
          );
          setIsloading(false);
          // window.open(response.data, '_blank');
        }
      } else {
        if (
          check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)
        ) {
          // const response =
          values.constraints.customReportQueueName = 'Purchase Product Report';
          message.info({
            key: 'downloading',
            content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
          });
          await download_report(
            {
              ...values.constraints,
              page: 1
            },
            state.id[group]
          );
          setIsloading(false);
          // window.open(response.data, '_blank');
        }
      }

      // if (group) {
      //   const response1 = await download_report(
      //     {
      //       ...values.constraints,
      //       endDate: moment(values.constraints.endDate).subtract(1, 'days').utc().format()
      //     },
      //     state.id[1]
      //   );
      //   window.open(response1.data, '_blank');
      // } else {
      //   const response2 = await download_report(values.constraints, state.id[0]);
      //   window.open(response2.data, '_blank');
      // }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const calculateTotalQuantityString = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        unit: key
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.unit) - shortNameHiearchy.indexOf(b.unit);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
              totalQuantityArray[ind].unit
            }`;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  const calculateTotalQuantityStringWithoutUnit = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key]
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.unit) - shortNameHiearchy.indexOf(b.unit);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} 
            `;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  const normalUpdatedData = getUpdatedData1(data.data);
  const groupedUpdatedData = getUpdatedData2(data2.data);
  const groupedTotalUpdatedData = getUpdatedData3(data3.data);

  return (
    <div>
      <Modal
        title="Download purchase report!"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        <div className="flex gap-2">
          <Button
            onClick={() => downloadReport(0)}
            style={{
              backgroundColor: '#0AA245',
              color: 'white',
              borderRadius: '10px'
            }}>
            Purchase Details report
          </Button>
          <Button
            onClick={() => downloadReport(1)}
            style={{
              backgroundColor: '#0AA245',
              color: 'white',
              borderRadius: '10px'
              // marginLeft: '1rem'
            }}>
            Grouped report
          </Button>
          <Button
            onClick={() => downloadReport(2)}
            style={{
              backgroundColor: '#0AA245',
              color: 'white',
              borderRadius: '10px'
              // marginLeft: '1rem'
            }}>
            Grouped Total
          </Button>
        </div>
      </Modal>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={(values) => onSearch({ values })}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '8px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-3 ">{children}</div>
                <div className="flex justify-end mt-5 gap-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="secondary-button"
                      style={{ borderRadius: '6px' }}
                      onClick={() => showModal()}>
                      Download
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <div className="mt-2">
            <Alert
              message="Information!"
              description="Please note, the grouped data is calculated at 00:00 every day. Today's data might not be visible."
              type="info"
              closable
              showIcon
            />
          </div>
          <Tabs defaultActiveKey="details">
            <Tabs.TabPane tab="Details" key="details">
              <PageHeader
                subTitle="Table"
                style={{
                  padding: '8px 0px'
                }}
              />
              <CustomizeTable
                customScroll={{ x: 1400, y: 500 }}
                notshowPagination
                key={JSON.stringify(normalFullExportURL)}
                tableName="product-purchase-details-report-table"
                columns={columnsData[0]}
                toSort={toSort}
                data={data.data}
                footer={() =>
                  `Total Amount: ${nepaliNumberFormatter(footer.detailAmount)}, Total Quantity: ${
                    footer.detailQuantity
                  }`
                }
                tableSummary={
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}> </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="text-center">
                        {footer.detailQuantity}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}> </Table.Summary.Cell>

                      <Table.Summary.Cell index={9} className="text-center">
                        {nepaliNumberFormatter(footer.detailAmount)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                }
                buttons={
                  <>
                    <ExportAllData
                      title="Product Purchase Details Report (All)"
                      columns={tableExportColumns1}
                      excelColumns={excelExportColumns1}
                      getInfo={() => onSearchAll(0)}
                    />
                    <ExportCurrent
                      data={{ total: data.count, results: normalUpdatedData }}
                      columns={tableExportColumns1}
                      excelColumns={excelExportColumns1}
                      title="Product Purchase Details Report"
                    />
                  </>
                }
              />

              <div className="flex justify-end mt-4">
                <Pagination
                  current={page}
                  total={data.count}
                  pageSize={size}
                  pageSizeOptions={[100, 500, data.count]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={(pageNo, pageSize) => {
                    // addPage(pageNo);
                    if (pageSize == size) {
                      //code for page change
                      addPage(pageNo);
                    } else {
                      //code for size change
                      addPage(pageNo, pageSize);
                    }
                  }}
                />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Grouped" key="group">
              <CustomizeTable
                customScroll={{ x: 3000, y: 500 }}
                notshowPagination
                tableName="product-purchase-grouped-report-table"
                columns={columnsData[1]}
                data={data2.data}
                footer={() =>
                  footer.groupAmount ? (
                    `Total Amount: ${nepaliNumberFormatter(footer.groupAmount)}, Total Quantity: ${
                      footer.groupQuantity
                    }`
                  ) : (
                    <></>
                  )
                }
                tableSummary={
                  footer ? (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-center">
                          {footer.totalQuantitySold}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="text-center">
                          {footer.totalAvgQuantitySold}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6} className="text-center">
                          {footer.totalQuantityReturnedSell}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="text-center">
                          {footer.totalAvgQuantityReturnedSell}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}></Table.Summary.Cell>
                        <Table.Summary.Cell index={9} className="text-center">
                          {footer.totalQuantityPurchased}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10} className="text-center">
                          {footer.totalAvgQuantityPurchased}
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={11} className="text-center">
                          {footer.totalQuantityPurchasedReturned}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={12} className="text-center">
                          {footer.totalAvgQuantityPurchasedReturned}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={13} className="text-center">
                          {footer.totalQuantityTransferred}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={14} className="text-center">
                          {footer.totalAvgQuantityTransferred}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={15} className="text-center">
                          {footer.totalQuantityTransferredOut}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={16} className="text-center">
                          {footer.totalAvgQuantityTransferredOut}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={17} className="text-center">
                          {footer.totalQuantityAdjusted}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={18} className="text-center">
                          {footer.totalAvgQuantityAdjusted}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  ) : (
                    <></>
                  )
                }
                buttons={
                  <>
                    <ExportAllData
                      title="Product Purchase Grouped Report (All)"
                      columns={tableExportColumns2}
                      excelColumns={excelExportColumns2}
                      getInfo={() => onSearchAll(1)}
                    />
                    <ExportCurrent
                      data={{ total: data.count, results: groupedUpdatedData }}
                      columns={tableExportColumns2}
                      excelColumns={excelExportColumns2}
                      title="Product Purchase Grouped Report"
                    />
                  </>
                }
              />

              <div className="flex justify-end mt-4">
                <Pagination
                  current={page2}
                  total={data2.count}
                  pageSize={size}
                  pageSizeOptions={[100, 500, data2.count]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={(pageNo, pageSize) => {
                    // addPage(pageNo);
                    if (pageSize == size) {
                      //code for page change
                      addPage2(pageNo);
                    } else {
                      //code for size change
                      addPage2(pageNo, pageSize);
                    }
                  }}
                />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Grouped total" key="grouped total">
              <CustomizeTable
                customScroll={{ x: 2000, y: 500 }}
                notshowPagination
                tableName="product-purchase-grouped-total-report-table"
                columns={columnsData[2]}
                data={data3.data}
                footer={() =>
                  footer.groupAmount ? (
                    `Total Amount: ${nepaliNumberFormatter(footer.groupAmount)}, Total Quantity: ${
                      footer.groupQuantity
                    }`
                  ) : (
                    <></>
                  )
                }
                tableSummary={
                  footer ? (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantitySold}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantityReturnedSell}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}> </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantityPurchased}
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={8} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantityPurchasedReturned}
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={9} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantityTransferred}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantityTransferredOut}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={11} className="text-center">
                          {' '}
                          {footerTotalGrouped.totalQuantityAdjusted}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  ) : (
                    <></>
                  )
                }
                buttons={
                  <>
                    <ExportAllData
                      title="Product Purchase Grouped Total Report (All)"
                      columns={tableExportColumns3}
                      excelColumns={excelExportColumns3}
                      getInfo={() => onSearchAll(2)}
                    />
                    <ExportCurrent
                      data={{ total: data.count, results: groupedTotalUpdatedData }}
                      columns={tableExportColumns3}
                      excelColumns={excelExportColumns3}
                      title="Product Purchase Grouped Total Report"
                    />
                  </>
                }
              />

              <div className="flex justify-end mt-4">
                <Pagination
                  current={page2}
                  total={data2.count}
                  pageSize={size}
                  pageSizeOptions={[100, 500, data2.count]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={(pageNo, pageSize) => {
                    // addPage(pageNo);
                    if (pageSize == size) {
                      //code for page change
                      addPage2(pageNo);
                    } else {
                      //code for size change
                      addPage2(pageNo, pageSize);
                    }
                  }}
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
// const getFooterData = async (values: any) => {
//   if (footerData.purchase) {
//     const responsePurchase = await get_report(values.constraints, 7);
//     setFooterData((prev) => {
//       return {
//         ...prev,
//         totalPurchase: responsePurchase.data.data[0][`sum(total_amount)`]
//       };
//     });
//   }
//   if (footerData.sell) {
//     const responseSell = await get_report(values.constraints, 6);
//     setFooterData((prev) => {
//       return {
//         ...prev,
//         totalSell: responseSell.data.data[0].total_amount
//       };
//     });
//   }
// };
// const loadFooter = () => {
//   if (!footerData) {
//     return '';
//   }
//   return (
//     <>
//       {footerData.sell || footerData.purchase ? (
//         <div
//           style={{
//             backgroundColor: 'grey',
//             color: 'white',
//             height: '50px',
//             display: 'flex',
//             justifyContent: 'space-around',
//             alignItems: 'center',
//             fontSize: '25px',
//             borderRadius: '9px'
//           }}>
//           {footerData.sell && <div>Total Sell: {footer.totalSell}</div>}
//           {footerData.purchase && <div>Total Purchase: {footer.totalPurchase}</div>}
//         </div>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };
