// Global imports
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Upload,
  Checkbox
} from 'antd';

// Local imports created by you
import UnitsDB from '@/store/localstorage/UnitsDB';
import { ICategory } from '@/services/category/types';
import { getFilteredUnits } from '@/utils/units.utils';
import { getLocalStorage } from '@/utils/storage.utils';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import CategoryDB from '@/store/localstorage/CategoryDB';
import AppContent from '@/components/Common/Content/Content';
import { get_units_list } from '@/services/products/queries';
import { get_category_list } from '@/services/category/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { IProductCreateRequest, IUnits, Unit } from '@/services/products/types';
import { create_products_mutation, upload_default_unitId } from '@/services/products/mutations';

// Third-party library imports
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const Create = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isloading, setIsloading] = useState<boolean>(false);
  const [unitsList, setUnitsList] = useState<IUnits[]>([]);
  const [filteredUnitsList, setFilteredUnitsList] = useState<IUnits[]>([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  const [productDetails, setProductDetails] = useState<any>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList2, setFileList2] = useState<UploadFile[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);

  useEffect(() => {
    // Fetch units and store in indexDB
    (async function () {
      UnitsDB.addUnits(await get_units_list());
    })();
  }, []);

  const getDataFromLC = async () => {
    const allunits: IUnits[] = (await UnitsDB.getAllUnits()) as IUnits[];
    if (allunits.length > 0) {
      setUnitsList(allunits);
      setFilteredUnitsList(allunits);
    } else {
      const response = await get_units_list();
      setUnitsList(response);
      setFilteredUnitsList(response);
      UnitsDB.addUnits(response);
    }
    const allcategory: ICategory[] = (await CategoryDB.getAllCategory()) as ICategory[];
    if (allcategory.length > 0) {
      setCategoryList(allcategory);
    } else {
      const response = await get_category_list();
      setCategoryList(response.data.results);
      CategoryDB.addCategory(response.data.results);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('imgId', updatedFileId);
    }
    setFileList(newFileList);
  };
  const onChange2: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('bannerId', updatedFileId);
    }
    setFileList2(newFileList);
  };
  useEffect(() => {
    getDataFromLC();
  }, []);

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };
  const uploadProps2: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList2,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange2
  };

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Create',
      link: '/products/new'
    }
  ];
  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const createProductsMutation = useMutation(create_products_mutation);
  const updateDefaultUnitIdMutation = useMutation(upload_default_unitId);
  const onFinish = async (values: IProductCreateRequest) => {
    setIsloading(true);
    try {
      let defaultUnitId: any;
      if (values.units && values.units.length > 0) {
        const units = values.units as any[];
        const isAllSameBaseUnit = units.every(
          (unit: any) => unit.baseUnitName === units[0].baseUnitName
        );

        if (!isAllSameBaseUnit) {
          message.error('All units must have the same base unit');
          setIsloading(false);
          return;
        }

        const selectedUnits = values.units.map((unit: Unit) => unit.id);
        const findDefaultUnit = values.units.find((unit: Unit) => unit.defaultUnit === true);
        if (findDefaultUnit) {
          defaultUnitId = findDefaultUnit.id;
        }
        values.units.map((curr: any) => {
          delete curr.defaultUnit;
        });
        values.unit_ids = selectedUnits as number[];
        // values.units = newUnits as IUnits[];
      } else {
        values.unit_ids = [];
        // values.units = [];
      }
      values.units = [];
      values.hidden = false;
      await createProductsMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            setIsloading(false);
            message.success('Product added successfully');
            if (defaultUnitId) {
              updateDefaultUnitIdMutation.mutateAsync(
                { productId: data.id, unitId: defaultUnitId },
                {
                  onSuccess: async ({ data }: { data: any }) => {
                    message.success('Default Added Sucessfully!');
                  },
                  onError: (e: any) => {
                    message.error(`${e.response.data.message}`, 5);
                  }
                }
              );
            }
            navigate('/products');
          }
        },
        onError: (e: any) => {
          setIsloading(false);
        }
      });
    } catch (err: any) {
      setIsloading(false);
      console.log(err.message);
    }
  };

  // const { data: unitsList } = useQuery(['units'], async () => get_units_list());

  const handleUnitFieldsDisabled = (name: number) => {
    return productDetails?.units?.[name]?.['unitExists'];
  };

  const getExistingUnitsDetails = (id: number, name: number) => {
    const existingUnits = form.getFieldValue('units');
    const isalreadyexists = existingUnits.find((curr: any) => curr.id == id);

    if (isalreadyexists) {
      message.error('You have already selected this unit!');
      return;
    }

    function setUnits(baseName: string) {
      const { updatedUnits } = getFilteredUnits(unitsList, baseName);
      message.info(
        <span>
          Now, You can only add units of <strong>{baseName}</strong> as base unit.
        </span>
      );
      setSelectedUnit(baseName);
      setFilteredUnitsList(updatedUnits);
      return updatedUnits;
    }

    const unit = unitsList?.find((unit: IUnits) => unit.id === id);
    if (!id || !unit) return;

    const firstElement = existingUnits[0];
    const isFirstUnitValid = firstElement?.id !== null;

    if (!isFirstUnitValid) {
      setUnits(unit.baseUnitName);
    }

    if (isFirstUnitValid && unit.baseUnitName !== selectedUnit) {
      setUnits(unit.baseUnitName);

      // Remove all units except the first one
      form.setFieldValue('units', [
        {
          id,
          name: unit?.name,
          shortName: unit?.shortName,
          description: unit?.description,
          baseUnitMultiplier: unit?.baseUnitMultiplier,
          baseUnitName: unit?.baseUnitName
        }
      ]);

      return;
    }

    existingUnits[name].id = id;
    existingUnits[name].name = unit?.name;
    existingUnits[name].shortName = unit?.shortName;
    existingUnits[name].description = unit?.description;
    existingUnits[name].baseUnitMultiplier = unit?.baseUnitMultiplier;
    existingUnits[name].baseUnitName = unit?.baseUnitName;

    form.setFieldValue('units', existingUnits);
  };

  const handleRichTextEditorChange = (field: string, value: string) => {
    form.setFieldValue(field, value);
  };

  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onDefaultUnitChange = (value: boolean, name: number) => {
    form.setFieldValue(['units', name, 'defaultUnit'], value);

    const data = form.getFieldValue(['units']);
    if (value) {
      for (let ind = 0; ind < data.length; ind++) {
        if (ind !== name) {
          form.setFieldValue(['units', ind, 'defaultUnit'], false);
        }
      }
    }
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Product Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{ units: [], purchasePrice: 0 }}
          onFinish={onFinish}
          disabled={isloading}
          layout="vertical"
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className={'grid grid-cols-1 gap-2 sm:grid-cols-2 gap-5 mb-5'}>
            <div className="flex mb-5 justify-center align-center">
              <div>
                <p>Upload an image</p>
                <Upload className="rounded-full" {...uploadProps}>
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <Form.Item
              label="Image"
              name="imgId"
              hidden
              rules={[{ required: false, message: 'Please upload image!' }]}>
              <Input />
            </Form.Item>
            <div className="flex mb-5 justify-center align-center">
              <div>
                <p>Upload an banner</p>
                <Upload className="rounded-full" {...uploadProps2}>
                  {fileList2.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <Form.Item
              label="Image"
              name="bannerId"
              hidden
              rules={[{ required: false, message: 'Please upload banner!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-2 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Category" name={'categoryId'}>
              <Select defaultValue={'none'}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {categoryList?.map((category: ICategory) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: true, message: 'Please add SKU!' }]}>
              <Input />
            </Form.Item> */}

            <Form.Item
              label="Lot Expiry Duration"
              name="lotExpiryDuration"
              rules={[{ required: true, message: 'Please add Lot expiry duration!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>

            <Form.Item
              label="Vat Percentage"
              name="vat"
              // rules={[
              //   {
              //     required: false,
              //     pattern: new RegExp('^(0|[1-9][0-9]?|100)$'),
              //     message: 'Please Input number between 1-100'
              //   }
              // ]}
              rules={[
                {
                  required: true,
                  message: 'Please add a vat percentage'
                }
              ]}>
              {/* <InputNumber controls={false} min={0} max={100} defaultValue={0} /> */}
              <Select placeholder="Select vat percentage">
                <Select.Option value={0}>0%</Select.Option>
                <Select.Option value={13}>13%</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Default Purchase Price (Exclusive of VAT)"
              name="purchasePrice"
              rules={[
                {
                  required: true,
                  message: 'Please add default purchase price'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>

          {/* <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            <Input.TextArea />
          </Form.Item> */}
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            {/* <RichTextEditor
              value={form.getFieldValue('description')}
              fieldName="description"
              fn={handleRichTextEditorChange}
            /> */}
            <TextArea
              value={form.getFieldValue('description')}
              rows={5}
              placeholder="Description..."
            />
          </Form.Item>
          <Divider />

          <div className="mt-5">
            <PageHeader
              title="Units"
              style={{
                padding: '8px 0px'
              }}
            />
            {form.getFieldValue('units') && form.getFieldValue('units').length > 0 ? (
              <div className="mb-2 text-gray-500 text-base	">Base Unit: {selectedUnit || 'NA'}</div>
            ) : (
              <></>
            )}

            <Form.List name="units">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <div
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-5 mb-5"
                        key={key}>
                        {
                          <Form.Item
                            label="Select existing unit"
                            name={[name, 'id']}
                            rules={[{ required: true, message: 'Please Select Unit' }]}>
                            <Select
                              value={form.getFieldValue(['units', name, 'id'])}
                              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                getExistingUnitsDetails(Number(event), name)
                              }>
                              {(name === 0 ? unitsList : filteredUnitsList).map((unit: IUnits) => (
                                <Option key={unit.id} value={unit.id}>
                                  {unit.name}
                                </Option>
                              ))}
                            </Select>

                            <Form.Item name={[name, 'name']} hidden>
                              <Input />
                            </Form.Item>
                          </Form.Item>
                        }
                        <Form.Item
                          {...restField}
                          name={[name, 'shortName']}
                          label="Short Name"
                          rules={[{ required: false, message: 'Please input short name!' }]}>
                          <Input disabled={true} />
                        </Form.Item>

                        <Form.Item
                          label="Description"
                          {...restField}
                          name={[name, 'description']}
                          rules={[{ required: false, message: 'Please add description!' }]}>
                          <Input disabled={true} />
                        </Form.Item>

                        <Form.Item
                          label="Base unit multiplier"
                          {...restField}
                          name={[name, 'baseUnitMultiplier']}
                          rules={[{ required: false, message: 'Please add SKU!' }]}>
                          <InputNumber controls={false} min={0} className="w-100" disabled={true} />
                        </Form.Item>
                        <Form.Item label="Default Unit" {...restField} name={[name, 'defaultUnit']}>
                          <Checkbox
                            checked={form.getFieldValue(['units', name, 'defaultUnit'])}
                            onChange={(value) => {
                              onDefaultUnitChange(value.target.checked, name);
                              // setIsTask(value.target.checked);
                            }}></Checkbox>
                        </Form.Item>

                        <div className="flex justify-center items-center">
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button flex justify-center items-center"
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => {
                        const existingUnits = form.getFieldValue('units');
                        const isFull = existingUnits.length === filteredUnitsList.length;
                        if (isFull) {
                          message.info('You have already added all units!');
                          return;
                        }

                        add({
                          id: null,
                          name: '',
                          shortName: '',
                          description: '',
                          baseUnitMultiplier: 0
                        });
                      }}
                      icon={<PlusOutlined />}>
                      Add Unit
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isloading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/products')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default Create;
