import { CloudDownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface CustomIconProps {
  link?: string;
  title?: string;
  onlyIcon?: boolean;
}

const CustomDownloadIcon: React.FC<CustomIconProps> = ({ link, title, onlyIcon = true }) => {
  return (
    <Tooltip title={title ? title : 'Download'} color="blue">
      {link ? (
        <Link to={link} className="flex gap-2">
          <CloudDownloadOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
          {!onlyIcon && title}
        </Link>
      ) : (
        <CloudDownloadOutlined
          style={{ transform: 'scale(1.4)', width: '100%', marginLeft: '2px' }}
        />
      )}
    </Tooltip>
  );
};

export default CustomDownloadIcon;
