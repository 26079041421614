export const reportCompareField = {
  unit: { filter: 'unitId', value: 'Unit' },
  location: { filter: 'locationId', value: 'Location' },
  product: { filter: 'productId', value: 'Product' }
};

const CompareAllFields = [
  reportCompareField.location,
  reportCompareField.unit,
  reportCompareField.product
];

export const reportCompareRequiredFields = {
  66: [...CompareAllFields],
  67: [...CompareAllFields],
  70: [...CompareAllFields],
  71: [...CompareAllFields],
  76: [...CompareAllFields],
  77: [...CompareAllFields]
} as Record<number, { filter: string; value: string }[]>;
