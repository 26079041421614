export enum SchedulerStatusType {
  LOTS = 'LOTS',
  LOT_EXPIRY = 'LOT_EXPIRY',
  SETTINGS = 'SETTINGS',
  ACCOUNT_BACKDATE = 'ACCOUNT_BACKDATE',
  ACCOUNT_ROLLBACK = 'ACCOUNT_ROLLBACK',
  ACCOUNT_FINANCIAL_YEAR = 'ACCOUNT_FINANCIAL_YEAR',
  FINANCIAL_YEAR_LOT_RESET = 'FINANCIAL_YEAR_LOT_RESET',
  ACCOUNT_HISTORY = 'ACCOUNT_HISTORY',
  PRODUCT_HISTORY = 'PRODUCT_HISTORY',
  CUSTOMER_HISTORY = 'CUSTOMER_HISTORY',
  ROUTE_HISTORY = 'ROUTE_HISTORY',
  VENDOR_HISTORY = 'VENDOR_HISTORY',
  VEHICLE_HISTORY = 'VEHICLE_HISTORY',
  VEHICLE_REMINDER = 'VEHICLE_REMINDER',
  EXPENSE = 'EXPENSE',
  IRD = 'IRD',
  LOGS_BACKUP = 'LOGS_BACKUP',
  LOGS_REMOVE = 'LOGS_REMOVE',
  REPORT_EMAIL = 'REPORT_EMAIL'
}

export enum ESchedularSuccessStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  RUNNING = 'RUNNING'
}
