import http from '@/utils/http.utils';
import { AxiosResponse } from 'axios';
import { IScheduler, ISchedulerResponse, ISchedulerStatus } from './types';

export const get_scheduler_status_list = async (filter = '') => {
  const response: AxiosResponse<ISchedulerResponse> = await http.get('/scheduler/?' + filter);
  return response.data;
};

export const get_scheduler_status_by_id = async (id: number) => {
  const response: AxiosResponse<ISchedulerStatus> = await http.get('/scheduler/' + id);
  return response.data;
};

export const get_scheduler_list = async () => {
  const response: AxiosResponse<IScheduler[]> = await http.get('/scheduler-order');
  return response.data;
};

export const get_scheduler_by_id = async (id: number) => {
  const response: AxiosResponse<IScheduler> = await http.get('/scheduler-order/by-id/' + id);
  return response.data;
};

export const get_scheduler_by_type = async (type: string) => {
  const response: AxiosResponse<IScheduler[]> = await http.get('/scheduler-order/by-type/' + type);
  return response.data;
};
