import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useRequestSignal(): AbortSignal {
  const controllers = useRef<AbortController | null>(null);
  const location = useLocation();

  // Register a new controller only if it doesn't exist
  const registerController = () => {
    if (!controllers.current) {
      controllers.current = new AbortController();
    }
    return controllers.current;
  };

  // Cancel ongoing requests when the location changes or component unmounts
  useEffect(() => {
    return () => {
      if (controllers.current) {
        controllers.current.abort();
        controllers.current = null; // Clear reference
      }
    };
  }, [location.pathname]);

  const controller = registerController();
  return controller.signal;
}
