import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICreateGlobalSettings,
  ICreateInvoiceLayoutMutation,
  ICreateInvoiceResponse,
  ICreateInvoiceSchemesMutation,
  ICreateMenuItem,
  IMenuDeleteResponse,
  IMenuList,
  IMenuListServer,
  INotification,
  IUpdateInvoiceLayoutMutation,
  IUpdateMenuItem,
  IUpdateMenuItemPosition
} from './types';
import { ICreateCommonResponse, IDeleteCommonResponse } from '../category/types';

export const create_invoice_layout_mutation = async (values: ICreateInvoiceLayoutMutation) => {
  const response: AxiosResponse<ICreateInvoiceResponse, any> = await http.store(
    'invoice-layout',
    values
  );
  return response;
};

export const update_invoice_layout_mutation = async (values: IUpdateInvoiceLayoutMutation) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'invoice-layout/' + values.id,
    values
  );
  return response;
};

export const delete_invoice_layout_mutation = async (id: string) => {
  const response: AxiosResponse<IDeleteCommonResponse, any> = await http.remove(
    'invoice-layout/' + id
  );
  return response;
};

export const create_invoice_schemes_mutation = async (value: ICreateInvoiceSchemesMutation) => {
  const response = await http.store('invoice', value);
  return response;
};

export const update_invoice_schemes_mutation = async (values: any) => {
  // console.log('Update invoice schemes--->', values);
  const response = await http.update('invoice/' + values.id, values);
  return response;
};

export const delete_invoice_schemes_mutation = async (id: string) => {
  const response = await http.remove('invoice/' + id);
  return response;
};

export const upload_invoice_pdf = async (values: any) => {
  const response = await http.store('storage/upload/', values.media);
  return response;
};

export const upload_invoice_pdf_with_mediaId = async (values: any) => {
  const response = await http.store('/invoice', values);
  return response;
};

export const get_notifications = async () => {
  const response: AxiosResponse<INotification[], any> = await http.get('notification/');
  return response;
};

// export const read_notification = async (ids: number[]) => {
//   const response = await http.store('notification/read', ids);
//   return response;
// };

export const create_global_settings = async (values: { content: string }) => {
  const response: AxiosResponse<ICreateGlobalSettings, any> = await http.store('settings', values);
  return response;
};

export const create_menu_item = async (values: ICreateMenuItem) => {
  const response: AxiosResponse<IMenuList> = await http.store('dynamic-menu', values);
  return response;
};

export const update_menu_item = async (values: IUpdateMenuItem) => {
  const response: AxiosResponse<IMenuList> = await http.update(
    `dynamic-menu/${values.id}`,
    values,
    { bypassSanitize: true }
  );
  return response;
};

export const update_menu_item_position = async (values: IUpdateMenuItemPosition) => {
  const response: AxiosResponse<IMenuListServer> = await http.update(
    'dynamic-menu/update-position',
    values,
    { bypassSanitize: true }
  );
  return response;
};

export const delete_menu_item = async (id: number) => {
  const response: AxiosResponse<IMenuDeleteResponse> = await http.remove(`dynamic-menu/${id}`);
  return response;
};
