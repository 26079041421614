import { AxiosResponse } from 'axios';
import { ICustomerPriceGroups, ICustomerPriceGroupsResponse } from './types';
import http from '@/utils/http.utils';
import { getDataForIds } from '@/utils/queries.utils';
import { IPriceGroupsView } from '../products/types';

export const get_customer_price_groups_all = async (filter = '') => {
  const response: AxiosResponse<ICustomerPriceGroupsResponse> = await http.get(
    'customer-prices/price-groups-find-all/?' + filter
  );
  return response.data;
};

export const get_customer_price_groups_customerGroupId = async (customerGroupId: number) => {
  const filter = `customerGroupIds[]=${customerGroupId}`;
  const response: AxiosResponse<ICustomerPriceGroupsResponse> = await http.get(
    'customer-prices/price-groups-find-all/?' + filter,
    { skip: 0, count: 100 }
  );
  return response.data;
};

export const get_customer_price_groups_ids = async (ids: number[] = []) => {
  return await getDataForIds<ICustomerPriceGroups>(ids, 'customer-prices/price-groups-find-all/?');
};

export const download_customer_price_groups = async (id: number) => {
  const response = await http.get('customer-prices/download/' + id);
  return response.data as string;
};

export const get_customer_price_groups_all_v2 = async (
  skip = 0,
  count = 0,
  value = '',
  filter = ''
) => {
  const response: AxiosResponse<ICustomerPriceGroupsResponse> = await http.get(
    'customer-prices/price-groups-find-all/?' + filter,
    { skip, count, value }
  );
  return response.data;
};

export const get_customer_price_groups_by_customerId = async (customerId: number) => {
  const response: AxiosResponse<ICustomerPriceGroups[]> = await http.get(
    'customer-prices/price-groups/' + customerId
  );
  return response.data;
};

export const get_current_price_By_priceGroupId = async (priceGroupId: number) => {
  const response: AxiosResponse<IPriceGroupsView[]> = await http.get(
    'customer-prices/prices/' + priceGroupId
  );
  return response.data;
};

// Customer Prices By Customer Groups
