import CustomSearch, { SelectProps } from '..';
import CustomerGroupsDB from '@/store/localstorage/CustomerGroupsDB';
import { get_customer_group_list_only } from '@/services/offfers/queries';
import { ICustomerGroupListData } from '@/services/offfers/types';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: ICustomerGroupListData) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: ICustomerGroupListData[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function CustomerGroupsSearchV2(props: Props) {
  return (
    <CustomSearch
      formFor="customer group"
      addCallback={CustomerGroupsDB.addCustomerGroups}
      serverCallback={get_customer_group_list_only}
      dbSearchById={(id) => CustomerGroupsDB.getByID(Number(id))}
      dbSearchCallback={CustomerGroupsDB.searchCustomerGroup}
      {...props}
    />
  );
}

export default CustomerGroupsSearchV2;
