import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Modal, Spin, message } from 'antd';

import MenuList from './MenuList';
import TableColumn from './TableColumn';
import PrintTransferOrder from './PrintTransferOrder';
import { User } from '@/services/users/types';
import UsersDB from '@/store/localstorage/UsersDB';
import { IUnits } from '@/services/products/types';

import { get_product_details, get_units_list } from '@/services/products/queries';
import AppContent from '@/components/Common/Content/Content';
import LocationsDB from '@/store/localstorage/LocationsDB';

import UnitsDB from '@/store/localstorage/UnitsDB';
import { get_user_details } from '@/services/users/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { ITransferDetails, ITransferLine } from '@/services/transfer/types';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';

import { get_transfer_order_by_id } from '@/services/transfer/queries';
import {
  create_transfer_mutation,
  edit_transfer_order_archive_mutation
} from '@/services/transfer/mutations';
import DebounceButton from '@/components/Common/DebounceButton';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { getCategoryById, getProductById } from '@/services';
import { find_default_product_category } from '@/store/localstorage/preferences';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';

function TransferOrderView() {
  const { id } = useParams();
  const navigateTo = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [lines, setProductLines] = useState<ITransferLine[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState<User | null>(null);
  const [totalQuantity, setTotalQuantity] = useState('');
  const [total, setTotal] = useState({ taxable: 0, nonTaxable: 0, vat: 0, total: 0 });

  const [showFinalizeModal, setShowFinalizeModal] = useState(false);

  const breadcrumbItems = [{ label: 'Transfer Order', link: '/transfer/order' }, { label: 'View' }];

  // check finalize_confirmation=true in the url
  const isFinalizeConfirmation = searchParams.get('finalize_confirmation') === 'true';
  useEffect(() => {
    const hasFinalizeConfirmation = searchParams.has('finalize_confirmation');
    if (!hasFinalizeConfirmation) return;

    setShowFinalizeModal(isFinalizeConfirmation);
  }, [isFinalizeConfirmation]);

  const { data: transferOrder } = useQuery(
    ['transferOrder', id],
    async () => {
      setIsLoading(true);
      const { data } = await get_transfer_order_by_id(parseInt(id as string));

      // Get the user details of the user who created the transfer order
      if (data?.createdBy) {
        let user = await UsersDB.getUser(data.createdBy);
        if (!user) {
          user = (await get_user_details(data.createdBy)).user;
          UsersDB.addUsers([user]);
        }

        setCreatedBy(user);
      }

      const totalQuantityList: Record<string, number> = {};
      const total = { taxable: 0, nonTaxable: 0, vat: 0, total: data.totalAmount };

      const productsPromises = data.lines.map(async (line, index) => {
        let product: any = await ProductsDB.getProduct(line.productId);
        if (!product) {
          product = await get_product_details(line.productId);
          ProductsDB.addProducts([product]);
        }

        line.productName = product.name;
        line.quantity = Number(numberDecimalFormatter(line.quantity));

        // Get units. If absent, fetch data and assigned units
        let unit = (await UnitsDB.getUnit(line.unitId)) as IUnits;
        if (!unit) {
          // Get all units and add to the indexDB
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);

          unit = (await UnitsDB.getUnit(line.unitId)) as IUnits;
        }

        // Add the unit name to the line
        const shortUnitName = unit.shortName;
        const hasUnitQuantity = totalQuantityList[shortUnitName];
        totalQuantityList[shortUnitName] = hasUnitQuantity
          ? hasUnitQuantity + line.quantity
          : line.quantity;

        line.unitName = shortUnitName;

        if (line.vat > 0) {
          total.vat += line.vat;
          total.taxable += line.totalAmount - line.vat;
        } else {
          total.nonTaxable += line.totalAmount;
        }

        return line;
      });

      const products = await Promise.all(productsPromises);
      setProductLines(products);
      setTotal(total);

      // Add From and To location to the transfer order
      const fromLocation = await LocationsDB.getLocation(data.from);
      const toLocation = await LocationsDB.getLocation(data.to);
      const category = await getCategoryById(data.categoryId);

      data.fromName = fromLocation?.name;
      data.toName = toLocation?.name;
      data.categoryName = category?.name;

      const quantity = Object.keys(totalQuantityList)
        .map((qty) => {
          return `${totalQuantityList[qty]} ${qty}`;
        })
        .join(', ');

      setTotalQuantity(quantity);

      return data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        setIsLoading(false);
      }
    }
  );

  const createTransferMutation = useMutation(create_transfer_mutation);
  const transferOrderEdit = useMutation(edit_transfer_order_archive_mutation);

  async function handleTransferFinalize() {
    setIsLoading(true);
    setShowFinalizeModal(false);

    try {
      if (!transferOrder) {
        message.error('Transfer order details not found');
        return;
      }

      const data = {
        date: transferOrder.transferDate,
        categoryId: transferOrder.categoryId,
        from: transferOrder.from,
        to: transferOrder.to,
        checklist: transferOrder.checklist,
        createdBy: transferOrder.createdBy,
        archived: false,
        lines: transferOrder.lines.map((line) => {
          return {
            id: line.id,
            productId: line.productId,
            unitId: line.unitId,
            quantity: Number(line.quantity),
            unitPrice: line.unitPrice,
            discount: line.discount,
            misc: line.misc,
            lotId: line.lotId as number,
            grade: line.grade,
            isTraceable: line.isTraceable,
            vat: line.vat
          };
        })
      };

      const allProductsIds = data.lines.map((value) => value.productId);
      let categoryId = null;
      for (const productId of allProductsIds) {
        const product = await getProductById(productId);
        if (!categoryId) {
          categoryId = product.categoryId;
          continue;
        }

        if (product.categoryId !== categoryId) {
          categoryId = null;
          break;
        }
      }

      if (!categoryId) {
        const defaultCategory = find_default_product_category();
        if (!defaultCategory) {
          throw {
            name: 'CategoryError',
            message: 'Please select a default Product Category from global preferences.'
          };
        } else {
          categoryId = defaultCategory;
        }
      }

      data.categoryId = categoryId;

      await createTransferMutation.mutateAsync(data, {
        async onSuccess() {
          message.success('Transfer order finalized successfully');
          await transferOrderEdit.mutateAsync({ id: transferOrder.id, archived: true });
          navigateTo('/transfer/order');
        }
      });
    } catch (error: any) {
      if ('name' in error) {
        CustomErrorModal({
          title: error.name,
          message: error.message
        });
      } else {
        CustomErrorModal({ message: getErrorMessage(error) });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const debounceHandleTransferFinalize = useDebounceFunction(handleTransferFinalize);

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <Modal
            title="Confirmation"
            visible={showFinalizeModal}
            onOk={debounceHandleTransferFinalize}
            onCancel={() => {
              setSearchParams({});
              setShowFinalizeModal(false);
            }}>
            <div>Are you sure you want to finalize this transfer order?</div>
          </Modal>
          <div className="space-y-4">
            <div className="grid grid-cols-4 gap-3">
              <MenuList createdBy={createdBy} data={transferOrder} />
            </div>

            <TableColumn data={lines} />

            <div style={{ color: 'black' }}>
              <span className="block">Total Quantity: {totalQuantity}</span>
              <span className="block">Taxable : {nepaliNumberFormatter(total.taxable)}</span>
              <span className="block">Non Taxable : {nepaliNumberFormatter(total.nonTaxable)}</span>
              <span className="block">VAT : {nepaliNumberFormatter(total.vat)}</span>
              <span className="block">Total Amount : {nepaliNumberFormatter(total.total)}</span>
            </div>
          </div>

          <div className="flex justify-end  gap-5 mt-5">
            <div
              style={{
                width: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <PrintTransferOrder
                setIsLoading={setIsLoading}
                transferOrder={transferOrder as ITransferDetails}
              />
            </div>

            {/* // Button */}
            <DebounceButton type="primary" onClick={() => setShowFinalizeModal(true)}>
              Finalize
            </DebounceButton>
            <DebounceButton type="default" onClick={() => navigateTo(`/transfer/order/edit/${id}`)}>
              Update
            </DebounceButton>
            <Button type="default" onClick={() => navigateTo('/transfer/order')}>
              Back
            </Button>
          </div>
        </Spin>
      </AppContent>
    </div>
  );
}

export default TransferOrderView;
