import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomerGroupsSearchV2 from '@/components/Common/CustomSearch/Customer/CustomerGroups';
import {
  download_customer_price_groups,
  get_customer_price_groups_customerGroupId
} from '@/services/customer-prices/queries';
import getErrorMessage from '@/utils/getError';
import { Button, Form, PageHeader, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

interface FormValues {
  customerGroupId: number;
  priceGroupId: number;
}

function CustomerPriceGroupsDownload() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm<FormValues>();
  const [priceGroup, setPriceGroup] = useState<{ label: string; value: number }[]>([]);

  const priceGroupId = Form.useWatch('priceGroupId', form);

  const breadcrumbItems = [
    { label: 'Customer Price Groups', link: '/price-groups/customers' },
    { label: 'Download' }
  ];

  async function handleCustomerGroupChange(customerGroupId?: number) {
    try {
      setIsLoading(true);
      form.setFieldValue('priceGroupId', undefined);
      if (!customerGroupId) {
        setPriceGroup([]);
        return;
      }

      const priceGroups = await get_customer_price_groups_customerGroupId(customerGroupId);
      const options = priceGroups.results.map((group) => ({ label: group.name, value: group.id }));
      setPriceGroup(options);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);

      if (!values.priceGroupId) {
        return CustomErrorModal({ message: 'Please select price group to download' });
      }

      const url = await download_customer_price_groups(values.priceGroupId);
      window.open(url, '_blank');
    } catch (error) {
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader subTitle="Price Information by Customer Group" style={{ padding: '8px 0px' }} />
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
            <CustomerGroupsSearchV2
              hasParentFormItem={false}
              name={'customerGroupId'}
              required
              onSelect={handleCustomerGroupChange}
            />

            <Form.Item
              label="Price Group"
              name="priceGroupId"
              rules={[{ required: true, message: 'Please select a price group!' }]}>
              <Select
                showSearch
                optionLabelProp="label"
                dropdownMatchSelectWidth={false}
                options={priceGroup}
                placeholder="Select Price Group"
              />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-4">
            <Button disabled={isLoading || !priceGroupId} type={'primary'} htmlType="submit">
              Download
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CustomerPriceGroupsDownload;
