import { cn } from '@/utils';
import { FormInstance, Input, InputNumber } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import moment from 'moment';
import cronstrue from 'cronstrue';
import cronParser from 'cron-parser';
import { sanitizeInput, validateCronInput } from '@/services/schedule/services';

const fields = [
  { name: 'second', range: [0, 59], maxLength: 2, isNumber: true },
  { name: 'minute', range: [0, 59], maxLength: 2, isNumber: true },
  { name: 'hour', range: [0, 23], maxLength: 2, isNumber: true },
  { name: 'day (month)', range: [1, 31], maxLength: 2 },
  { name: 'month', range: [1, 12], maxLength: 2 },
  { name: 'day (week)', range: [0, 6], maxLength: 1 }
];

export interface CronSelectorRef {
  values: string[];
  errors: string[];
}

interface Props {
  form?: FormInstance;
  defaultCron?: string;
}

const DEFAULT_CRON_EXPRESSION = '0 0 0 * * *';

const CronSelector = forwardRef<CronSelectorRef, Props>(
  ({ defaultCron = DEFAULT_CRON_EXPRESSION, form }, ref) => {
    const [currentActiveInput, setCurrentActiveInput] = useState<number>();
    const [values, setValues] = useState<string[]>([]);
    const [errors, setErrors] = useState(['', '', '', '', '', '']);

    const [visualText, setVisualText] = useState({ cronText: '', nextExecution: '' });

    useImperativeHandle(ref, () => ({ values, errors }));

    useEffect(() => {
      const splits = defaultCron.split(' ');
      const newValues = splits.length === 6 ? splits : DEFAULT_CRON_EXPRESSION.split(' ');
      setValues(newValues);

      form?.setFieldValue('cronExpression', newValues.join(' '));
    }, [defaultCron]);

    useEffect(() => {
      if (values.length === 6) {
        const cronExpression = values.join(' ');
        try {
          const humanReadableText = cronstrue.toString(cronExpression, {
            use24HourTimeFormat: true
          });
          const nextDate = cronParser.parseExpression(cronExpression).next().toDate();
          const momentString = moment(nextDate).format('YYYY-MM-DD hh:mm:ss a');

          setVisualText({ cronText: humanReadableText, nextExecution: momentString });
          setErrors(['', '', '', '', '', '']);
        } catch (error) {
          setVisualText({ cronText: 'Invalid cron expression', nextExecution: '' });
        }
      }
    }, [values]);

    const handleInputChange = (value: string, index: number) => {
      const sanitizedValue = sanitizeInput(value);
      const newValues = [...values];
      newValues[index] = sanitizedValue;

      const field = fields[index];
      const isValid = validateCronInput(sanitizedValue, field.range);

      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = isValid ? '' : `must be between ${field.range[0]} and ${field.range[1]}`;
        return newErrors;
      });

      setValues(newValues);
      form?.setFieldValue('cronExpression', newValues.join(' '));
    };

    return (
      <div className="flex items-center flex-col w-full space-y-4">
        <div className={'text-center'}>
          <span
            className={cn('block italic text-lg min-h-[2.2rem] font-serif ', {
              invisible: !visualText.cronText
            })}>
            "{visualText.cronText}"
          </span>
          <div className={!visualText.nextExecution ? 'invisible' : ''}>
            <span className="underline">next</span> at {visualText.nextExecution}
          </div>
        </div>

        <div className="flex gap-4 flex-wrap justify-center">
          {fields.map((field, i) => {
            return (
              <div key={field.name} className="flex flex-col gap-4 cron-input">
                {field.isNumber && (
                  <InputNumber
                    value={values[i]}
                    controls={false}
                    min={String(field.range[0])}
                    max={String(field.range[1])}
                    onChange={(e) => handleInputChange(e ? e.toString() : '0', i)}
                    onBlur={() => setCurrentActiveInput(undefined)}
                    onFocus={() => setCurrentActiveInput(i)}
                  />
                )}

                {!field.isNumber && (
                  <Input
                    value={values[i]}
                    maxLength={field.maxLength}
                    onChange={(e) => handleInputChange(e.target.value, i)}
                    onBlur={() => setCurrentActiveInput(undefined)}
                    onFocus={() => setCurrentActiveInput(i)}
                  />
                )}

                <div
                  className={cn({
                    'text-blue-500': currentActiveInput === i && !errors[i],
                    'text-red-500': errors[i]
                  })}>
                  {field.name}
                </div>
              </div>
            );
          })}
        </div>

        {/* Explanation */}

        <table className="mx-auto table-fixed border-collapse cron-explanation max-w-md w-full">
          <tbody>
            {fields.map((field, i) => (
              <tr
                key={field.name}
                className={cn({ hidden: !errors[i], 'text-red-500': errors[i] })}>
                <th>{field.name}</th>
                <td>{errors[i]}</td>
              </tr>
            ))}
          </tbody>
          <tbody>
            <tr>
              <th>*</th>
              <td>any value</td>
            </tr>
            <tr
              style={{ height: 29.2 }}
              className={cn({ hidden: currentActiveInput !== undefined })}></tr>
          </tbody>

          <tbody className={cn({ hidden: currentActiveInput !== 0 && currentActiveInput !== 1 })}>
            <tr>
              <th>0-59</th>
              <td>allowed values</td>
            </tr>
          </tbody>
          <tbody className={cn({ hidden: currentActiveInput !== 2 })}>
            <tr>
              <th>0-23</th>
              <td>allowed values</td>
            </tr>
          </tbody>
          <tbody className={cn({ hidden: currentActiveInput !== 3 })}>
            <tr>
              <th>1-31</th>
              <td>allowed values</td>
            </tr>
          </tbody>
          <tbody className={cn({ hidden: currentActiveInput !== 4 })}>
            <tr>
              <th>1-12</th>
              <td>allowed values</td>
            </tr>
          </tbody>
          <tbody className={cn({ hidden: currentActiveInput !== 5 })}>
            <tr>
              <th>0-6</th>
              <td>allowed values (0 = Sunday)</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
);

CronSelector.displayName = 'CronSelector';
export default CronSelector;
