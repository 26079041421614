import moment from 'moment';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import CopyButton from '../../../../components/Common/CopyButton';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import CustomUpdateIcon from '../../../../components/Common/CustomIcons/CustomUpdateIcon';
import { optionalNumberSorter, optionalStringSorter } from '../../../../utils/sorter.utils';
import DeleteConfirmModal from '../../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import InvoicePrintButton from '../../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface TableColumnsProps {
  sortedInfo: SorterResult<any>;
  page: number;
  size: number;
  onDelete: (id: number | string) => Promise<void>;
  onGenerate: (record: any, curr: any) => void;
}

export const getTransferOrderTable = ({
  sortedInfo,
  page,
  size,
  onDelete,
  onGenerate
}: TableColumnsProps) => {
  return [
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Id',
      key: 'id',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 40,

      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            {/* // TODO: Create a new page for transfer order view  */}
            <Link to={`/transfer/order/view/${record.id}`} color="black">
              {record.financialReference}
            </Link>
            <CopyButton text={record.financialReference} />
          </TableCell>
        );
      }
    },
    {
      title: 'Category',
      key: 'categoryName',
      width: 20,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      sortOrder: sortedInfo.columnKey === 'categoryName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.categoryName}</TableCell>;
      }
    },
    {
      title: 'From',
      key: 'fromName',
      width: 20,

      sorter: (a, b) => a.fromName.localeCompare(b.fromName),
      sortOrder: sortedInfo.columnKey === 'fromName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/transfers/view/${record.id}?type=out`}>
          <TableCell>{record.fromName}</TableCell>
          //  </Link>;
        );
      }
    },
    {
      title: 'To',
      key: 'toName',
      width: 20,

      sorter: (a, b) => a.toName.localeCompare(b.toName),
      sortOrder: sortedInfo.columnKey === 'toName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          //  <Link to={`/transfers/view/${record.id}?type=out`}>
          <TableCell>{record.toName}</TableCell>
        );
        // </Link>;
      }
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      className: 'highlight',
      width: 20,
      dataIndex: 'totalAmount',
      align: 'right',

      sorter: (a, b) => optionalNumberSorter(a.totalAmount, b.totalAmount),
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (totalAmount) => {
        return <TableCell>{nepaliNumberFormatter(totalAmount)}</TableCell>;
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 20,

      sorter: (a, b) => optionalStringSorter(a.createdByName, b.createdByName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'transferDate',
      width: 20,

      sorter: (a, b) => moment(a.transferDate).unix() - moment(b.transferDate).unix(),
      sortOrder: sortedInfo.columnKey === 'transferDate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 7,
      fixed: 'right',
      render: (record) => {
        const menuItems = [
          {
            key: 'menu_action_transfer_order_edit',
            label: <CustomUpdateIcon link={`/transfer/order/edit/${record.id}`} />
          },
          {
            key: 'menu_action_transfer_order_invoice_print',
            label: (
              <InvoicePrintButton
                getInvoice={(curr) => {
                  onGenerate(record, curr);
                }}
              />
            )
          },
          {
            key: 'menu_action_transfer_order_delete',
            label: (
              <DeleteConfirmModal
                FunctionAfterOk={() => {
                  onDelete(record.id);
                }}
              />
            )
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ] as ColumnsType<any>;
};
