import AppContent from '@/components/Common/Content/Content';
import { LineType } from '@/services/report/enums';
import getErrorMessage from '@/utils/getError';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { Collapse, Form, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useEffect, useState } from 'react';
import moment from 'moment';
import FilterForm from './Form';
import { download_report, get_report_without_count } from '@/services/report/queries';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { ILatestProductLineReport } from '@/services/report/types';
import Table from './Table';

export interface FormValues {
  identifier?: LineType;
  endDateMoment?: moment.Moment;
  endDate: string;
  endDateNepali?: string;
  locationId: number | string;
  productId: number | string;
  categoryId: number | string;
  unitId: number | string;
  page: number;
  size: number;
}

function LastIdentifierReport() {
  const [isLoading, setIsLoading] = useState(false);
  const report = { id: 78, name: 'Latest Product Line Report' };
  const [isEmpty, setIsEmpty] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const [pagination, setPagination] = useState({ page: 1, size: 10 });
  const [data, setData] = useState<ILatestProductLineReport[]>([]);

  useEffect(() => {
    const todayDate = moment();

    form.setFieldsValue({
      locationId: '',
      productId: '',
      categoryId: '',
      unitId: '',
      endDateMoment: todayDate,
      endDate: todayDate.toISOString(),
      endDateNepali: convert_string_to_nepali_date_string(todayDate.format('YYYY-MM-DD HH:mm')),
      ...pagination
    });
  }, []);

  async function onSearch(values: FormValues) {
    try {
      setIsLoading(true);
      delete values.endDateNepali;
      delete values.endDateMoment;

      const data = await get_report_without_count(report.id, values);
      setData(data.data);
      setIsEmpty(!data.data.length);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }

    try {
      setIsLoading(true);
      const values = await form.validateFields();
      delete values.endDateNepali;
      delete values.endDateMoment;

      message.info('Report is being generated. Download will start soon.');
      await download_report({ ...values, customReportQueueName: report.name }, report.id);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onSizeChange(size: number) {
    const formValues = await form.validateFields();
    const newSize = size !== 0 ? size : pagination.size;
    setPagination((prev) => {
      if (size !== 0) prev.size = size;
      return prev;
    });

    formValues.size = newSize;
    onSearch(formValues);
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Reports', link: '/reports' },
          { label: 'Latest Product Line Report' }
        ]}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              setIsEmpty={setIsEmpty}
              onDownload={onDownload}
              onSearch={onSearch}
            />
          </CollapsePanel>
        </Collapse>

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <Table data={data} pagination={pagination} onSizeChange={onSizeChange} />
      </AppContent>
    </Spin>
  );
}

export default LastIdentifierReport;
