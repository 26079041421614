import { useQuery } from '@tanstack/react-query';
import { Button, Select, PageHeader, Spin, Table, Divider, Menu, Tooltip } from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '../../../components/Common/Content/Content';
import { get_product_list_ids, get_units_list } from '../../../services/products/queries';
import {
  get_adjustment_details,
  get_adjustment_line_details,
  get_reasons_list
} from '../../../services/adjustments/queries';
import { get_location_details } from '../../../services/locations/queries';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import ReuseChannel from '../../channel/Reuse';
import { checkAccess } from '../../../routes/acl';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { get_invoices_list } from '../../../services/settings/queries';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import { IProductType } from '../../../services/products/types';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { IAdjustmentInvoice } from '../../../services/invoice/types';
import { getAdjustmentPrintData } from '../../../components/Common/InvoicePrint/AdjustmentInvoice/services';
import { CustomModal } from '../../../components/Common/CustomModal';
import AdjustmentInvoice from '../../../components/Common/InvoicePrint/AdjustmentInvoice/AdjustmentInvoice';
import InvoicePrintButton from '../../../components/Common/InvoicePrintButton/InvoicePrintButton';

const { Option } = Select;
interface AdjustmentResuableViewProps {
  id: number;
}
const AdjustmentResuableView: React.FC<AdjustmentResuableViewProps> = ({ id }) => {
  const navigate = useNavigate();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [adjustmentDetails, setAdjustmentDetails] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [lines, setLines] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<IAdjustmentInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  const { refetch } = useQuery(['adjustment-view', id], async () => {
    const response: any = await get_adjustment_details(id);
    // console.log('responsess-> ', response);
    // const response: any = await get_adjustments_list();
    const reasons: any = await get_reasons_list();
    if (response?.data) {
      let locD: any = await LocationsDB.getLocation(response.data.locationId);
      if (!locD) {
        locD = await get_location_details(response.data.locationId);
        await LocationsDB.addLocations([locD]);
      }
      const reason = reasons?.data?.find((curr: any) => curr.id == response.data.reasonId);
      const linesResponse = await get_adjustment_line_details(response.data.status, id);
      // console.log('linesResponse', linesResponse);
      if (linesResponse.data.length > 0) {
        const searchProducts: any = {};
        for (let index = 0; index < linesResponse.data.length; index++) {
          const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
          if (!product) {
            // product = await get_product_details(linesResponse.lines[index].productId);
            // await ProductsDB.addProducts([product]);
            if (linesResponse.data[index].productId in searchProducts) {
              searchProducts[linesResponse.data[index].productId] = [
                ...searchProducts[linesResponse.data[index].productId],
                index
              ];
            } else {
              searchProducts[linesResponse.data[index].productId] = [index];
            }
          } else {
            linesResponse.data[index].productName = product.name;
          }

          let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
          if (!findUnit) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
          }
          // linesResponse.lines[index].unitName = findUnit.name;
          linesResponse.data[
            index
          ].quantity = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
        }
        // console.log('Search Products-->', searchProducts);
        const searchProductslength = Object.entries(searchProducts).length;
        if (searchProductslength > 0) {
          const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
          for (const key in searchProducts) {
            const findproduct = productsresponse?.data?.results.find(
              (currProduct: IProductType) => currProduct.id == key
            );
            for (let i = 0; i < searchProducts[key].length; i++) {
              linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }
      setLines(linesResponse.data);
      setAdjustmentDetails({ ...response.data, reasonName: reason?.name });
      setLocationDetails(locD);
      setIsloading(false);
    }
    return response;
  });

  const breadcrumbItems = [
    {
      label: 'Adjustments',
      link: '/adjustments'
    },
    {
      label: 'Edit'
    }
  ];

  const columns: ColumnsType<{ name: string }> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text: number) => <a>{text}</a>
    },
    {
      title: 'Lot & Expiry',
      dataIndex: 'lotExpiryDuration',
      render: (text: string) => <a>{text}</a>
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text: number) => <a>{text}</a>
    },
    {
      title: 'Subtotal',
      dataIndex: 'totalAmount',
      render: (text: number) => <a>{text}</a>
    }
  ];

  const generateInvoiceForPrint = async (curr: any) => {
    setIsloading(true);

    const invoiceModalData = await getAdjustmentPrintData(
      adjustmentDetails.id,
      adjustmentDetails.status,
      adjustmentDetails.reason,
      adjustmentDetails.locationId,
      curr
    );

    setIsloading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    refetch();
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <CustomModal
            footer={false}
            isModalOpen={openModalForInvoicePrint}
            setIsModalOpen={setOpenModalForInvoicePrint}
            title="Adjustment Invoice Print">
            <AdjustmentInvoice
              adjustmentDetails={invoiceData.adjustmentDetails}
              lines={invoiceData.lines}
              locationDetails={invoiceData.locationDetails}
              invoiceLayouts={invoiceData.invoiceLayouts}
              handleModalClose={handleInvoicePrintModalClose}
            />
          </CustomModal>
          <PageHeader
            title="Adjustment Information"
            style={{
              padding: '4px 0px'
            }}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Location"
                style={{
                  padding: '8px 0px'
                }}
              />
              {locationDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {locationDetails?.name ? locationDetails.name : ''}
                    {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                      locationDetails?.address ? `${locationDetails.address},` : ''
                    } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                      locationDetails?.country ? `${locationDetails.country}` : ''
                    }`}
                    {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                      locationDetails?.email ? `${locationDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px'
                }}
              />
              {adjustmentDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    Financial Reference : {adjustmentDetails.financialReference}
                    Created : {adjustmentDetails.createdAt.substr(0, 10)}
                    Reason: {adjustmentDetails.reasonName}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Table columns={columns} dataSource={lines ? lines : []} rowKey={'id'} />

          <div className="flex justify-end  gap-5 mt-5">
            <div
              style={{
                width: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InvoicePrintButton
                getInvoice={(curr) => {
                  generateInvoiceForPrint(curr);
                }}
              />
              {/* <ActionDropdown
                trigger={'click'}
                insideaction={true}
                menu={
                  <Menu
                    items={invoiceLists?.map((curr: any, ind: number) => {
                      return {
                        key: ind,
                        label: (
                          <Tooltip title="Print receipt" color="blue">
                            <div className="text-center">{curr.name}</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          generateInvoiceForPrint(curr);
                        }
                      };
                    })}
                  />
                }
              /> */}
            </div>
            <Button type="default" onClick={() => navigate('/adjustments')}>
              Back
            </Button>
          </div>
        </Spin>
        <Divider />
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`adjustments_${id}`} />}
      </AppContent>
    </div>
  );
};

export default AdjustmentResuableView;
