import React, { useState, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Button, Input, message, Form, Menu, TableProps, Tooltip, Spin, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';
import { get_invoices_list } from '@/services/settings/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import { get_transfer_list } from '@/services/transfer/queries';
import moment from 'moment';
import TableFilter from '@/components/FliterTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { ConvertObjectToURL } from '@/utils/converturl';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { SorterResult } from 'antd/lib/table/interface';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CopyButton from '@/components/Common/CopyButton';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import { getUserData } from '@/utils/auth.utils';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { ITransferData } from '@/services/transfer/types';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import TransferInvoice from '@/components/Common/InvoicePrint/TransferInvoice/TransferInvoice';
import { getTransferPrintData } from '@/components/Common/InvoicePrint/TransferInvoice/services';
import { ITransferInvoice } from '@/services/invoice/types';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import { optionalNumberSorter, optionalStringSorter } from '@/utils/sorter.utils';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { checkAccess } from '@/routes/acl';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { getCategoryById } from '@/services';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';

const TransfersList: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const printPDFRef = useRef<any>();
  const [isLoading, setIsloading] = useState(true);
  const [alltransferList, setalltransferList] = useState<ITransferData>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [invoiceData, setInvoiceData] = useState<ITransferInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_transfer_list(filter);
    if (response?.data.results) {
      //locaton name extract
      for (let index = 0; index < response.data.results.length; index++) {
        //from
        let location: any = await LocationsDB.getLocation(response.data.results[index].from);
        if (!location) {
          location = await get_location_details(response.data.results[index].from);
          await LocationsDB.addLocations([location]);
        }
        response.data.results[index].fromName = location.name;
        //to
        location = await LocationsDB.getLocation(response.data.results[index].to);
        if (!location) {
          location = await get_location_details(response.data.results[index].to);
          await LocationsDB.addLocations([location]);
        }
        response.data.results[index].toName = location.name;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );

        // created By
        if (response.data.results[index].createdBy) {
          let userDetails = await UsersDB.getUser(response.data.results[index].createdBy);
          if (!userDetails) {
            const allUsers = await get_user_list_ids([
              ...new Set(
                response.data.results
                  .filter((val) => val.createdBy !== null)
                  .map((value) => {
                    return value.createdBy;
                  })
              )
            ]);
            await UsersDB.addUsers(allUsers.data.results);
            userDetails = await UsersDB.getUser(response.data.results[index].createdBy);
          }
          response.data.results[index].createdByName = userDetails.name;
        }
      }
    }

    const allCategoryIds = response.data.results.map((val) => val.categoryId);
    await CategoryDB.addCategoryIfAbsent(allCategoryIds);

    for (let index = 0; index < response.data.results.length; index++) {
      const category = await getCategoryById(response.data.results[index].categoryId);
      response.data.results[index].categoryName = category.name;
    }

    setalltransferList(response.data);
    setIsloading(false);
    return response;
  };

  const generateInvoiceForPrint = async (record: any, curr: any) => {
    setIsloading(true);
    const invoiceModalData = await getTransferPrintData(record.id, record.from, record.to, curr);
    console.log(invoiceModalData);
    setIsloading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    const values = form.getFieldsValue();
    console.log(values);
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record) => {
        return (
          //  <Link to={`/transfers/view/${record.id}?type=out`}>
          <TableCell>{record.id}</TableCell>
        );
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 20,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            <Link to={`/transfers/view/${record.id}?type=out`}>{record.financialReference}</Link>
            {record.financialReference && <CopyButton text={record.financialReference} />}
          </TableCell>
        );
      }
    },
    {
      title: 'Category',
      key: 'categoryName',
      dataIndex: 'categoryName',
      width: 16,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      sortOrder: sortedInfo.columnKey === 'categoryName' ? sortedInfo.order : null
    },
    {
      title: 'From',
      key: 'fromName',
      width: 20,
      sorter: (a, b) => a.fromName.localeCompare(b.fromName),
      sortOrder: sortedInfo.columnKey === 'fromName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/transfers/view/${record.id}?type=out`}>
          <TableCell>{record.fromName}</TableCell>
          //  </Link>;
        );
      }
    },
    {
      title: 'To',
      key: 'toName',
      width: 20,
      sorter: (a, b) => a.toName.localeCompare(b.toName),
      sortOrder: sortedInfo.columnKey === 'toName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          //  <Link to={`/transfers/view/${record.id}?type=out`}>
          <TableCell>{record.toName}</TableCell>
        );
        // </Link>;
      }
    },

    {
      title: 'Total Amount',
      key: 'totalAmount',
      className: 'highlight',
      dataIndex: 'totalAmount',
      width: 16,
      sorter: (a, b) => optionalNumberSorter(a.totalAmount, b.totalAmount),
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (totalAmount) => {
        return <TableCell>{nepaliNumberFormatter(totalAmount)}</TableCell>;
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 20,
      sorter: (a, b) => optionalStringSorter(a.createdByName, b.createdByName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'transferDate',
      width: 20,
      sorter: (a, b) => moment(a.transferDate).unix() - moment(b.transferDate).unix(),
      sortOrder: sortedInfo.columnKey === 'transferDate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/transfers/view/${record.id}?type=out`}>
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: 10,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      render: (record) => {
        const colorCode: any = { APPROVED: 'green', REJECTED: 'red', PENDING: 'purple' };
        return <Tag color={colorCode[record.status] || 'green'}>{record.status}</Tag>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 7,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        if (record.status == 'PENDING') {
          menuItems.push({
            key: '1',
            label: <Link to={`/transfer-in/${record.id}`}>Approve</Link>
          });

          if (checkAccess('APPROVE_TRANSFER')) {
            menuItems.push({
              key: 'Reject',
              label: <Link to={`/transfer-reject/${record.id}`}>Reject</Link>
            });
          }
        }

        menuItems.push({
          key: '2',
          label: (
            <InvoicePrintButton
              getInvoice={(curr) => {
                generateInvoiceForPrint(record, curr);
              }}
            />
          )
        });

        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Transfers',
      link: '/transfers'
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url);
    setIsloading(false);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns = [
        { title: 'S.N', dataIndex: 'SN', width: 50 },
        { title: 'ID', dataIndex: 'id', width: 100 },
        { title: 'Financial Reference', width: 200, dataIndex: 'financialReference' },
        { title: 'Category', width: 250, dataIndex: 'categoryName' },
        { title: 'From', width: 250, dataIndex: 'fromName' },
        { title: 'To', width: 250, dataIndex: 'toName' },
        { title: 'Status', width: 250, dataIndex: 'status' },
        { title: 'Total Amount', width: 250, dataIndex: 'totalAmount' },
        { title: 'Created By', width: 250, dataIndex: 'createdByName' },
        { title: 'Date', width: 200, dataIndex: 'date' }
      ];
      if (alltransferList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = alltransferList.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
        };
      });

      exportExcelAutoWidth(columns, dataUpdated, 'Transfer Out List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      label: 'ID',
      dataIndex: 'id',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Financial Reference.',
      dataIndex: 'financialReference',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Category',
      dataIndex: 'categoryName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'From',
      dataIndex: 'fromName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'To',
      dataIndex: 'toName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Amount',
      dataIndex: 'totalAmount',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Created By',
      dataIndex: 'createdByName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Date',
      dataIndex: 'date',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Transfer Invoice Print">
        <TransferInvoice
          transferData={invoiceData.transferData}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Transfer Out View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={alltransferList.results}
            reff={printPDFRef}
            title={'Transfer Out List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [...CustomDatePresets.Today],
                  locationId: preferenceLocationId ? preferenceLocationId : '',
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                form={form}
                onSubmit={onSubmitFilter}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    <div>
                      <CustomButton
                        onClick={() => navigate('/transfers/new')}
                        text="Add"
                        backgroundColor="#1890ff"
                        Linkto="/transfers/new"
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  label="Location(To)"
                />

                <ProductCategorySearchV2 hasParentFormItem={false} name={'categoryId'} showAll />

                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        {/* <Modal
          width={`80%`}
          title={'Approve Details'}
          visible={isModalOpenApp}
          onOk={handleOk}
          okText={'Approve'}
          onCancel={handleCancel}>
          <Form
            form={form}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off"
            disabled={isLoading}>
            <Form.Item
              label={'Crate'}
              name={['crate']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label={'Sack'}
              name={['sack']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label={'Bags'}
              name={['bags']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item label={'Others'} name={['others']}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          width={`80%`}
          title={'Acknowledge Details'}
          visible={isModalOpenAck}
          onOk={handleOk2}
          okText={'Acknowledge'}
          onCancel={handleCancel2}>
          {displayAcknowledge()}
        </Modal> */}
        {/* <TableFilter
          defaultValues={{
            dateCustom: [moment(), moment()],
            test: 'aaaa'
          }}
          initial={true}
          onSubmit={onSubmitFilter}></TableFilter>
        <Button
          htmlType={'button'}
          size={'small'}
          type={'primary'}
          className={'me-2'}
          onClick={() => navigate('/transfers/new')}>
          <PlusCircleFilled />
          <span> Add</span>
        </Button> */}

        {/* <Table
          // rowSelection={{
          //   ...rowSelection
          // }}
          scroll={{ y: '65vh', x: true }}
          columns={columns}
          dataSource={transferList?.results}
          rowKey={'id'}
          // expandable={{
          //   expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>
          // }}
          loading={isLoading}
        /> */}
        <div className="flex justify-end mb-2">
          {/* <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
          <CustomButton
            text="PDF Export"
            backgroundColor="#1890ff"
            onClick={() => setOpenModalforexport(true)}
          /> */}
        </div>
        <GenericTable
          columns={columns}
          data={alltransferList.results}
          buttons={
            <div>
              <ActionDropdown
                button={true}
                menu={
                  <Menu
                    items={[
                      {
                        key: '1',
                        label: (
                          <Tooltip title="Export Excel" color="blue">
                            <div className="text-center">Excel</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          handleExport();
                        }
                      },
                      {
                        key: '2',
                        label: (
                          <Tooltip title="Export PDF" color="blue">
                            <div className="text-center">PDF</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          setOpenModalforexport(true);
                        }
                      }
                    ]}
                  />
                }
              />
            </div>
          }
          hideDefaultPagination={true}
          scroll={{ x: 1400, y: '75vh' }}
          pagination={{
            page: page,
            total: alltransferList.total,
            size: size,
            onPagination
          }}
          generateSummary
          summaryClassName="text-left"
          tableName={'transfers-list'}
          toSort={handleChange}
        />

        {/* <ProductFilterDrawers visible={visible} onClose={onClose} /> */}
      </AppContent>
    </Spin>
  );
};

export default TransfersList;
