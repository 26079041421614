import AppContent from '@/components/Common/Content/Content';
import { get_current_price_By_priceGroupId } from '@/services/customer-prices/queries';
import { IPriceGroupsTableView, IPriceGroupsView } from '@/services/products/types';
import { useQuery } from '@tanstack/react-query';
import { Spin, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getProductById, getUnit } from '@/services';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';

import ExportCurrent from '@/components/Common/ExportCurrent';
import { tableExportColumns, excelExportColumns } from './column.export';

function CustomerPriceGroupView() {
  const parsms = useParams();
  const priceGroupId = Number(parsms.id);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPriceGroupsView>>({});
  const pricegroupinfo = JSON.parse(localStorage.getItem('pricegroupinfo')!);

  if (isNaN(priceGroupId)) {
    return <Navigate to={'/price-groups/customers'} />;
  }

  const {
    data,
    isLoading: isDataLoading,
    isFetching
  } = useQuery(['customer-price-group', priceGroupId], async () => {
    if (isNaN(priceGroupId)) return;

    const response = await get_current_price_By_priceGroupId(priceGroupId);
    const allProductIds = response.map((item) => item.productId);
    await ProductsDB.addProductsIfAbsent(allProductIds);

    const allProductData: IPriceGroupsTableView[] = [];

    for (let i = 0; i < response.length; i++) {
      const current = response[i];
      const product = await getProductById(current.productId);
      const unit = await getUnit(current.unitId);

      allProductData.push({ ...current, productName: product.name, unit: unit.shortName });
    }

    return allProductData;
  });

  const isLoading = isDataLoading || isFetching;

  const handleChange: TableProps<IPriceGroupsView>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<IPriceGroupsView>);
  };

  const columns: ColumnsType<IPriceGroupsView> = [
    {
      title: 'ID',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 15,
      render: (record) => {
        return <div color="black">{record.id}</div>;
      }
    },
    {
      title: 'Product',
      key: 'productId',
      sorter: (a, b) => a.productId - b.productId,
      sortOrder: sortedInfo.columnKey === 'productId' ? sortedInfo.order : null,
      width: 50,
      render: (record) => {
        return <div color="black">{record.productName}</div>;
      }
    },
    {
      title: 'Unit',
      key: 'unitId',
      sorter: (a, b) => a.unitId - b.unitId,
      sortOrder: sortedInfo.columnKey === 'unitId' ? sortedInfo.order : null,
      width: 20,
      render: (record) => {
        return <div color="black">{record.unit}</div>;
      }
    },
    {
      title: 'Sell Price',
      key: 'sellingPrice',
      sorter: (a, b) => a.sellingPrice - b.sellingPrice,
      sortOrder: sortedInfo.columnKey === 'sellingPrice' ? sortedInfo.order : null,
      width: 25,
      render: (record) => {
        return <div color="black">{record.sellingPrice}</div>;
      }
    },
    {
      title: 'Cost Price',
      key: 'costPrice',
      sorter: (a, b) => a.costPrice - b.costPrice,
      sortOrder: sortedInfo.columnKey === 'costPrice' ? sortedInfo.order : null,
      width: 25,
      render: (record) => {
        return <div color="black">{record.costPrice}</div>;
      }
    },
    {
      title: 'Currency',
      width: 15,
      key: 'currency',
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      sortOrder: sortedInfo.columnKey === 'currency' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.currency ? record.currency : 'NPR'}</div>
          // </Link>
        );
      }
    }
  ];

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Customer Price Groups', link: '/price-groups/customers' },
        { label: `Products Info of ${pricegroupinfo.name} group.` }
      ]}
      withfilter={false}>
      <CustomizeTable
        columns={columns}
        data={data || []}
        usersLoading={isLoading}
        buttons={
          <>
            <ExportCurrent
              isDisabled={isLoading}
              data={{ total: data?.length || 0, results: data || [] }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title={`Price Group ${pricegroupinfo.name}`}
            />
          </>
        }
        notshowPagination={true}
        customScroll={{ x: 1000, y: '70vh' }}
        toSort={handleChange}
      />
    </AppContent>
  );
}

export default CustomerPriceGroupView;
