import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomerGroupsSearchV2 from '@/components/Common/CustomSearch/Customer/CustomerGroups';
import { upload_customer_price_group_mutation } from '@/services/customer-prices/mutation';
import { get_customer_price_groups_customerGroupId } from '@/services/customer-prices/queries';
import getErrorMessage from '@/utils/getError';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, message, PageHeader, Select, Spin, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload';
import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import { AxiosError } from 'axios';
import { CustomModal } from '@/components/Common/CustomModal';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  customerGroupId: number;
  priceGroupId: number;
}

const breadcrumbItems = [
  { label: 'Customer Price Groups', link: '/price-groups/customers' },
  { label: 'Upload' }
];

function CustomerPriceGroupsUpload() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm<FormValues>();
  const [priceGroup, setPriceGroup] = useState<{ label: string; value: number }[]>([]);
  const navigate = useNavigate();

  const priceGroupId = Form.useWatch('priceGroupId', form);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const uploadMutation = useMutation(upload_customer_price_group_mutation);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  async function handleCustomerGroupChange(customerGroupId?: number) {
    try {
      setIsLoading(true);
      form.setFieldValue('priceGroupId', undefined);
      if (!customerGroupId) {
        setPriceGroup([]);
        return;
      }

      const priceGroups = await get_customer_price_groups_customerGroupId(customerGroupId);
      const options = priceGroups.results.map((group) => ({
        label: group.name,
        value: group.id
      }));
      setPriceGroup(options);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const displayError = (error: AxiosError) => {
    const listOfErrors: string[] = [];
    if (error.response?.data) {
      (error.response.data as any[]).forEach((item: string[][]) => {
        if (item) {
          item.map((i) => {
            listOfErrors.push(i[0]);
          });
        }
      });
    } else {
      listOfErrors.push(error.message);
    }
    setErrors(listOfErrors);
    setIsErrorOpen(true);
  };

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);

      if (!values.priceGroupId) {
        return CustomErrorModal({ message: 'Please select price group to upload' });
      }

      if (fileList.length === 0) {
        return CustomErrorModal({ message: 'Please select file to upload' });
      }

      const formData = new FormData();
      formData.append('file', fileList[0].originFileObj as RcFile);
      await uploadMutation.mutateAsync(
        { id: values.priceGroupId, file: formData },
        {
          onSuccess: async () => {
            message.success('Uploaded successfully');
            navigate(`/price-groups/customers/${values.priceGroupId}`);
          },
          onError: (e: any) => displayError(e)
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <CustomModal isModalOpen={isErrorOpen} setIsModalOpen={setIsErrorOpen} title={'Errors'}>
        <div className="grid grid-cols-1 gap-2 ">
          {errors.map((error, index) => {
            return (
              <div key={index}>
                <div className="text-red-600">{`${index + 1}. ${error}`}</div>
              </div>
            );
          })}
        </div>
      </CustomModal>

      <Spin spinning={isLoading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            subTitle="Price Information by Customer Group"
            style={{ padding: '8px 0px' }}
          />
          <Form form={form} onFinish={onFinish} layout="vertical">
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
              <CustomerGroupsSearchV2
                hasParentFormItem={false}
                name={'customerGroupId'}
                required
                onSelect={handleCustomerGroupChange}
              />
              <Form.Item
                label="Price Group"
                name="priceGroupId"
                rules={[{ required: true, message: 'Please select a price group!' }]}>
                <Select
                  showSearch
                  optionLabelProp="label"
                  dropdownMatchSelectWidth={false}
                  options={priceGroup}
                  placeholder="Select Price Group"
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-5 ">
              <Upload
                name="media"
                multiple={false}
                maxCount={1}
                fileList={fileList}
                accept=".xlsx"
                beforeUpload={() => false}
                showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
                onChange={(e) => setFileList(e.fileList)}>
                <Button
                  style={{ width: 150 }}
                  icon={<UploadOutlined />}
                  disabled={fileList.length > 0}>
                  Upload File
                </Button>
              </Upload>
            </div>
            <div className="flex justify-end mt-4">
              <Button
                disabled={isLoading || !priceGroupId || fileList.length === 0}
                type={'primary'}
                htmlType="submit">
                Upload
              </Button>
            </div>
          </Form>
        </AppContent>
      </Spin>
    </div>
  );
}

export default CustomerPriceGroupsUpload;
