import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ICustomerPriceGroups } from '@/services/customer-prices/types';
import { Menu, message, Modal, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import moment from 'moment';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CustomDeleteIcon from '@/components/Common/CustomIcons/CustomDeleteIcon';
import { checkAccess } from '@/routes/acl';
import CustomDownloadIcon from '@/components/Common/CustomIcons/CustomDownloadIcon';

interface Props {
  isLoading: boolean;
  data: { total: number; results: ICustomerPriceGroups[] };
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
  handleDelete: (id: number) => Promise<void>;
  handleDownload: (id: number) => Promise<void>;
}

function Table({ data, pagination, isLoading, onPagination, handleDelete, handleDownload }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ICustomerPriceGroups>>({});
  const [selected, setSelected] = useState<number>();

  function handleDeleteClick(id: number) {
    setIsModalVisible(true);
    setSelected(id);
  }

  async function handleOk() {
    if (!selected) {
      return message.error('Please select the price group');
    }

    setIsModalVisible(false);
    await handleDelete(selected);
    setSelected(undefined);
  }

  const handleChange: TableProps<ICustomerPriceGroups>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<ICustomerPriceGroups>);
  };

  const columns: ColumnsType<ICustomerPriceGroups> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 15,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Name',
      width: 70,
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'Customer Group',
      width: 70,
      key: 'groupName',
      dataIndex: 'groupName',
      sorter: (a, b) => a.groupName.localeCompare(b.groupName),
      sortOrder: sortedInfo.columnKey === 'groupName' ? sortedInfo.order : null
    },
    {
      title: 'Created Date',
      width: 40,
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Updated Date',
      width: 40,
      key: 'updatedAt',
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 20,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: '7',
            label: <CustomViewIcon link={`/price-groups/customers/${record.id}`} />,
            onClick: () => {
              localStorage.setItem('pricegroupinfo', JSON.stringify(record));
            }
          }
        ];

        if (checkAccess('UPDATE_PRICE_GROUP')) {
          menuItems.push({
            key: '2',
            label: <CustomUpdateIcon link={`/price-groups/customers/details/${record.id}`} />
          });
        }

        if (checkAccess('DELETE_PRICE_GROUP')) {
          menuItems.push({
            key: '4',
            label: <CustomDeleteIcon onClick={() => handleDeleteClick(record.id)} />
          });
        }

        menuItems.push({
          key: 'download',
          label: <CustomDownloadIcon />,
          onClick: () => handleDownload(record.id)
        });

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <Modal
        title="Delete Price Group"
        width={600}
        visible={isModalVisible && selected !== undefined}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}>
        <p>Are you sure you want to delete this price group?</p>
      </Modal>
      <CustomizeTable
        columns={columns}
        data={data.results}
        usersLoading={isLoading}
        tableName="customers-price-group-list"
        toSort={handleChange}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination
        }}
      />
    </div>
  );
}

export default Table;
