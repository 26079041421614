import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ITransfer, ITransferData, ITransferDetails, ITransferLine } from './types';
import { TransactionType } from '../products/enums';
import { getDataForIds, getDataOnlyForIds } from '../../utils/queries.utils';

export interface TransferOrderList {
  archived: boolean;
  checklist: string;
  createdAt: string;
  createdBy: number;
  financialReference: string;
  from: number;
  id: number;
  localDate: string;
  referenceNo: string;
  to: number;
  totalAmount: number;
  transferDate: string;
  updatedAt: string;
  fromName: string;
  toName: string;
  createdByName: string;
  categoryId: number;
  categoryName?: string;
}

interface ITransferOrder {
  results: TransferOrderList[];
  total: number;
}

export const get_transfer_list = async (filter = '') => {
  const response: AxiosResponse<ITransferData> = await http.get('transfers/?' + filter);
  return response;
};

export const get_transfer_order_list = async (filter = '') => {
  const response: AxiosResponse<ITransferOrder> = await http.get('transfer-order/?' + filter);
  return response;
};

export const get_transfer_order_by_id = async (id: number) => {
  const response: AxiosResponse<ITransferDetails> = await http.get('transfer-order/' + id);
  return response;
};

export const get_transfer_details_ids = async (ids: number[]) => {
  return await getDataForIds<ITransfer>(ids, 'transfers/?');
};

export const get_transfers_by_id = async (
  ids: string[] | number[],
  params?: Record<string, any>
) => {
  const response: AxiosResponse<ITransferData> = await http.get('transfers/?', {
    ids,
    count: ids.length,
    ...params
  });
  return response;
};

export const get_transfer_in_list = async (filter = '') => {
  const response: AxiosResponse<ITransferData> = await http.get('transfers/in/?' + filter);
  return response;
};

export const get_transfer_in_details_ids = async (ids: number[]) => {
  return await getDataForIds<ITransfer>(ids, 'transfers/in/?');
};

export const get_transfer_out_details = async (id: number) => {
  const response: AxiosResponse<ITransferDetails> = await http.get('transfers/out/' + id);
  return response.data;
};
export const get_transfer_in_details = async (id: number) => {
  const response: AxiosResponse<ITransferDetails> = await http.get('transfers/in/' + id);
  return response.data;
};

export const get_transfer_lines_details = async (id: number) => {
  const response: AxiosResponse<ITransferLine[]> = await http.get(
    'products/lines/TRANSFER_IN/' + id
  );
  return response;
};

export const get_transfer_out_lines_details = async (id: number) => {
  const response: AxiosResponse<ITransferLine[]> = await http.get(
    'products/lines/TRANSFER_OUT/' + id
  );
  return response;
};

export const get_transfer_order_lines_details = async (id: number) => {
  const response: AxiosResponse<ITransferLine[]> = await http.get(
    'products/lines/TRANSFER_ORDER/' + id
  );
  return response;
};

export const get_latest_transfer_out_lines_details = async (id: number) => {
  const response: AxiosResponse<ITransferLine[]> = await http.get(
    'products/lines-latest/PURCHASE/',
    { ids: [id] }
  );
  return response;
};

export const get_latest_purchase_line_by_location_product = async (
  productId: number,
  locationId: number
) => {
  const response: AxiosResponse<ITransferLine> = await http.get('products/lines-latest/PURCHASE?', {
    locationId: locationId,
    ids: [productId]
  });
  return response.data;
};

export const get_latest_purchase_line_by_location_productIds = async (
  productIds: number[],
  locationId: number
) => {
  return await getDataOnlyForIds<ITransferLine>(
    productIds,
    'products/lines-latest-group/PURCHASE/?',
    { locationId }
  );
};

export const get_latest_transfer_in_by_location_product = async (
  productId: number,
  locationId: number
) => {
  const response: AxiosResponse<ITransferLine> = await http.get(
    'products/lines-latest/TRANSFER_IN?',
    { locationId: locationId, ids: [productId] }
  );
  return response.data;
};

export const get_latest_opening_stock_by_location_product = async (
  productId: number,
  locationId: number
) => {
  const response: AxiosResponse<ITransferLine> = await http.get(
    'products/lines-latest/OPENING_STOCK?',
    { locationId: locationId, ids: [productId] }
  );
  return response.data;
};

export const get_latest_product_transaction_by_location_product = async (
  productId: number,
  locationId: number,
  transactionType: TransactionType
) => {
  const response: AxiosResponse<ITransferLine> = await http.get(
    `products/lines-latest/${transactionType}?`,
    { locationId: locationId, ids: [productId] }
  );
  return response.data;
};
