import http from '@/utils/http.utils';
import { AxiosResponse } from 'axios';
import { ISchedularPayload } from './types';
import { SchedulerStatusType } from './enum';

export const create_update_schedule = async (data: ISchedularPayload) => {
  const response: AxiosResponse = await http.store('/scheduler-order', data);
  return response.data;
};

export const rerun_schedule = async (type: SchedulerStatusType) => {
  const response: AxiosResponse = await http.update('/scheduler/re-run/' + type);
  return response.data;
};
