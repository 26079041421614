import { useQuery, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Card,
  Switch,
  Spin
} from 'antd';
import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import {
  get_product_list,
  get_units_list,
  get_lots_details_bylocationId_productId,
  get_product_list_ids,
  get_product_details,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { approved_adjustments_mutation } from '@/services/adjustments/mutations';
import { ILotDetails, IProductType, IUnits } from '@/services/products/types';
import useDebounce from '@/hooks/useDebounce';
import { ILines, IProductUnits, Line } from '@/services/sell/types';
import {
  get_adjustment_details,
  get_adjustment_line_details,
  get_reasons_list
} from '@/services/adjustments/queries';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import DebounceButton from '@/components/Common/DebounceButton';

const { Option } = Select;

const ApproveAdjustment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { socket } = useContext(WebSocketContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasRule, setHasRule] = useState<boolean>(true);
  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [totalLots, settotalLots] = useState<any>([]);
  //product search
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const { data: reasonList } = useQuery(['reason'], async () => get_reasons_list());
  //   const [pricegroupDropdown, setpricegroupDropdown] = useState<any>([]);
  //   const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  //   const [pricegrouplocationId, setpricegrouplocationId] = useState<any>({});
  const autofocusRef: React.Ref<any> = useRef(null);
  const [fornewreason, setfornewreason] = useState<any>([]);
  const preferenceLocationId = find_locationId_preference();
  const [reasonforall, setReasonforall] = useState<boolean>(false);

  const breadcrumbItems = [{ label: 'Adjustments', link: '/adjustments' }, { label: 'Approve' }];

  const approvedAdjustmentMutation = useMutation(approved_adjustments_mutation);

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['location']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        const locationId = form.getFieldValue(['locationId']);
        let updatedProducts = data.data as { productId: number; locationId: number }[];

        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);

          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  useQuery(['adjustment_pending', id], async () => {
    const adjustmentDetails = await get_adjustment_details(parseInt(id as string));
    const adjustmentLines = await get_adjustment_line_details('PENDING', parseInt(id as string));
    const productsIds = new Set<number>();
    if (adjustmentLines.data.length > 0) {
      for (let i = 0; i < adjustmentLines.data.length; i++) {
        productsIds.add(adjustmentLines.data[i].productId);
      }
    }
    if (Array.from(productsIds).length > 0) {
      const productList = await get_product_list_ids(Array.from(productsIds));
      setProductList(productList?.data?.results);
      await ProductsDB.addProducts(productList.data.results);
    }
    // console.log('Response', response);
    // const linesResponse = await get_transfer_out_lines_details(parseInt(id as string));
    if (adjustmentDetails.data) {
      //locaton name extract
      let netTotalAmount = 0;
      if (adjustmentLines.data.length > 0) {
        const allProducts = [...productList];
        // console.log('allproducts', allProducts);
        const mySet = new Set<number>();

        for (let i = 0; i < adjustmentLines.data.length; i++) {
          delete adjustmentLines.data[i].lotId;
          mySet.add(adjustmentLines.data[i].productId);
          // let findProduct = allProducts.find((value) => value.id == adjustmentLines.data[i].productId);
          let findProduct: any = await ProductsDB.getProduct(adjustmentLines.data[i].productId);
          if (!findProduct) {
            findProduct = await get_product_details(adjustmentLines.data[i].productId);
            allProducts.push(findProduct);
          }
          adjustmentLines.data[i].productName = findProduct.name;
          adjustmentLines.data[i].reasonId = adjustmentDetails.data.reasonId;
          adjustmentLines.data[i].total = adjustmentLines.data[i].totalAmount;
          netTotalAmount += adjustmentLines.data[i].totalAmount;
        }
        await fetchLotsOnLocationandProductChange(
          Array.from(mySet),
          adjustmentDetails.data.locationId,
          'location'
        );
      }
      // console.log('Lines Response data--> ', linesResponse);
      await checkAccountRule(adjustmentDetails.data.locationId);
      form.setFieldsValue({
        ...adjustmentDetails.data,
        lines: adjustmentLines.data,
        grandtotal: netTotalAmount
        // lines: adjustmentLines.data
      });
    }
    setIsLoading(false);
  });

  const checkAccountRule = async (locationId: number) => {
    if (await checkHasAccountRule(locationId, AccountRulesEvent.ADJUSTMENT_CREATE)) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.ADJUSTMENT_CREATE}" rule has not been created!`
      });
    }
  };

  const onFinish = async (values: any) => {
    // console.log('On Finish ', values);
    try {
      setIsLoading(true);
      await approvedAdjustmentMutation.mutateAsync(
        { ...values, id },
        {
          onSuccess: async ({ data }: { data: any }) => {
            if (data) {
              message.success('Adjustment approved sucessfully!');
              navigate('/adjustments');
              setIsLoading(false);
            }
          },
          onError: (e: any) => {
            message.error(`${e.response.data.message}`, 5);
            setIsLoading(false);
          }
        }
      );
    } catch (errors) {
      console.log(errors);
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (!productsIdArray || productsIdArray.length === 0) return;

      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));

          return [...filterLots, ...result];
        });
      } else {
        for (let i = 0; i < productsIdArray.length; i++) {
          const result = await get_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[i]
          );
          currenttotalLots.push(...result);
        }
        settotalLots([...currenttotalLots]);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('location', value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');

    // let currPriceGroups: any = [];
    // if (pricegrouplocationId[value]) {
    //   setpricegroupDropdown(pricegrouplocationId[value]);
    //   currPriceGroups = [...pricegrouplocationId[value]];
    // } else {
    //   const response = await get_price_groups_by_location(value);
    //   let defaultpricegroup = [...defaultPriceGroup];
    //   if (defaultpricegroup.length == 0) {
    //     const response2 = await get_price_groups_by_location('');
    //     setdefaultPriceGroup(response2.data);
    //     defaultpricegroup = [...response2.data];
    //   }
    //   if (response?.data) {
    //     currPriceGroups = [...response.data, ...defaultpricegroup];
    //     setpricegroupDropdown([...response.data, ...defaultpricegroup]);
    //     setpricegrouplocationId((prev: any) => ({
    //       ...prev,
    //       [value]: [...response.data, ...defaultpricegroup]
    //     }));
    //   }
    // }
    // if (data.length == 0) {
    //   return;
    // }
    // if (currPriceGroups.length == 0) {
    //   for (let lineindex = 0; lineindex < data.length; lineindex++) {
    //     form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
    //     form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
    //   }
    // }
    // const foundArray = new Array(data.length).fill(false);
    // // console.log('current price groups', currPriceGroups);
    // for (let ind = 0; ind < currPriceGroups.length; ind++) {
    //   let currDefaultPrice: any = [];
    //   if (pricegroupsgroupId[currPriceGroups[ind].id]) {
    //     currDefaultPrice = pricegroupsgroupId[currPriceGroups[ind].id];
    //   } else {
    //     const response2 = await prices_by_groupId(currPriceGroups[ind].id);
    //     setpricegroupsId((prev: any) => ({
    //       ...prev,
    //       [currPriceGroups[ind].id]: response2.data
    //     }));
    //     currDefaultPrice = response2.data;
    //   }

    //   for (let lineindex = 0; lineindex < data.length; lineindex++) {
    //     if (!foundArray[lineindex]) {
    //       const selectedProductId = data[lineindex].productId;
    //       const selectedUnitId = data[lineindex].unitId;
    //       const findOne = currDefaultPrice.find(
    //         (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
    //       );
    //       if (findOne) {
    //         form.setFieldValue(['lines', lineindex, 'unitPrice'], findOne.sellingPrice);
    //         form.setFieldValue(['lines', lineindex, 'priceGroupId'], findOne.priceGroupId);
    //         foundArray[lineindex] = true;
    //       } else {
    //         form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
    //         form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
    //       }
    //     }
    //   }

    //   const checkallisfound = foundArray.find((curr: any) => !curr);
    //   if (checkallisfound == undefined) {
    //     break;
    //   }
    // }
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const currentLocation = form.getFieldValue(['location']);
    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }

      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => {
            const isExpired = value?.expirationDate
              ? moment().isAfter(value.expirationDate)
              : false;

            return (
              <Option
                value={value.id}
                key={value.id}
                style={{ color: value.qtyAvailable > 0 && !isExpired ? 'green' : 'red' }}>
                {`(${numberDecimalFormatter(
                  value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
                )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                  value?.expirationDate
                    ? new Date(value.expirationDate).toLocaleDateString()
                    : 'N/A'
                }`}
              </Option>
            );
          })}
        </>
      );
    }
  };
  const onProductChange = async (value: number, name?: number) => {
    // form.setFieldValue([name, 'productId'], value);
    // await checkAndGetLot(value);
    setfornewreason([...fornewreason, false]);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['location']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productList) {
      const selectedProduct = productList.find((val) => val.id == value);
      // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    }

    // const selectedUnitId = form.getFieldValue(['lines', data.length - 1, 'unitId']);
    // const selectedProductId = form.getFieldValue(['lines', data.length - 1, 'productId']);
    // const currentLocationId = form.getFieldValue(['location']);
    // if (!currentLocationId) {
    //   message.error('Please select locationId.');
    //   return;
    // }

    // for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
    //   let currDefaultPrice: any = [];
    //   if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
    //     currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
    //   } else {
    //     const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
    //     setpricegroupsId((prev: any) => ({
    //       ...prev,
    //       [pricegroupDropdown[ind].id]: response2.data
    //     }));
    //     currDefaultPrice = response2.data;
    //   }
    //   const findOne = currDefaultPrice.find(
    //     (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
    //   );
    //   if (findOne) {
    //     form.setFieldValue(['lines', data.length - 1, 'unitPrice'], findOne.sellingPrice);
    //     form.setFieldValue(['lines', data.length - 1, 'priceGroupId'], findOne.priceGroupId);
    //     break;
    //   } else {
    //     form.setFieldValue(['lines', data.length - 1, 'unitPrice'], 0);
    //     form.setFieldValue(['lines', data.legth - 1, 'priceGroupId'], null);
    //   }
    // }
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));
  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      location: preferenceLocationId ? preferenceLocationId : null,
      lines: [],
      reason: null
    });
    if (preferenceLocationId) {
      onLocationChange(preferenceLocationId);
    }
    initializeUnits();
  }, []);
  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const checkLotQuantity = (index: number) => {
    const row = form.getFieldValue(['lines', index]);
    const unitInfo: any = allLocalUnits.find((val: any) => row.unitId == val.id);
    if (row.lotId) {
      const filteredLot: any = totalLots.find((value: any) => value.id == row.lotId);
      return {
        quantity: filteredLot.qtyAvailable / unitInfo.baseUnitMultiplier,
        shortName: unitInfo.shortName
      };
    }
    return null;
  };

  const onUnitChange = async (name: number) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    const currentLocationId = form.getFieldValue(['location']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    // for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
    //   let currDefaultPrice: any = [];
    //   if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
    //     currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
    //   } else {
    //     const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
    //     setpricegroupsId((prev: any) => ({
    //       ...prev,
    //       [pricegroupDropdown[ind].id]: response2.data
    //     }));
    //     currDefaultPrice = response2.data;
    //   }
    //   const findOne = currDefaultPrice.find(
    //     (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
    //   );
    //   if (findOne) {
    //     form.setFieldValue(['lines', name, 'unitPrice'], findOne.sellingPrice);
    //     form.setFieldValue(['lines', name, 'priceGroupId'], findOne.priceGroupId);
    //     break;
    //   } else {
    //     form.setFieldValue(['lines', name, 'unitPrice'], 0);
    //     form.setFieldValue(['lines', name, 'priceGroupId'], null);
    //   }
    // }
  };

  const onUnitandQuantityChange = (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total;
    }
    // console.log('grandtotal', grandTotal);

    form.setFieldValue(['lines', 'grandtotal'], numberDecimalFormatter(grandTotal));
    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  //   const onPriceGroupChange = async (val: number) => {
  //     const productInfo = form.getFieldValue(['lines', val]);
  //     // console.log('product Info', productInfo);
  //     let defaultPrice: any = [];
  //     const locationId = form.getFieldValue(['location']);
  //     // console.log('pricegroupsgroupId', pricegroupsgroupId);
  //     if (locationId && productInfo.priceGroupId) {
  //       if (!pricegroupsgroupId[productInfo.priceGroupId]) {
  //         const response2 = await prices_by_groupId(productInfo.priceGroupId);
  //         defaultPrice = [...response2.data];
  //         // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
  //         setpricegroupsId((prev: any) => ({
  //           ...prev,
  //           [productInfo.priceGroupId]: response2.data
  //         }));
  //       } else {
  //         defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
  //       }
  //     }
  //     const alreadySelectedUnitId = form.getFieldValue(['lines', val, 'unitId']);
  //     const findone = defaultPrice.find(
  //       (res2elem: any) =>
  //         res2elem.productId == productInfo.productId && res2elem.unitId == alreadySelectedUnitId
  //     );
  //     if (findone) {
  //       form.setFieldValue(['lines', val, 'unitPrice'], findone.sellingPrice);
  //     } else {
  //       form.setFieldValue(['lines', val, 'unitPrice'], 0);
  //     }
  //   };

  const onSwitchChange = (checked: boolean) => {
    setReasonforall(checked);
    if (!checked) {
      const lines = form.getFieldValue(['lines']);
      for (let ind = 0; ind < lines.length; ind++) {
        form.setFieldValue(['lines', ind, 'reasonId'], undefined);
      }
    }
  };

  const handleReasonforAll = (val: any) => {
    const lines = form.getFieldValue(['lines']);
    for (let ind = 0; ind < lines.length; ind++) {
      form.setFieldValue(['lines', ind, 'reasonId'], val);
    }
  };

  const onLotChange = (val: any, name: number) => {
    console.log('Lot change', val);
    const unitId = form.getFieldValue(['lines', name, 'unitId']);
    const reqquantity = form.getFieldValue(['lines', name, 'quantity']);
    const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
    const lotInfo: any = totalLots.find((cval: any) => cval.id == val);
    if (lotInfo.qtyAvailable / unitInfo.baseUnitMultiplier >= reqquantity) {
      return;
    }
    message.error(
      `Selected Lot(${lotInfo.qtyAvailable / unitInfo.baseUnitMultiplier} ${
        unitInfo.shortName
      }) doesn't have required quantity ${reqquantity} ${unitInfo.shortName}`
    );
    form.setFieldValue(['lines', name, 'lotId'], null);
    return;
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Adjustment Informations"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          //   disabled={isloading}
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className={'grid grid-cols-1 mb-3 md:grid-cols-3 gap-5 mb-5'}>
            <LocationSearch
              disabled={true}
              notAll={true}
              onSelect={onLocationChange}
              formData={{ formName: 'locationId', formLabel: 'Location' }}
            />

            <Form.Item label="Caegory" name="categoryId" hidden>
              <InputNumber />
            </Form.Item>
          </div>
          <Divider />
          <div className="grid grid-cols-4 gap-5 mb-5"></div>
          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Product"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <ProductSearchForLines
                    add2={add2}
                    onProductChange={onProductChange}
                    productList={productList}
                    setProductList={setProductList}
                    productSearch={productSearch}
                    setProductSearch={setProductSearch}
                    autofocusRef={autofocusRef}
                    disabled={true}
                    locationId={form.getFieldValue(['locationId'])}
                  />
                  {fields2.length > 0 && (
                    <>
                      <PageHeader
                        title="All Products"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />
                      <div className="flex gap-2 ml-2">
                        <Switch
                          defaultChecked={false}
                          onChange={onSwitchChange}
                          style={{ width: '2rem' }}
                        />
                        {reasonforall && (
                          <Select
                            placeholder="Select a reason for all!"
                            dropdownMatchSelectWidth={false}
                            allowClear
                            onChange={handleReasonforAll}>
                            {reasonList?.data.map((value: any) => {
                              return (
                                <Option value={value.id} key={value.id}>
                                  {value.name}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-9'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          {/* <Form.Item
                            {...restField2}
                            name={[name2, 'sellingPrice']}
                            hidden></Form.Item> */}
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                onChange={() => {
                                  onUnitChange(name2), onUnitandQuantityChange(name2);
                                }}
                                dropdownMatchSelectWidth={false}
                                allowClear
                                disabled>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                onChange={(val) => onLotChange(val, name2)}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <ReusableQuantity
                            disabled={true}
                            name={name2}
                            restField={restField2}
                            onChangeData={() => onUnitandQuantityChange(name2)}
                            onPressEnterData={(e) => {
                              if (autofocusRef.current) {
                                autofocusRef.current.focus();
                              }
                            }}
                            rules={[
                              { required: true, message: 'Please add a Valid Quantity!' },
                              () => ({
                                validator(_: any, value: any) {
                                  const checked = checkLotQuantity(name2);
                                  // console.log('value', value);
                                  // console.log(quantity);
                                  if (!value) {
                                    return Promise.reject();
                                  }
                                  if (value < 0)
                                    return Promise.reject(`Please Input valid quantity!`);
                                  if (checked == null) {
                                    return Promise.reject(`Please select Lot!`);
                                  }
                                  if (value > checked.quantity)
                                    return Promise.reject(
                                      `Please Input valid quantity! Currently in stock: ${checked.quantity} ${checked.shortName}`
                                    );
                                  return Promise.resolve();
                                }
                              })
                            ]}
                          />
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[{ required: true, message: 'Please add Rate!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              controls={false}
                              min={0}
                              disabled
                              // className="font-bold"
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Reason"
                            name={[name2, 'reasonId']}
                            rules={[{ required: true, message: 'Please select reason!' }]}>
                            <Select
                              placeholder="Select a reason!"
                              dropdownMatchSelectWidth={false}
                              allowClear
                              disabled>
                              {reasonList?.data.map((value: any) => {
                                return (
                                  <Option value={value.id} key={value.id}>
                                    {value.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          {/* {fields2.length > 0 ? (
                            <div className="flex items-center justify-start">
                              <CustomButton
                                backgroundColor="white"
                                text="Remove"
                                textColor="green"
                                // marginTop={responsize ? '10px' : '20px'}
                                onClick={() => {
                                  remove2(name2);
                                  const newArray = fornewreason.filter((curr: any, ind: number) => {
                                    return ind != name2;
                                  });
                                  setfornewreason(newArray);
                                }}
                              />
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>
          <Card style={{ borderRadius: '10px', marginBottom: '10px' }} className="grid grid-cols-2">
            <Form.Item name="grandtotal" label="Grand Total">
              <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
            </Form.Item>
          </Card>
          <Divider />
          <div className="flex justify-end mt-5">
            <Form.Item>
              <DebounceButton type="primary" disabled={!hasRule || isLoading} onClick={form.submit}>
                Approve
              </DebounceButton>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default ApproveAdjustment;
