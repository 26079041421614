import { AxiosRequestConfig, AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICustomerHistoryDataType,
  ICustomerHistoryDownload,
  ICustomerReportType,
  IReport,
  IReportGet,
  IVendorReportType
} from './types';

function buildQuery(data: any) {
  let query = '';
  for (const key in data) {
    if (data[key] === '' || data[key] === undefined || data[key] === null) continue;
    query = query + `&${key}=${data[key]}`;
  }
  return query;
}

export const get_report_list = async () => {
  const response: AxiosResponse<IReport[]> = await http.get('data');
  return response.data;
};

export const get_report_by_id = async (id: number) => {
  const response = await http.get(`data/${id}`);
  return response.data as IReport;
};

export const get_report_with_config = async (payload: IReportGet, config?: AxiosRequestConfig) => {
  const query = buildQuery(payload.payload);
  const response = await http.getWithConfig(`data/${payload.id}?` + query, config);
  return response.data;
};

export const get_report = async (data: any, id: any) => {
  const query = buildQuery(data);
  const response = await http.get(`data/${id}?` + query);
  return response;
};

export const get_report_without_count = async (id: number, data: unknown) => {
  const query = buildQuery(data);
  const response = await http.get(`data/without-count/${id}?` + query);
  return response.data;
};

export async function getRouteReport(data: any) {
  const query = buildQuery(data);
  const response = await http.get('data/custom/route-history?' + query);
  return response.data as ICustomerHistoryDataType[];
}

export const get_report_filter = async (filter: any, id: any) => {
  const response = await http.get(`data/${id}?` + filter);
  return response;
};

export const get_report_string = async (filter: string, id: any) => {
  const response = await http.get(`data/${id}?` + filter);
  return response;
};
export const download_report = async (data: any, sourceid: number) => {
  const query = buildQuery(data);
  const response = await http.get(`data/queue/${sourceid}?` + query);
  return response;
};

export const downloadRouteReport = async (data: any) => {
  const query = buildQuery(data);
  const response = await http.get('data/custom-export/route-history?' + query);
  return response.data as ICustomerHistoryDownload;
};

export const getCustomerVendorHistoryAPI = async (data: any, type: 'customer' | 'vendor') => {
  const query = buildQuery(data);
  const response = await http.get(`data/custom/${type}-history?` + query);

  return response.data as ICustomerReportType[] | IVendorReportType[];
};

export const downloadCustomerVendorHistoryAPI = async (data: any, type: 'customer' | 'vendor') => {
  const query = buildQuery(data);
  const response = await http.get(`data/custom-export/${type}-history?` + query);
  return response.data;
};
