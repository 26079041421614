import { AxiosRequestConfig } from 'axios';
import { httpBase } from './httpbase.utils';
import DOMPurify from 'dompurify';
import { apiWrapper } from './errorModal.utils';

interface IHttpOptions {
  bypassSanitize?: boolean;
  showErrorModal?: boolean;
}

function getWithConfig(endpoint: string, options?: AxiosRequestConfig) {
  if (sanitizeData(options?.params)) {
    return httpBase().get(`${endpoint}`, options);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function get(endpoint: string, params?: unknown) {
  if (sanitizeData(params)) {
    return httpBase().get(`${endpoint}`, { params });
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function store(endpoint: string, payload?: unknown, options?: IHttpOptions) {
  if (sanitizeData(payload) || options?.bypassSanitize) {
    return apiWrapper(httpBase().post(`${endpoint}`, payload), {
      showError: options?.showErrorModal
    });
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function update(endpoint: string, payload?: unknown, options?: IHttpOptions) {
  if (sanitizeData(payload) || options?.bypassSanitize) {
    return apiWrapper(httpBase().patch(`${endpoint}`, payload), {
      showError: options?.showErrorModal
    });
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function updateWithFile(endpoint: string, data?: unknown) {
  if (sanitizeData(data)) {
    return httpBase(true).patch(`${endpoint}`, data);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function saveWithFile(endpoint: string, data?: unknown) {
  if (sanitizeData(data)) {
    return httpBase(true).post(`${endpoint}`, data);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function remove(endpoint: string) {
  return httpBase(true).delete(`${endpoint}`);
}

const httpUtils = {
  get,
  store,
  update,
  updateWithFile,
  saveWithFile,
  remove,
  getWithConfig
};

const sanitizeData = (data?: unknown) => {
  if (data) {
    const jsonString = JSON.stringify(data);
    const sanitizedData = DOMPurify.sanitize(jsonString);
    if (sanitizedData === jsonString) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export default httpUtils;
