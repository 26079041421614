export const excelExportColumns = [
  { dataIndex: 'id', title: 'ID' },
  { dataIndex: 'productName', title: 'Product' },
  { dataIndex: 'unit', title: 'Unit' },
  { dataIndex: 'sellingPrice', title: 'Sell Price' },
  { dataIndex: 'costPrice', title: 'Cost Price' }
];

export const tableExportColumns = excelExportColumns.map((c) => ({
  label: c.title,
  dataIndex: c.dataIndex,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));
