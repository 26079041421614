import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import ActionDropdown from '../Dropdownactions';
import { Button, Menu, message, Tooltip } from 'antd';
import { CustomModal } from '../CustomModal';
import CustomTable, { IColumnDataForCustomTable } from '../CustomResuableInvoice/CustomTable';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';

interface Props {
  columns: IColumnDataForCustomTable[];
  excelColumns: { title: string; dataIndex: string }[];
  data: { total: number; results: any[] };
  title: string;
  isDisabled?: boolean;
}

function ExportCurrent({ columns, data, excelColumns, title, isDisabled }: Props) {
  const dataWithSN = data.results.map((item: any, index: number) => {
    return {
      ...item,
      sn: index + 1
    };
  });

  const printPDFRef = useRef<any>();
  const [openPDFExportModal, setOpenPDFExportModal] = useState(false);

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const handleExport = (type: 'excel' | 'pdf') => {
    const isDataEmpty = data.results.length === 0;
    if (isDataEmpty) {
      message.error('No data to export');
      return;
    }

    if (type === 'pdf') {
      setOpenPDFExportModal(true);
      return;
    }

    exportExcelAutoWidth(excelColumns, data.results, title);
  };

  return (
    <>
      <div className="flex justify-end">
        <div>
          <ActionDropdown
            marginLeft="0px"
            button={true}
            buttonJSX={
              <Button type="primary" className="!rounded-md" disabled={isDisabled}>
                Export
              </Button>
            }
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: () => handleExport('excel')
                  },
                  {
                    key: '2',
                    label: (
                      <Tooltip title="Export PDF" color="blue">
                        <div className="text-center">PDF</div>
                      </Tooltip>
                    ),
                    onClick: () => handleExport('pdf')
                  }
                ]}
              />
            }
          />
        </div>
      </div>

      <CustomModal
        footer={false}
        isModalOpen={openPDFExportModal}
        setIsModalOpen={setOpenPDFExportModal}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable columns={columns} datas={dataWithSN} reff={printPDFRef} title={title} />
        </div>
      </CustomModal>
    </>
  );
}

export default ExportCurrent;
