import { Menu, message, Modal, Spin, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery } from '@tanstack/react-query';

import moment from 'moment';
import cronParser from 'cron-parser';

import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import GenericTable from '@/components/Common/CustomizeTable';
import { get_scheduler_list } from '@/services/schedule/queries';
import { ISchedulerTableData } from '@/services/schedule/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import { useState } from 'react';
import { create_update_schedule } from '@/services/schedule/mutation';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function ScheduleListPage() {
  const { data, isLoading, isFetching, refetch } = useQuery(['schedule'], getSchedularList);
  const [isDisableModal, setIsDisableModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [selectedData, setSelectedData] = useState<ISchedulerTableData>();

  const disableMutation = useMutation(async () => {
    setIsDisableModal(false);
    if (!selectedData) {
      return CustomErrorModal({
        message: 'Something went wrong. Please try again later.'
      });
    }

    await create_update_schedule({
      type: selectedData.type,
      cronExpression: selectedData.cronExpression,
      isDisabled: !selectedData.isDisabled
    });

    setSelectedData(undefined);
    setModalText('');
    message.success('Scheduler updated successfully');
    refetch();
  });

  function onModalOpen(id: number) {
    const selectedData = data?.find((data) => data.id === id);
    if (!selectedData) {
      return CustomErrorModal({
        message: 'Something went wrong. Please try again later.'
      });
    }

    setModalText(
      `Are you sure you want to ${selectedData.isDisabled ? 'enable' : 'disable'} ${
        selectedData.type
      } scheduler?`
    );

    setIsDisableModal(true);
    setSelectedData(selectedData);
  }

  async function getSchedularList() {
    const schedularList = await get_scheduler_list();

    return schedularList.map((schedular) => {
      const nextDate = cronParser.parseExpression(schedular.cronExpression).next().toDate();
      const nextExecution = moment(nextDate).format('YYYY-MM-DD hh:mm:ss a');

      return { ...schedular, nextExecution };
    });
  }
  const isWholeLoading = isLoading || isFetching;

  const columns: ColumnsType<ISchedulerTableData> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 1.5,
      render: (_, __, index) => index + 1
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 6,
      render: (type, record) => <Link to={`/scheduler/view/${record.id}`}>{type}</Link>
    },
    { title: 'CRON Expression', dataIndex: 'cronExpression', width: 4 },
    { title: 'Next Execution', dataIndex: 'nextExecution', width: 6 },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 5,
      render: (createdAt) => moment(createdAt).format(DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: 5,
      render: (updatedAt) => moment(updatedAt).format(DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      dataIndex: 'isDisabled',
      width: 3,
      render: (isDisabled) => (
        <Tag color={isDisabled ? 'red' : 'green'}>{isDisabled ? 'Disabled' : 'Active'}</Tag>
      )
    },
    {
      title: 'Action',
      width: 2,
      key: 'action',
      fixed: 'right',
      render: (record: ISchedulerTableData) => {
        const menuItems = [
          {
            key: '1',
            label: <CustomUpdateIcon link={`/scheduler/${record.id}`} />
          },
          {
            key: '2',
            label: (
              <Tooltip title={record.isDisabled ? 'Enable' : 'Disable'}>
                <div className="flex justify-center" onClick={() => onModalOpen(record.id)}>
                  {!record.isDisabled ? (
                    <EyeOutlined style={{ transform: 'scale(1.2)' }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ transform: 'scale(1.2)' }} />
                  )}
                </div>
              </Tooltip>
            )
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={disableMutation.isLoading}>
      <Modal
        visible={isDisableModal}
        onCancel={() => setIsDisableModal(false)}
        onOk={async () => await disableMutation.mutateAsync()}>
        {modalText}
      </Modal>
      <AppContent
        button={
          <div className="mt-2">
            <CustomButton
              text="Add"
              backgroundColor="#1890ff"
              Linkto="/scheduler/new"
              marginLeft="0"
            />
          </div>
        }
        breadcrumbItems={[{ label: 'Schedule List', link: '/scheduler' }]}>
        <GenericTable
          hideDefaultPagination
          columns={columns}
          scroll={{ x: 1200 }}
          data={data || []}
          isLoading={isWholeLoading}
          tableName="schedular-type-list"
        />
      </AppContent>
    </Spin>
  );
}

export default ScheduleListPage;
