import { ICustomerGroupListData } from '@/services/offfers/types';
import LocalStore, { getTransactionError } from '.';
import { get_customer_group_list_ids } from '@/services/offfers/queries';

// DB Name: CustomerGroups
export default class CustomerGroupsDB extends LocalStore {
  static async addCustomerGroups(data: ICustomerGroupListData[]) {
    if (!CustomerGroupsDB.db) await CustomerGroupsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CustomerGroupsDB.db.transaction('CustomerGroups', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('CustomerGroups');

      data.forEach(async (customerGroup) => {
        const checkKey = objectStore.count(customerGroup.id);
        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          const data = { ...customerGroup, lowercaseName: customerGroup.name.trim().toLowerCase() };
          if (checkKey.result == 0) {
            const request = objectStore.add(data);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await CustomerGroupsDB.updateCustomerGroup(data);
          }
        };
      });
    });
  }

  static async searchCustomerGroup(name: string, limit = 10): Promise<ICustomerGroupListData[]> {
    if (!CustomerGroupsDB.db) await CustomerGroupsDB.init();
    const givenName = name.trim().toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = CustomerGroupsDB.db.transaction('CustomerGroups', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('CustomerGroups');
      const index = objectStore.index('CustomerGroupsSecondIndex');
      const request = index.openCursor();

      const customerGroups: ICustomerGroupListData[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const customerGroup = cursor?.value as ICustomerGroupListData;
          if (customerGroup?.name?.trim()?.toLowerCase()?.includes(givenName)) {
            customerGroups.push(customerGroup);
            count++;
          }

          cursor.continue();
        } else {
          resolve(customerGroups);
        }
      };
    });
  }

  static async getAll(): Promise<ICustomerGroupListData[]> {
    if (!CustomerGroupsDB.db) await CustomerGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CustomerGroupsDB.db.transaction('CustomerGroups', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getCustomerGroups');
      const objectStore = transaction.objectStore('CustomerGroups');
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
    });
  }

  static async getByID(id: number): Promise<ICustomerGroupListData> {
    if (!CustomerGroupsDB.db) await CustomerGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CustomerGroupsDB.db.transaction('CustomerGroups', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getCustomerGroups');
      const objectStore = transaction.objectStore('CustomerGroups');
      const request = objectStore.get(id);

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async updateCustomerGroup(customerGroup: ICustomerGroupListData) {
    if (!CustomerGroupsDB.db) await CustomerGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CustomerGroupsDB.db.transaction('CustomerGroups', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('CustomerGroups');
      const request = objectStore.get(customerGroup.id);

      request.onerror = (event) => getTransactionError(event, reject, 'update');
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(customerGroup);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };
    });
  }

  static async addIfAbsent(ids: number[]): Promise<void> {
    if (!CustomerGroupsDB.db) await CustomerGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CustomerGroupsDB.db.transaction('CustomerGroups', 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('CustomerGroups');
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const customerGroups: ICustomerGroupListData[] = (event.target as IDBRequest).result;
        const customerGroupsIds = customerGroups.map((customerGroup) => customerGroup.id);

        const idsToAdd = ids.filter((id) => {
          const isValidNumber = Boolean(id);
          return isValidNumber && !customerGroupsIds.includes(id);
        });

        if (idsToAdd.length) {
          const response = await get_customer_group_list_ids(idsToAdd);
          await CustomerGroupsDB.addCustomerGroups(response.results);
          resolve(undefined);
        } else resolve(undefined);
      };
    });
  }
}
