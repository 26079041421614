import GenericTable from '@/components/Common/CustomizeTable';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { ILatestProductLineReport } from '@/services/report/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { excelExportColumns, tableExportColumns } from './column.export';

interface Props {
  data: ILatestProductLineReport[];
  pagination: { page: number; size: number };
  onSizeChange: (size: number) => void;
}

function Table({ data, pagination, onSizeChange }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ILatestProductLineReport>>({});

  const handleChange: TableProps<ILatestProductLineReport>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<ILatestProductLineReport>);
  };

  const columns: ColumnsType<ILatestProductLineReport> = [
    {
      title: 'SN',
      key: 'SN',
      width: 30,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      key: 'category_name',
      width: 60,
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null
    },
    {
      title: 'Product',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 120,
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortOrder: sortedInfo.columnKey === 'product_name' ? sortedInfo.order : null
    },
    {
      title: 'Unit',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: 40,
      sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      sortOrder: sortedInfo.columnKey === 'unit_name' ? sortedInfo.order : null
    },

    {
      title: 'Location',
      dataIndex: 'location_name',
      key: 'location_name',
      width: 80,
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      width: 40,
      sorter: (a, b) => {
        return parseFloat(a.quantity) - parseFloat(b.quantity);
      },
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      dataIndex: 'total_amount',
      width: 60,
      sorter: (a, b) => {
        return parseFloat(a.total_amount) - parseFloat(b.total_amount);
      },
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (amount: string) => nepaliNumberFormatter(parseFloat(amount))
    },
    {
      title: 'Vat',
      key: 'vat',
      dataIndex: 'vat',
      width: 40,
      sorter: (a, b) => {
        return parseFloat(a.vat) - parseFloat(b.vat);
      },
      sortOrder: sortedInfo.columnKey === 'vat' ? sortedInfo.order : null,
      render: (amount: string) => nepaliNumberFormatter(parseFloat(amount))
    },
    {
      title: 'Misc',
      key: 'misc',
      dataIndex: 'misc',
      width: 40,
      sorter: (a, b) => {
        return parseFloat(a.misc) - parseFloat(b.misc);
      },
      sortOrder: sortedInfo.columnKey === 'misc' ? sortedInfo.order : null,
      render: (amount: string) => nepaliNumberFormatter(parseFloat(amount))
    },
    {
      title: 'Date',
      key: 'created_at',
      width: 80,
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
      render: (_, record) => (
        <TableCell>
          {convertUTCStringtoLocalString(record.created_at, DEFAULT_DATE_FORMAT)}
        </TableCell>
      )
    }
  ];

  const updatedData = data.map((item) => {
    return {
      ...item,
      created_at: convertUTCStringtoLocalString(item.created_at, DEFAULT_DATE_FORMAT)
    };
  });

  return (
    <div>
      <GenericTable
        columns={columns}
        data={data}
        toSort={handleChange}
        showPager={false}
        scroll={{ x: 1400, y: '75vh' }}
        hideDefaultPagination
        generateSummary
        summaryClassName="text-left"
        pagination={{
          page: pagination.page,
          size: pagination.size,
          total: data.length,
          showSizeChanger: data.length >= 10,
          onPagination(page, currentSize, isSize) {
            if (isSize) onSizeChange(currentSize);
          }
        }}
        buttons={
          <>
            <ExportCurrent
              data={{ total: updatedData.length, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Latest Product Line Report"
            />
          </>
        }
      />
    </div>
  );
}

export default Table;
