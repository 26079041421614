import moment from 'moment';

import { getReportPayload } from '@/services/dashboard/v3/services.dashboard';
import {
  IWidgetCompareGetConfig,
  IWidgetGlobalGetConfig,
  IDateRange,
  IGlobalOptions,
  ICompareOptions,
  ICompareSingleProps,
  IFetchSingleOptions
} from '@/services/dashboard/v3/types';
import { get_report_with_config } from '@/services/report/queries';
import { getDateRangeFromSingle } from '@/utils/getDateRangeFromSingle';
import getGroupedData from '@/utils/getGroupedData';
import { getPreviousRange } from '@/utils/getPrevDateRangeFromCurrent';
import groupByDate, { groupByTime } from '@/utils/groupByDate.utils';
import { capitalize } from 'lodash';
import { getAlignDateRanges } from '@/utils/getAlignDateRanges';
import roundNumber from '@/utils/round.utils';
import { CompareType } from '@/services/dashboard/v3/enums';

async function fetchData({ date, defaultAPIConfig, reportId, ...props }: IFetchSingleOptions) {
  const payload = getReportPayload(defaultAPIConfig, reportId, date);
  if (props.locationId) payload.locationId = props.locationId;
  if (props.reportFormFields) {
    props.reportFormFields.forEach((field) => {
      if (field.value) payload[field.key] = field.value;
    });
  }

  const data = await get_report_with_config(
    { id: reportId, payload },
    { signal: props.abortSignal }
  );

  return data as any[];
}

export async function getGlobalSingleData(props: IWidgetGlobalGetConfig, options: IGlobalOptions) {
  const { date, dateType, formFields, locationId } = props;

  const reportId = options.reportWidget.reportId;
  const reportFormFields = formFields[reportId];

  // Build payload
  const response = await fetchData({
    date,
    defaultAPIConfig: options.defaultAPIConfig,
    reportFormFields,
    reportId,
    locationId,
    abortSignal: options.abortSignal
  });

  if (!options.isPlotAgainstDate) {
    return getGroupedData(response, options.reportWidget.plotAgainst);
  }

  const data = groupByDate(response, options.reportWidget.plotAgainst);
  if (dateType !== 'daily') return data;
  return groupByTime(response, options.reportWidget.plotAgainst);
}

export async function getCompareData(props: IWidgetCompareGetConfig, options: ICompareOptions) {
  const selectedConfig = props.selectedDate;
  const reportId = options.reportWidget.reportId;

  // Extra fields for payload
  const reportFormFields = props.formFields[reportId];
  const dataset = options.reportWidget.field[0];

  if (selectedConfig.key === 'single') {
    const plotAgainst = options.currentFilterField.value;
    const { fields, data } = await getCompareSingleDate(
      {
        firstDate: selectedConfig.firstDate,
        secondDate: selectedConfig.secondDate,
        reportFormFields,
        dataset,
        locationId: props.locationId
      },
      options
    );

    const groupedData = getGroupedData(data, plotAgainst);
    return { fields, data: groupedData, plotAgainst };
  }

  async function getCompareMultiDate(currentDateRange: IDateRange, previousDateRange: IDateRange) {
    const currentResponsePromise = fetchData({
      defaultAPIConfig: options.defaultAPIConfig,
      reportId,
      reportFormFields,
      locationId: props.locationId,
      date: currentDateRange,
      abortSignal: options.abortSignal
    });

    const previousResponsePromise = fetchData({
      defaultAPIConfig: options.defaultAPIConfig,
      reportId,
      reportFormFields,
      locationId: props.locationId,
      date: previousDateRange,
      abortSignal: options.abortSignal
    });

    const response = await Promise.all([currentResponsePromise, previousResponsePromise]);

    const firstDateDataset = generateDataset(currentDateRange, dataset);
    const secondDateDataset = generateDataset(previousDateRange, dataset);

    const currentResponse = response[0].map((item) => ({ ...item, type: 'current' }));
    const previousResponse = response[1].map((item) => ({ ...item, type: 'previous' }));

    const mergedData = [...currentResponse, ...previousResponse];

    const alignedDates = getAlignDateRanges(currentDateRange, previousDateRange);
    const comparedData = compareDataByDates(mergedData, alignedDates, {
      plotAgainst,
      dataset,
      currentDatasetLabel: firstDateDataset,
      previousDatasetLabel: secondDateDataset
    });

    const groupedData = getGroupedData(comparedData, plotAgainst);
    return {
      fields: [firstDateDataset, secondDateDataset],
      data: groupedData,
      plotAgainst: `${selectedFieldName}, Plotted against ${plotAgainst}`
    };
  }

  const plotAgainst = options.reportWidget.plotAgainst;
  const selectedFieldName = selectedConfig.formFieldName;

  if (selectedConfig.key === 'custom-both-range') {
    const { first, second } = selectedConfig.value;
    return getCompareMultiDate(first, second);
  }

  const currentDateRange = selectedConfig.value;
  // Single Range But no compare
  if (selectedConfig.key === 'custom') {
    const data = await fetchData({
      date: currentDateRange,
      defaultAPIConfig: options.defaultAPIConfig,
      reportFormFields,
      reportId,
      locationId: props.locationId,
      abortSignal: options.abortSignal
    });

    const groupedData = groupByDate(data, plotAgainst);
    return {
      fields: [dataset],
      data: groupedData,
      plotAgainst: `${selectedFieldName}, Plotted against ${plotAgainst}`
    };
  }

  // Range Compare
  const previousDateRange = getPreviousRange(currentDateRange[0], currentDateRange[1]);

  if (!selectedConfig.formFieldValue) {
    throw new Error('Please select value from filter');
  }

  return getCompareMultiDate(currentDateRange, previousDateRange);
}

function generateDataset(date: IDateRange, dataset: string) {
  const updatedDate = date.map((d) => moment(d).format('YYYY-MM-DD'));
  return `${capitalize(dataset)} (${updatedDate[0]} / ${updatedDate[1]})`;
}

async function getCompareSingleDate(selectedConfig: ICompareSingleProps, options: ICompareOptions) {
  const reportId = options.reportWidget.reportId;
  const plotAgainst = options.currentFilterField.value;
  const firstDateRange = getDateRangeFromSingle(selectedConfig.firstDate);
  const secondDateRange = getDateRangeFromSingle(selectedConfig.secondDate);

  const currentResponsePromise = fetchData({
    defaultAPIConfig: options.defaultAPIConfig,
    reportId,
    reportFormFields: selectedConfig.reportFormFields,
    locationId: selectedConfig.locationId,
    date: [firstDateRange.startDate, firstDateRange.endDate],
    abortSignal: options.abortSignal
  });

  const previousResponsePromise = fetchData({
    defaultAPIConfig: options.defaultAPIConfig,
    reportId,
    reportFormFields: selectedConfig.reportFormFields,
    locationId: selectedConfig.locationId,
    date: [secondDateRange.startDate, secondDateRange.endDate],
    abortSignal: options.abortSignal
  });

  const response = await Promise.all([currentResponsePromise, previousResponsePromise]);

  const firstDateDataset = `${selectedConfig.dataset} (${selectedConfig.firstDate})`;
  const secondDateDataset = `${selectedConfig.dataset} (${selectedConfig.secondDate})`;

  const firstDateResponse = response[0].map((item) => ({ ...item, type: 'firstDate' }));
  const secondDateResponse = response[1].map((item) => ({ ...item, type: 'secondDate' }));

  const mergedData = [...firstDateResponse, ...secondDateResponse];
  const groupedByProduct = getGroupedData(mergedData, plotAgainst);

  const updatedData = Object.keys(groupedByProduct).map((key) => {
    const data = groupedByProduct[key];
    const productDetails = data[0];
    const firstDateProducts = data.filter((item) => item.type === 'firstDate');
    const secondDateProducts = data.filter((item) => item.type === 'secondDate');

    const firstDateTotalPrice = firstDateProducts.reduce((acc, item) => {
      acc += parseFloat(item[selectedConfig.dataset] || 0);
      return acc;
    }, 0);

    const secondDateTotalPrice = secondDateProducts.reduce((acc, item) => {
      acc += parseFloat(item[selectedConfig.dataset] || 0);
      return acc;
    }, 0);

    return {
      ...productDetails,
      [firstDateDataset]: firstDateTotalPrice,
      [secondDateDataset]: secondDateTotalPrice
    };
  });

  return { fields: [firstDateDataset, secondDateDataset], data: updatedData };
}

function compareDataByDates(
  mergedData: any[],
  alignedDates: { current: string; previous: string }[],
  returnDatasets: {
    currentDatasetLabel: string;
    previousDatasetLabel: string;
    plotAgainst: string;
    dataset: string;
  }
) {
  const { currentDatasetLabel, previousDatasetLabel, plotAgainst, dataset } = returnDatasets;

  const results = alignedDates.map(({ current, previous }) => {
    const currentData = mergedData.filter(
      (item) =>
        item[plotAgainst] &&
        moment(item[plotAgainst]).isSame(current, 'day') &&
        item.type === 'current'
    );

    const previousData = mergedData.filter(
      (item) =>
        item[plotAgainst] &&
        moment(item[plotAgainst]).isSame(previous, 'day') &&
        item.type === 'previous'
    );

    const currentTotal = currentData.reduce((acc, item) => {
      acc += parseFloat(item[dataset] || 0);
      return acc;
    }, 0);

    const previousTotal = previousData.reduce((acc, item) => {
      acc += parseFloat(item[dataset] || 0);
      return acc;
    }, 0);

    return {
      [currentDatasetLabel]: roundNumber(currentTotal),
      [previousDatasetLabel]: roundNumber(previousTotal),
      [plotAgainst]: `${current} / ${previous}`
    };
  });

  return results;
}

export function generateAlertMessageForFilter(compareType: CompareType) {
  switch (compareType) {
    case CompareType.CUSTOM_RANGE:
      return 'View data within a specified range. For example, select a range and a product to see data within that range for that product.';
    case CompareType.CUSTOM_BOTH_RANGE:
      return 'Compare data within two specified ranges of your choice.';
    case CompareType.RANGE_COMPARE:
      return 'Compare ranges such as the last 30 days to the previous 30 days. Select a range, and the system will calculate the corresponding previous range for comparison.';
    case CompareType.SINGLE_COMPARE:
      return 'Compare data from one specific single date to another';
    default:
      return '';
  }
}
