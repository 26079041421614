import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, message } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import Table from './Table';
import {
  download_customer_price_groups,
  get_customer_price_groups_all
} from '@/services/customer-prices/queries';
import CustomerGroupsDB from '@/store/localstorage/CustomerGroupsDB';
import { getCustomerGroup } from '@/services';
import { ICustomerPriceGroups } from '@/services/customer-prices/types';
import CustomerGroupsSearchV2 from '@/components/Common/CustomSearch/Customer/CustomerGroups';
import { delete_customers_price_group_mutation } from '@/services/customer-prices/mutation';

const DEFAULT_SIZE = 100;

function CustomerPriceGroupList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_SIZE });
  const [data, setData] = useState({ total: 0, results: [] as ICustomerPriceGroups[] });

  const [initialFilter, setInitialFilter] = useState('');

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: DEFAULT_SIZE });
    setInitialFilter(filter);
  }

  async function handleDelete(id: number) {
    try {
      setIsLoading(true);
      await delete_customers_price_group_mutation(id);
      getInfo(initialFilter);
      message.success('Price Group has been deleted successfully');
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload(id: number) {
    try {
      setIsLoading(true);
      const url = await download_customer_price_groups(id);
      window.open(url, '_blank');
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(false);
      const response = await get_customer_price_groups_all(filter);

      const groupIds = response.results.map((item) => item.customerGroupId);
      await CustomerGroupsDB.addIfAbsent(groupIds);

      for (let i = 0; i < response.results.length; i++) {
        const current = response.results[i];
        const customerGroup = await getCustomerGroup(current.customerGroupId);
        current.groupName = customerGroup.name;
      }

      setData(response);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = DEFAULT_SIZE, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[{ label: 'Customer Price Groups' }]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onSubmit={onSubmitFilter}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            customerGroupId: '',
            value: '',
            skip: 0,
            count: DEFAULT_SIZE
          }}
          styleforbuttons={'flex justify-end items-center'}
          style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}
          buttons={
            <>
              <CustomButton
                text="Add"
                backgroundColor="#1890ff"
                Linkto="/price-groups/customers/new"
              />
            </>
          }>
          <CustomerGroupsSearchV2 hasParentFormItem={false} name="customerGroupIds[]" showAll />
        </TableFilter>
      }>
      <Table
        data={data}
        isLoading={isLoading}
        pagination={pagination}
        onPagination={onPagination}
        handleDelete={handleDelete}
        handleDownload={onDownload}
      />
    </AppContent>
  );
}

export default CustomerPriceGroupList;
