import { useEffect, useRef, useState } from 'react';
import { FilterFilled } from '@ant-design/icons';
import Table, { ColumnsType, TableProps } from 'antd/lib/table';
import { Checkbox, Dropdown, Form, Menu, Tooltip, Button, Pagination } from 'antd';

import {
  generateTableSummary,
  getFilteredColumns,
  getSummaryRender,
  updateTableFilterPref
} from './services';

interface IPagination {
  page: number;
  total: number;
  size: number;
  onPagination: (pageNo: number, totalSize: number, isSize?: boolean) => void;
  scrollToTop?: boolean;
  showSizeChanger?: boolean;
}

interface Props<T> {
  data: any[];
  columns: ColumnsType<T>;
  toSort?: TableProps<T>['onChange'];
  isLoading?: boolean;
  generateSummary?: boolean;
  tableName?: string;
  buttons?: React.ReactNode;
  scroll?: { x?: string | number | true | undefined; y?: string | number | undefined };
  pagination?: IPagination;
  showPager?: boolean;
  hideDefaultPagination?: boolean;
  excludeSummaryByKeys?: (keyof T)[];

  footer?: TableProps<T>['footer'];
  rowSelection?: TableProps<T>['rowSelection'];
  rowSelectionWidthInPx?: number;
  rowKey?: TableProps<T>['rowKey'];
  summaryClassName?: string;
}

function GenericTable<T extends object>({
  columns,
  data,
  scroll = { x: 1000, y: '75vh' },
  isLoading,
  generateSummary,
  tableName,
  buttons,
  pagination,
  hideDefaultPagination = false,
  showPager = true,
  excludeSummaryByKeys,
  toSort,
  footer,
  rowSelection,
  rowSelectionWidthInPx = 30,
  rowKey = 'id',
  summaryClassName
}: Props<T>) {
  const tableRef = useRef<HTMLDivElement>(null);
  const { initial, filter } = getFilteredColumns(columns, tableName);
  const [filteredColumns, setFilteredColumns] = useState(initial);
  const [paginationSize, setPaginationSize] = useState([10, 20, 50, 100, 500, 2000]);

  const finalColumns =
    tableName && filter.length > 0
      ? columns.filter((value) => {
          if (value.key) return filter.includes(value.key.toString());
        })
      : columns;

  useEffect(() => {
    const newData = [...paginationSize];
    if (pagination && pagination?.total > 2000 && !newData.includes(pagination.total)) {
      newData.push(pagination.total);
    }

    setPaginationSize(newData);
  }, [pagination]);

  const summary = generateSummary
    ? generateTableSummary(data, columns, excludeSummaryByKeys)
    : null;

  function scrollToTop() {
    if (!tableRef.current) return;

    const tableBody = tableRef.current.querySelector('.ant-table-body');
    tableBody?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const filterMenu = (
    <Menu className="ant-dropdown-menu max-h-[20rem] overflow-scroll">
      {columns.map((column) => {
        const colKey = column.key as string;

        return (
          <Menu.ItemGroup key={column.key}>
            <Checkbox
              checked={filteredColumns.includes(colKey)}
              onChange={async (e) => {
                let updatedFilterData: string[] = [];
                if (e.target.checked) {
                  setFilteredColumns((prev) => {
                    updatedFilterData = [...prev, colKey];
                    return updatedFilterData;
                  });
                } else {
                  setFilteredColumns((prev) => {
                    updatedFilterData = prev.filter((val) => val !== column.key);
                    return updatedFilterData;
                  });
                }

                await updateTableFilterPref(updatedFilterData, tableName);
              }}>
              {column.title}
            </Checkbox>
          </Menu.ItemGroup>
        );
      })}
    </Menu>
  );

  return (
    <>
      {(tableName || buttons) && (
        <div className="flex w-full justify-between mb-2">
          <div className="grid grid-cols-5 mt-2">
            {tableName && (
              <Form.Item name="ColumnFilter">
                <Dropdown overlay={filterMenu} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Tooltip title="Table Filter">
                      <Button type="primary" className="!rounded-md" icon={<FilterFilled />}>
                        Filter
                      </Button>
                    </Tooltip>
                  </a>
                </Dropdown>
              </Form.Item>
            )}
          </div>
          {buttons && <div className="flex gap-2 my-2">{buttons}</div>}
        </div>
      )}

      <div ref={tableRef}>
        <Table
          ref={tableRef}
          columns={finalColumns}
          dataSource={data}
          scroll={scroll}
          bordered
          rowKey={rowKey}
          footer={footer}
          onChange={toSort}
          loading={isLoading}
          pagination={hideDefaultPagination ? false : { showSizeChanger: true }}
          rowSelection={
            rowSelection && {
              type: 'checkbox',
              ...rowSelection,
              columnWidth: rowSelectionWidthInPx
            }
          }
          summary={() => {
            if (!summary || Object.keys(summary).length === 0) return null;
            const rowSelectedCOlumn = rowSelection ? [{}, ...finalColumns] : finalColumns;

            return getSummaryRender<T>(rowSelectedCOlumn, summary, filter, summaryClassName);
          }}
        />
      </div>

      {pagination && (
        <div className="flex justify-end mt-4">
          <Pagination
            className={showPager ? '' : 'hide-pager'}
            current={pagination.page}
            total={pagination.total}
            pageSize={pagination.size}
            showSizeChanger={pagination.showSizeChanger}
            pageSizeOptions={paginationSize}
            onChange={(pageNo, pageSize) => {
              pageSize == pagination?.size
                ? pagination.onPagination(pageNo, pageSize)
                : pagination.onPagination(pageNo, pageSize, true);
              if (pagination.scrollToTop) scrollToTop();
            }}
          />
        </div>
      )}
    </>
  );
}

export default GenericTable;
