import { ErrorLog } from './errorModal.utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function getFormattedResponseTime(responseTimeMs: number) {
  // Less than 1 second, show in milliseconds
  if (responseTimeMs < 1000) {
    return `${responseTimeMs} ms`;
  }

  if (responseTimeMs < 60000) {
    return `${(responseTimeMs / 1000).toFixed(2)} sec`;
  }

  return `${(responseTimeMs / 60000).toFixed(2)} min`;
}

async function downloadErrorLogsAsExcel(errorEntry: ErrorLog) {
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Error Details');

  // Define header row with styles
  worksheet.columns = [
    { header: 'Key', key: 'key', width: 34 },
    { header: 'Value', key: 'value', width: 100 }
  ];

  const axiosResponse = errorEntry.errorResponse;
  const serverResponse = axiosResponse?.data || 'N/A';

  const logDetails = [
    { key: 'Start Time', value: errorEntry.startTime.toISOString() },
    { key: 'End Time', value: errorEntry.endTime.toISOString() },
    { key: 'Error Code', value: axiosResponse.status },
    { key: 'Error Message', value: errorEntry.message }
  ];

  // Add data rows
  logDetails.forEach((row) => worksheet.addRow(row));
  worksheet.addRow({});

  const requestDetails = [
    { key: 'Request Headers', value: JSON.stringify(axiosResponse.config.headers) },
    {
      key: 'Request URL',
      value: axiosResponse?.request?.responseURL || axiosResponse.config.url
    },
    { key: 'Request Method', value: axiosResponse.config.method },
    {
      key: 'Request Body',
      value: JSON.stringify(errorEntry.requestData)
    }
  ];

  // Add Request Overview Section
  const requestOverviewRow = worksheet.addRow({ key: 'Request' });
  requestOverviewRow.font = { bold: true, size: 12, color: { argb: 'FF000000' } };
  requestDetails.forEach((row) => worksheet.addRow(row));
  worksheet.addRow({});

  const responseTime = errorEntry.endTime.diff(errorEntry.startTime, 'milliseconds');
  const responseTimeFormatted = getFormattedResponseTime(responseTime);

  const responseDetails = [
    { key: 'Response Status', value: axiosResponse.status },
    { key: 'Response Headers', value: JSON.stringify(axiosResponse.headers) },
    { key: 'Response Time', value: responseTimeFormatted },
    {
      key: 'Response Body',
      value: JSON.stringify(serverResponse, null)
    }
  ];

  // Add Response Overview Section
  const responseOverviewRow = worksheet.addRow({ key: 'Response' });
  responseOverviewRow.font = { bold: true, size: 12, color: { argb: 'FF000000' } };
  responseDetails.forEach((row) => worksheet.addRow(row));

  // Apply styles to the header row
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0078D4' }
    };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
  });

  // Apply alignment and wrapping for data rows
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber !== 1) {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
      });
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const fileName = `error_log_${new Date().toISOString()}.xlsx`;
  saveAs(new Blob([buffer]), fileName);
}

export default downloadErrorLogsAsExcel;
