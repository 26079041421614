import { Navigate, useParams } from 'react-router-dom';
import ScheduleMutationUI from '../ScheduleMutationUI';
import { useQuery } from '@tanstack/react-query';
import { get_scheduler_by_id } from '@/services/schedule/queries';

function UpdateScheduler() {
  const params = useParams();
  const schedularId = parseInt(params.id || '');

  if (isNaN(schedularId)) return <Navigate to="/scheduler" />;

  const { data, isLoading, isFetching } = useQuery([`scheduler${schedularId}`], async () =>
    get_scheduler_by_id(schedularId)
  );

  const isParentLoading = isLoading || isFetching;

  const breadcrumbs = [{ label: 'Schedule List', link: '/scheduler' }, { label: 'Update' }];
  return (
    <ScheduleMutationUI
      data={data}
      uiType="update"
      id={schedularId}
      breadcrumbs={breadcrumbs}
      isParentLoading={isParentLoading}
    />
  );
}

export default UpdateScheduler;
