import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Select, message } from 'antd';

import useDebounce from '@/hooks/useDebounce';
import { IVehicleData } from '@/services/vehicle/types';
import { VehiclesDB } from '@/store/localstorage/VehicleDB';
import { get_vehicles_list_filter_v2 } from '@/services/vehicle/queries';

interface VehiclesSearchProps {
  formData?: {
    formName: any;
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  onSelect?: (value: number) => any;
}

type SearchType = 'initial' | 'more';

function VehiclesSearch({ isAll, required = false, onSelect, formData }: VehiclesSearchProps) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [vehicleSearch, setVehicleSearch] = useState<IVehicleData[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  useEffect(() => {
    searchIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  async function searchIndexDB(value: string) {
    try {
      const vehicles = await VehiclesDB.searchVehicles(value);
      if (!vehicles || vehicles.length == 0) {
        message.error('Cannot find any vehicle with that value in cache, searching in server...');
        search(value, 'initial');
        return;
      }

      setVehicleSearch(vehicles);
      setIsCacheResponse(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function search(value: string, searchType: SearchType) {
    try {
      const currentSkip = searchType === 'initial' ? 0 : skip;

      const { data } = await get_vehicles_list_filter_v2(currentSkip, 10, value);
      const isValidResponse = data && data.vehicles.length > 0;
      if (!isValidResponse) {
        message.error('Cannot find any vehicle with that name!');
        setIsMore(false);
        searchType === 'initial' && setVehicleSearch([]);
      }

      if (isValidResponse) {
        searchType === 'initial'
          ? setVehicleSearch(data.vehicles)
          : setVehicleSearch((prev: any) => [...prev, ...data.vehicles]);

        VehiclesDB.addVehicles(data.vehicles);
        setIsMore(data.vehicles.length >= 10);

        searchType === 'more' && setSkip(currentSkip + 10);
      }

      if (searchType === 'initial') {
        setIsCacheResponse(false);
        setSkip(10);
      }
    } catch (error) {
      message.error(error as string);
    }
  }

  const options = vehicleSearch?.map((value) => (
    <Select.Option key={value.id} value={value.id}>
      {value.number} ({value.name}, {value.imei})
    </Select.Option>
  ));

  const selectMenu = (
    <Select
      showSearch
      allowClear
      filterOption={false}
      placeholder="Search vehicles"
      defaultActiveFirstOption={false}
      onSearch={(val) => setSearchValue(val)}
      notFoundContent={<div>No Content</div>}
      onChange={onSelect}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => search(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => search(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {isAll ? <Select.Option value="">All</Select.Option> : null}
      {options}
    </Select>
  );

  if (!formData) return selectMenu;
  return (
    <Form.Item
      label={formData.label || 'Select Vehicles'}
      name={formData.formName || 'vehicleId'}
      rules={[{ required, message: 'Please select vehicle!' }]}>
      {selectMenu}
    </Form.Item>
  );
}

export default VehiclesSearch;
