import { get_vehicle_by_id } from '@/services/vehicle/queries';
import LocalStore from '.';
import { IVehicleData } from '@/services/vehicle/types';

interface IVehicle extends IVehicleData {
  lowercaseName: string;
}

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} vehicle on indexDB`));
}

export class VehiclesDB extends LocalStore {
  static async addVehicles(vehiclesData: IVehicleData[]) {
    if (!VehiclesDB.db) await VehiclesDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VehiclesDB.db.transaction('Vehicles', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('Vehicles');

      for (const vehicle of vehiclesData) {
        const checkKey = objectStore.count(vehicle.id);
        const vehicleCopy = vehicle as IVehicle;
        vehicleCopy.lowercaseName = vehicleCopy.name.toLowerCase();

        checkKey.onsuccess = async () => {
          if (checkKey.result == 0) {
            const request = objectStore.add(vehicle);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await VehiclesDB.updateVehicle(vehicle);
          }
        };
      }
    });
  }

  static async searchVehicles(name: string, limit = 10): Promise<IVehicleData[]> {
    if (!VehiclesDB.db) await VehiclesDB.init();
    const givenName = name.toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = VehiclesDB.db.transaction('Vehicles', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('Vehicles');
      const index = objectStore.index('VehiclesSecondIndex');
      const request = index.openCursor();

      const vehicles: IVehicle[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const vehicle = cursor.value as IVehicle;
          const lowercase = vehicle?.lowercaseName || vehicle?.name?.toLowerCase();
          const isId = JSON.stringify(vehicle.id).toLowerCase().includes(givenName);
          const isVehicleNumber = String(vehicle.number).toLowerCase().includes(givenName);
          const isVehicleIMEI = String(vehicle.imei).toLowerCase().includes(givenName);

          if (lowercase.includes(givenName) || isId || isVehicleNumber || isVehicleIMEI) {
            vehicles.push(vehicle);
            count++;
          }

          cursor.continue();
        } else {
          resolve(vehicles);
        }
      };
    });
  }

  static async updateVehicle(vehicle: IVehicleData) {
    if (!VehiclesDB.db) await VehiclesDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VehiclesDB.db.transaction('Vehicles', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('Vehicles');
      const request = objectStore.get(vehicle.id);

      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const vechileCopy = vehicle as IVehicle;
          vechileCopy.lowercaseName = vechileCopy.name.toLowerCase();
          const requestUpdate = objectStore.put(vechileCopy);

          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };

      request.onerror = (event) => getTransactionError(event, reject, 'update');
    });
  }

  static async getVehicle(id: number): Promise<IVehicleData> {
    if (!VehiclesDB.db) await VehiclesDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VehiclesDB.db.transaction('Vehicles', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('Vehicles');
      const request = objectStore.get(id);

      request.onsuccess = async (event) => {
        const result = (event.target as IDBRequest).result;
        if (result) resolve(result);

        const vehicle = await get_vehicle_by_id(id);
        if (vehicle) {
          await VehiclesDB.addVehicles([vehicle]);
          resolve(vehicle);
        }
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }
}
