import { useState } from 'react';
import {
  IWidgetConfiguration,
  IGroupedData,
  IDateRange,
  IDateRangeType,
  IFormField,
  IWidgetGetConfig
} from '@/services/dashboard/v3/types';
import getPascalCase from '@/utils/getPascalCase';
import { get_report_with_config } from '@/services/report/queries';
import WidgetMainWrapper from './wrapper/WidgetMainWrapper';
import WidgetCustomizeWrapper from './wrapper/WidgetCustomizeWrapper';
import { getReportGroupedData, getReportPayload } from '@/services/dashboard/v3/services.dashboard';
import { Modal } from 'antd';
import useWindowDimensions from '@/hooks/useWindowDimension';
import { DashboardDB, IDashboardData } from '@/store/localstorage/DashboardDB';
import { useRequestSignal } from '@/hooks/useRequestSignal';

type IReport = { id: number; name: string };

interface IReportGroupData {
  id: number;
  data: any[];
}

interface Props {
  type: 'main' | 'customize';
  widget: IWidgetConfiguration;
  defaultAPIConfig: { locationId: number; page: number; size: number };
  reports: IReport[];
}

function MultipleReportWidget({ widget, defaultAPIConfig, type, reports }: Props) {
  const [data, setData] = useState<IGroupedData | undefined>(undefined);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const requestSignal = useRequestSignal();

  function handleFullScreen() {
    setIsFullScreen((prev) => !prev);
  }

  const { width } = useWindowDimensions();
  const reportWidgets = widget.widgets;
  const reportIds = reportWidgets.map((widget) => widget.reportId);

  // MemoReport when widget changes in customization
  const [memoReport, setMemoReport] = useState<string | undefined>();

  // To store function props
  const [baseMemoProp, setBaseMemoProp] = useState('');

  const fields = reportWidgets.map((widget) => {
    const { field, reportId, plotAgainst, name } = widget;
    const fields = `${field[0]} (${name || reportId})`;
    const label = `${plotAgainst} (${name || reportId})`;
    return { fields, label };
  });

  const chartFields = fields.map((field) => field.fields);
  const xAxisLabel = fields.map((field) => getPascalCase(field.label)).join(', ');

  async function fetchReportData(
    ids: number[],
    formFields: IFormField,
    date: IDateRange,
    defaultAPIConfig: Props['defaultAPIConfig']
  ) {
    const responses = await Promise.all(
      ids.map(async (id) => {
        const reportFormFields = formFields[id];
        const payload = getReportPayload(defaultAPIConfig, id, date);

        if (reportFormFields) {
          reportFormFields.forEach((field) => {
            if (field.value) payload[field.key] = field.value;
          });
        }

        const data = await get_report_with_config({ id, payload }, { signal: requestSignal });
        return { id, data: data as any[] };
      })
    );

    return responses;
  }

  function getGroupedData(data: IReportGroupData[], datatype: IDateRangeType) {
    const processedData: Record<string, any[]> = {};

    reportWidgets.forEach((widget) => {
      const { field, plotAgainst, reportId } = widget;

      // Field 0 because in multiple there is only one field in array
      const newField = field[0];

      const reportData = data.find((d) => d.id === reportId);
      if (!reportData) return;

      const groupedData = getReportGroupedData(reportData.data, plotAgainst, datatype);
      Object.keys(groupedData).forEach((key) => {
        if (!processedData[key]) processedData[key] = [];
        const value = groupedData[key].reduce((acc, curr) => {
          return acc + Number(curr[newField] || 0);
        }, 0);

        const chartField = `${newField} (${widget.name || reportId})`;
        processedData[key].push({ [chartField]: value });
      });
    });

    return processedData;
  }

  async function getConfig(props: IWidgetGetConfig) {
    if (props.type === 'compare') return;
    const { date, dateType, formFields, locationId } = props;

    const config = JSON.stringify(reportIds);
    setBaseMemoProp(JSON.stringify(props));

    if (memoReport !== config) {
      setMemoReport(config);
      setData(undefined);
    }

    let persistSaveData = undefined as IDashboardData | undefined;
    try {
      persistSaveData = await DashboardDB.getReportData(widget.id, props);
    } catch (error) {
      persistSaveData = undefined;
    }

    if (persistSaveData && persistSaveData.type === 'global') {
      setData(persistSaveData.data);
      return;
    }

    const payload = { ...defaultAPIConfig };
    if (locationId) payload.locationId = locationId;
    const reportDatas = await fetchReportData(reportIds, formFields, date, payload);
    const groupedDatas = getGroupedData(reportDatas, dateType);
    setData(groupedDatas);
    DashboardDB.addReportData({
      widgetId: widget.id,
      type: 'global',
      data: groupedDatas,
      props
    });
  }

  async function modalGetConfig(props: IWidgetGetConfig) {
    const config = JSON.stringify(props);
    if (baseMemoProp === config) return;

    await getConfig(props);
  }

  const modalWidth = width > 760 ? '80%' : '100%';

  return (
    <>
      {type === 'main' && (
        <>
          <Modal
            title={widget.title}
            data-no-dnd="true"
            width={modalWidth}
            footer={null}
            visible={isFullScreen}
            bodyStyle={{ paddingTop: 0 }}
            onCancel={() => setIsFullScreen(false)}>
            <WidgetMainWrapper
              key={JSON.stringify(reportIds)}
              data={data}
              widget={{ ...widget, showFilter: false, enlargeChart: false }}
              reports={reports}
              getConfig={modalGetConfig}
              fields={chartFields}
              xAxisLabel={xAxisLabel}
            />
          </Modal>
          <WidgetMainWrapper
            key={JSON.stringify(reportIds)}
            data={data}
            widget={widget}
            reports={reports}
            getConfig={getConfig}
            fields={chartFields}
            xAxisLabel={xAxisLabel}
            handleFullScreen={handleFullScreen}
          />
        </>
      )}

      {type === 'customize' && (
        <>
          <Modal
            title={widget.title}
            data-no-dnd="true"
            width={modalWidth}
            footer={null}
            visible={isFullScreen}
            bodyStyle={{ paddingTop: 0 }}
            onCancel={() => setIsFullScreen(false)}>
            <WidgetCustomizeWrapper
              key={JSON.stringify(reportIds)}
              data={data}
              widget={{ ...widget, showFilter: false, enlargeChart: false }}
              reports={reports}
              getConfig={modalGetConfig}
              fields={chartFields}
              xAxisLabel={xAxisLabel}
            />
          </Modal>

          <WidgetCustomizeWrapper
            key={JSON.stringify(reportIds)}
            data={data}
            widget={widget}
            reports={reports}
            getConfig={getConfig}
            fields={chartFields}
            handleFullScreen={handleFullScreen}
            xAxisLabel={xAxisLabel}
          />
        </>
      )}
    </>
  );
}

export default MultipleReportWidget;
