import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getParsedJSON(data: string) {
  try {
    if (typeof data !== 'string') return data;
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}

export function getDeepCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}
