import { IWidgetConfiguration } from '@/services/dashboard/v3/types';

export const defaultDashboardLayout = [
  {
    id: '23d99272-e748-42eb-9d7c-a0c0e1e82513',
    colSize: 6,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Sell Report',
    widgets: [
      { reportId: 12, name: 'Sell Report', field: ['total_amount'], plotAgainst: 'created_at' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  },
  {
    id: 'f41f50e0-710f-4e03-b69e-b800c3668223',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Adjustment Line Report',
    widgets: [
      { reportId: 30, name: 'Adjustment Line Report', field: ['total_amount'], plotAgainst: 'date' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'bar',
    type: 'report',
    isGradient: true,
    borderRadius: 2,
    enlargeChart: true
  },
  {
    id: '1336d22c-3a1a-4992-a040-1f1a32ea449a',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Purchase Report',
    widgets: [
      { reportId: 28, name: 'Purchase Report', field: ['total_amount'], plotAgainst: 'date' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  },
  {
    id: '2ceac301-ebfb-45ca-aad6-a61b5ff2c412',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: false,
    title: 'Most Sold Product',
    widgets: [
      {
        reportId: 39,
        name: 'Product History Range Report Summed',
        field: ['qty_sold'],
        plotAgainst: 'product_name'
      }
    ],
    showFilter: true,
    showZoom: false,
    chartType: 'pie',
    type: 'report',
    pieChartLimit: 5,
    enlargeChart: true
  },
  {
    id: 'a39752f7-0426-4a41-a290-4817d7a403cb',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: false,
    title: 'Most Purchase Product',
    widgets: [
      {
        reportId: 39,
        name: 'Product History Range Report Summed',
        field: ['qty_purchased'],
        plotAgainst: 'product_name'
      }
    ],
    showFilter: true,
    showZoom: false,
    chartType: 'pie',
    type: 'report',
    pieChartLimit: 5,
    enlargeChart: true
  },
  {
    id: '109bce65-7587-4908-8786-a90fbdc0d113',
    colSize: 6,
    isMultiple: true,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Sell vs Purchase Payment',
    widgets: [
      { field: ['amount'], plotAgainst: 'paidOn', reportId: 13, name: 'Payment Sell Report' },
      { field: ['amount'], plotAgainst: 'paidOn', reportId: 14, name: 'Payment Purchase Report' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  },
  {
    id: '88cc830e-71ce-4136-bd37-78a294e1a62f',
    colSize: 12,
    isMultiple: true,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Sell, Purchase, Adjustment, Stock, Stock Transfer',
    widgets: [
      {
        field: ['total_amount'],
        plotAgainst: 'date',
        reportId: 12,
        name: 'Sell Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'date',
        reportId: 28,
        name: 'Purchase Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'date',
        reportId: 30,
        name: 'Adjustment Line Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'created_at',
        reportId: 57,
        name: 'Opening Stock Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'created_at',
        reportId: 38,
        name: 'Stock Transfer Report'
      }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  }
] as IWidgetConfiguration[];
