import moment from 'moment';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Input,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Checkbox,
  Card,
  Switch
} from 'antd';

import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import {
  get_product_list,
  get_units_list,
  get_lots_details_bylocationId_productId,
  prices_by_groupId,
  get_product_list_ids,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import UnitsDB from '@/store/localstorage/UnitsDB';
import {
  create_adjustments_mutation,
  create_reason_mutation
} from '@/services/adjustments/mutations';
import { IAdjustmentConfirmationData, IAdjustmentpostConfirm } from '@/services/adjustments/types';
import { ILotDetails, IProductType, IUnits } from '@/services/products/types';
import useDebounce from '@/hooks/useDebounce';
import { ILines, IProductUnits, Line } from '@/services/sell/types';
import { get_reasons_list } from '@/services/adjustments/queries';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import {
  find_adjustment_confirmation,
  find_default_product_category,
  find_locationId_preference
} from '@/store/localstorage/preferences';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableAdjustmentConfirm from '@/components/Common/CustomModal/ReusableAdjustmentConfirm';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { TransactionType } from '@/services/products/enums';
import { get_unit_price_prefill } from '@/services/products/services';
import DebounceButton from '@/components/Common/DebounceButton';
import { getProductById } from '@/services';
import ProductbyCategory from '@/components/Common/CustomSearch/Products/ProductsByCategory';
import CustomErrorModal from '@/components/Common/CustomErrorModal';

const { Option } = Select;

const CreateAdjustments = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [totalLots, settotalLots] = useState<any>([]);
  //product search
  const [searchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [createValues, setCreateValues] = useState<IAdjustmentpostConfirm>();
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const { data: reasonList } = useQuery(['reason'], async () => get_reasons_list('status=ACTIVE'));
  const [latestProductLines, setLatestProductLines] = useState<any>({});
  const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  const autofocusRef: React.Ref<any> = useRef(null);
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const [fornewreason, setfornewreason] = useState<any>([]);
  const preferenceLocationId = find_locationId_preference();
  const [reasonforall, setReasonforall] = useState<boolean>(false);
  const [confirmModalValues, setConfirmModalValues] = useState<IAdjustmentConfirmationData>();
  const [modalOpenForConfirmation, setModalOpenForConfirmation] = useState<boolean>(false);

  const [productCategory, setProductCategory] = useState<number | string | null>(
    find_default_product_category()
  );

  const breadcrumbItems = [
    { label: 'Adjustments', link: '/adjustments' },
    { label: 'new', link: '/adjustments/new' }
  ];

  const createadjustmentsMutation = useMutation(create_adjustments_mutation);
  const createreasonsMutation = useMutation(create_reason_mutation);

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['location']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['location']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const locationId = form.getFieldValue(['location']);

        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const onFinish = async (values: any) => {
    try {
      setIsloading(true);
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };

      values.date = JSON.stringify(new Date()).slice(1, -1);
      setCreateValues(values);
      if (find_adjustment_confirmation()) {
        setIsloading(false);
        const modalValues = { ...values, grandtotal: form.getFieldValue(['lines', 'grandtotal']) };
        confirmationPopup(modalValues);
      } else {
        for (let ind = 0; ind < values.lines.length; ind++) {
          if (fornewreason[ind]) {
            if (values.lines[ind].name)
              if (!values.lines[ind].isArchived) {
                values.lines[ind].isArchived = false;
              }
            await createreasonsMutation.mutateAsync(
              { name: values.lines[ind].name, isArchived: values.lines[ind].isArchived },
              {
                onSuccess: async ({ data }: { data: any }) => {
                  message.success('Reason created successfully');
                  values.lines[ind].reasonId = data.id;
                },
                onError: (e: any) => {
                  throw {
                    name: 'ReasonCreationError',
                    message: `${e.response.data.message}`
                  };
                }
              }
            );
          }
          delete values.lines[ind].priceGroupId;
          delete values.lines[ind].productName;
          delete values.lines[ind].sellingPrice;
          delete values.lines[ind].total;
          delete values.lines[ind].hsCode;

          const currentLine = values.lines[ind];
          if (productCategory !== null) {
            if (typeof productCategory === 'number') {
              values.categoryId = productCategory;
            } else {
              const defaultCategory = find_default_product_category();
              if (defaultCategory === null) {
                throw {
                  name: 'CategoryError',
                  message: 'Please select a default Product Category from preferences.'
                };
              } else {
                values.categoryId = defaultCategory;
              }
            }
          } else {
            throw {
              name: 'CategoryError',
              message: 'Please select a Product Category.'
            };
          }

          createadjustmentsMutation.mutateAsync(
            {
              date: values.date,
              categoryId: values.categoryId,
              location: values.location,
              lines: [{ ...currentLine }],
              reasonId: values.lines[ind].reasonId,
              note: values.lines[ind].note
            },
            {
              onSuccess: async ({ data }: { data: any }) => {
                if (data) {
                  // setIsloading(false);
                  message.success('Adjustments created successfully');
                  // navigate('/adjustments');
                }
              },
              onError: (e: any) => {
                // setIsloading(false);
                message.error(`${e.response.data.message}`, 5);
              }
            }
          );
        }
        navigate('/adjustments');
        setfornewreason([]);
      }
      setIsloading(false);
    } catch (errors: any) {
      if ('name' in errors) {
        CustomErrorModal({ message: errors.message, title: errors.name });
      } else message.error('Empty fields found!');
      setIsloading(false);
    }
  };

  const confirmationPopup = (values: IAdjustmentConfirmationData) => {
    //console.log(values);
    setConfirmModalValues(values);
    setModalOpenForConfirmation(true);
  };
  const handleConfirmationModalSubmit = async () => {
    //console.log(createValues);
    try {
      setIsloading(true);
      if (createValues) {
        for (let ind = 0; ind < createValues.lines.length; ind++) {
          if (fornewreason[ind]) {
            if (createValues.lines[ind].name)
              if (!createValues.lines[ind].isArchived) {
                createValues.lines[ind].isArchived = false;
              }
            await createreasonsMutation.mutateAsync(
              {
                name: createValues.lines[ind].name,
                isArchived: createValues.lines[ind].isArchived
              },
              {
                onSuccess: async ({ data }: { data: any }) => {
                  message.success('Reason created successfully');
                  createValues.lines[ind].reasonId = data.id;
                },
                onError: (e: any) => {
                  throw {
                    name: 'ReasonCreationError',
                    message: `${e.response.data.message}`
                  };
                }
              }
            );
          }
          delete createValues.lines[ind].priceGroupId;
          delete createValues.lines[ind].productName;
          delete createValues.lines[ind].sellingPrice;
          delete createValues.lines[ind].total;

          const product = await getProductById(createValues.lines[ind].productId);

          createadjustmentsMutation.mutateAsync(
            {
              date: createValues.date,
              categoryId: product.categoryId,
              location: createValues.location,
              lines: [{ ...createValues.lines[ind] }],
              reasonId: createValues.lines[ind].reasonId,
              note: createValues.lines[ind].note
            },
            {
              onSuccess: async ({ data }: { data: any }) => {
                if (data) {
                  // setIsloading(false);
                  message.success('Adjustments created successfully');
                  // navigate('/adjustments');
                }
              },
              onError: (e: any) => {
                // setIsloading(false);
                message.error(`${e.response.data.message}`, 5);
              }
            }
          );
        }
        navigate('/adjustments');
        setfornewreason([]);
      }
    } finally {
      setIsloading(false);
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length === 0) return;

      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        for (let i = 0; i < productsIdArray.length; i++) {
          const result = await get_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[i]
          );
          currenttotalLots.push(...result);
        }
        settotalLots([...currenttotalLots]);

        // latest price change
        const lines = form.getFieldValue(['lines']);
        if (lines !== undefined) {
          if (lines.length > 0) {
            const latestTransferLinesData = await Promise.all(
              productsIdArray.map(async (val) => {
                const response = await get_unit_price_prefill(val, locationId, [
                  TransactionType.PURCHASE,
                  TransactionType.TRANSFER_IN,
                  TransactionType.OPENING_STOCK
                ]);
                if (response) return response;
              })
            );

            const latestTransferLines = latestTransferLinesData.map((val) => {
              return val?.filtered;
            });

            for (let ind = 0; ind < lines.length; ind++) {
              const currTransferLine = latestTransferLines.find((val) => {
                if (val) return val.productId === lines[ind].productId;
              });
              if (currTransferLine) {
                const selectedUnitId = lines[ind].unitId;
                const value = lines[ind].productId;
                let unitInfo = await UnitsDB.getUnit(currTransferLine.unitId);
                if (!unitInfo) {
                  const allUnits = await get_units_list();
                  UnitsDB.addUnits(allUnits);
                  unitInfo = await UnitsDB.getUnit(currTransferLine.unitId);
                }
                let productInfo = await ProductsDB.getProduct(currTransferLine.productId);
                if (!productInfo) {
                  const allProducts = await get_product_list_ids([currTransferLine.productId]);
                  await ProductsDB.addProducts(allProducts.data.results);
                  productInfo = await ProductsDB.getProduct(currTransferLine.productId);
                }
                let baseUnitPrice;

                if (typeof unitInfo === 'object') {
                  baseUnitPrice = currTransferLine.unitPrice / unitInfo.baseUnitMultiplier;
                } else {
                  throw {
                    name: 'UnitError',
                    message: 'Something went wrong. Please try again.'
                  };
                }

                const decimalBaseUnitPrice = baseUnitPrice;

                let unitSelected = await UnitsDB.getUnit(selectedUnitId);
                if (!unitSelected) {
                  const allUnits = await get_units_list();
                  UnitsDB.addUnits(allUnits);
                  unitSelected = await UnitsDB.getUnit(selectedUnitId);
                }

                let unitPrice;
                if (typeof unitSelected === 'object') {
                  unitPrice = baseUnitPrice * unitSelected.baseUnitMultiplier;
                } else {
                  throw {
                    name: 'UnitError',
                    message: 'Something went wrong. Please try again.'
                  };
                }

                setLatestProductLines((prev: any) => ({
                  ...prev,
                  [value]: { ...currTransferLine, decimalBaseUnitPrice }
                }));
                form.setFieldValue(['lines', ind, 'unitPrice'], unitPrice);
                onUnitandQuantityChange(ind);
              } else {
                form.setFieldValue(['lines', ind, 'unitPrice'], 0);
                onUnitandQuantityChange(ind);
              }
            }
          }
        }
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('location', value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');

    if (data.length == 0) {
      return;
    }
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const currentLocation = form.getFieldValue(['location']);
    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }

      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      const lines = form.getFieldValue(['lines']);
      let grandTotal = 0;
      for (let i = 0; i < lines.length; i++) {
        grandTotal += lines[i].total;
      }
      form.setFieldValue(['lines', 'grandtotal'], numberDecimalFormatter(grandTotal));
      return (
        <>
          {filteredLots?.map((value: any) => {
            const isExpired = value?.expirationDate
              ? moment().isAfter(value.expirationDate)
              : false;

            return (
              <Option
                value={value.id}
                key={value.id}
                style={{ color: value.qtyAvailable > 0 && !isExpired ? 'green' : 'red' }}>
                {`(${numberDecimalFormatter(
                  value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
                )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                  value?.expirationDate
                    ? new Date(value.expirationDate).toLocaleDateString()
                    : 'N/A'
                }`}
              </Option>
            );
          })}
        </>
      );
    }
  };
  const onProductChange = async (value: number, name?: number) => {
    // form.setFieldValue([name, 'productId'], value);
    // await checkAndGetLot(value);
    setfornewreason([...fornewreason, false]);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['location']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productList) {
      const selectedProduct = productList.find((val) => val.id == value);
      // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    }

    const selectedUnitId = form.getFieldValue(['lines', data.length - 1, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', data.length - 1, 'productId']);
    const currentLocationId = form.getFieldValue(['location']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    if (!latestProductLines[value] || latestProductLines[value].locationId !== currentLocationId) {
      const latesttransferlineData = await get_unit_price_prefill(value, currentLocationId, [
        TransactionType.PURCHASE,
        TransactionType.TRANSFER_IN,
        TransactionType.OPENING_STOCK
      ]);

      const latesttransferline = latesttransferlineData.filtered;

      if (latesttransferline) {
        let unitInfo = await UnitsDB.getUnit(latesttransferline.unitId);
        if (!unitInfo) {
          const allUnits = await get_units_list();
          UnitsDB.addUnits(allUnits);
          unitInfo = await UnitsDB.getUnit(latesttransferline.unitId);
        }
        let productInfo = await ProductsDB.getProduct(latesttransferline.productId);
        if (!productInfo) {
          const allProducts = await get_product_list_ids([latesttransferline.productId]);
          await ProductsDB.addProducts(allProducts.data.results);
          productInfo = await ProductsDB.getProduct(latesttransferline.productId);
        }
        let baseUnitPrice;
        if (typeof unitInfo === 'object') {
          baseUnitPrice = latesttransferline.unitPrice / unitInfo.baseUnitMultiplier;
        } else {
          throw {
            name: 'UnitError',
            message: 'Something went wrong. Please try again.'
          };
        }

        const decimalBaseUnitPrice = baseUnitPrice;

        let unitSelected = await UnitsDB.getUnit(selectedUnitId);
        if (!unitSelected) {
          const allUnits = await get_units_list();
          UnitsDB.addUnits(allUnits);
          unitSelected = await UnitsDB.getUnit(selectedUnitId);
        }

        let unitPrice;
        if (typeof unitSelected === 'object') {
          unitPrice = baseUnitPrice * unitSelected.baseUnitMultiplier;
        } else {
          throw {
            name: 'UnitError',
            message: 'Something went wrong. Please try again.'
          };
        }

        setLatestProductLines((prev: any) => ({
          ...prev,
          [value]: { ...latesttransferline, decimalBaseUnitPrice }
        }));

        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], unitPrice);
      }
    } else {
      let unitSelected = await UnitsDB.getUnit(selectedUnitId);
      if (!unitSelected) {
        const allUnits = await get_units_list();
        UnitsDB.addUnits(allUnits);
        unitSelected = await UnitsDB.getUnit(selectedUnitId);
      }

      const unitPrice =
        typeof unitSelected === 'object'
          ? latestProductLines[value].decimalBaseUnitPrice * unitSelected.baseUnitMultiplier
          : latestProductLines[value].decimalBaseUnitPrice * 1;

      form.setFieldValue(['lines', data.length - 1, 'unitPrice'], unitPrice);
      let productInfo = await ProductsDB.getProduct(selectedProductId);
      if (!productInfo) {
        const allProducts = await get_product_list_ids([selectedProductId]);
        await ProductsDB.addProducts(allProducts.data.results);
        productInfo = await ProductsDB.getProduct(selectedProductId);
      }

      form.setFieldValue(['lines', data.length - 1, 'unitId'], selectedUnitId);
    }
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));
  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      location: preferenceLocationId ? preferenceLocationId : null,
      lines: [],
      reason: null
    });
    if (preferenceLocationId) {
      onLocationChange(preferenceLocationId);
    }
    initializeUnits();
  }, []);
  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const checkLotQuantity = (index: number) => {
    const row = form.getFieldValue(['lines', index]);
    const unitInfo: any = allLocalUnits.find((val: any) => row.unitId == val.id);
    if (row.lotId) {
      const filteredLot: any = totalLots.find((value: any) => value.id == row.lotId);
      return {
        quantity: filteredLot.qtyAvailable / unitInfo.baseUnitMultiplier,
        shortName: unitInfo.shortName
      };
    }
    return null;
  };

  const onUnitChange = async (name: number) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    const currentLocationId = form.getFieldValue(['location']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    if (
      latestProductLines[selectedProductId] &&
      latestProductLines[selectedProductId].locationId === currentLocationId
    ) {
      let unitInfo = await UnitsDB.getUnit(selectedUnitId);
      if (!unitInfo) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        unitInfo = await UnitsDB.getUnit(selectedUnitId);
      }

      if (typeof unitInfo === 'object') {
        const unitPrice =
          latestProductLines[selectedProductId].decimalBaseUnitPrice * unitInfo.baseUnitMultiplier;

        form.setFieldValue(['lines', name, 'unitPrice'], unitPrice);
        let productInfo = await ProductsDB.getProduct(selectedProductId);
        if (!productInfo) {
          const allProducts = await get_product_list_ids([selectedProductId]);
          await ProductsDB.addProducts(allProducts.data.results);
          productInfo = await ProductsDB.getProduct(selectedProductId);
        }

        onUnitandQuantityChange(name);
      }
    } else {
      form.setFieldValue(['lines', name, 'unitPrice'], 0);
      onUnitandQuantityChange(name);
    }
  };

  const onUnitandQuantityChange = (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total;
    }
    // console.log('grandtotal', grandTotal);

    form.setFieldValue(['lines', 'grandtotal'], numberDecimalFormatter(grandTotal));
    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  const onPriceGroupChange = async (val: number) => {
    const productInfo = form.getFieldValue(['lines', val]);
    // console.log('product Info', productInfo);
    let defaultPrice: any = [];
    const locationId = form.getFieldValue(['location']);
    // console.log('pricegroupsgroupId', pricegroupsgroupId);
    if (locationId && productInfo.priceGroupId) {
      if (!pricegroupsgroupId[productInfo.priceGroupId]) {
        const response2 = await prices_by_groupId(productInfo.priceGroupId);
        defaultPrice = [...response2.data];
        // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
        setpricegroupsId((prev: any) => ({
          ...prev,
          [productInfo.priceGroupId]: response2.data
        }));
      } else {
        defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
      }
    }
    const alreadySelectedUnitId = form.getFieldValue(['lines', val, 'unitId']);
    const findone = defaultPrice.find(
      (res2elem: any) =>
        res2elem.productId == productInfo.productId && res2elem.unitId == alreadySelectedUnitId
    );
    if (findone) {
      form.setFieldValue(['lines', val, 'unitPrice'], findone.sellingPrice);
    } else {
      form.setFieldValue(['lines', val, 'unitPrice'], 0);
    }
  };

  const onSwitchChange = (checked: boolean) => {
    setReasonforall(checked);
    if (!checked) {
      const lines = form.getFieldValue(['lines']);
      for (let ind = 0; ind < lines.length; ind++) {
        form.setFieldValue(['lines', ind, 'reasonId'], undefined);
      }
    }
  };

  const handleReasonforAll = (val: any) => {
    const lines = form.getFieldValue(['lines']);
    for (let ind = 0; ind < lines.length; ind++) {
      form.setFieldValue(['lines', ind, 'reasonId'], val);
    }
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <CustomModal
        isModalOpen={modalOpenForConfirmation}
        setIsModalOpen={setModalOpenForConfirmation}
        title={'Confirmation'}
        footer={false}>
        <ReusableAdjustmentConfirm
          disabled={isloading}
          data={confirmModalValues}
          handleSubmitClick={handleConfirmationModalSubmit}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Adjustment Informations"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isloading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className={'grid grid-cols-1 mb-3 md:grid-cols-3 gap-5 mb-5'}>
            <LocationSearch
              notAll={true}
              onSelect={onLocationChange}
              formData={{ formName: 'location', formLabel: 'Location' }}
            />
          </div>
          <Divider />
          <div className="grid grid-cols-4 gap-5 mb-5"></div>
          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Product"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <ProductCategorySearch
                      setValue={productCategory}
                      onProductCategoryChange={(val) => {
                        setProductCategory(val);
                        if (val !== 'default') {
                          form.setFieldValue(['lines'], []);
                        }
                      }}
                      isAll={false}
                    />

                    <ProductSearchForLines
                      add2={add2}
                      onProductChange={onProductChange}
                      productList={productList}
                      setProductList={setProductList}
                      productSearch={productSearch}
                      setProductSearch={setProductSearch}
                      autofocusRef={autofocusRef}
                      isProductAlreadyAdded={isProductAlreadyAdded}
                      index={0}
                      validation={productCategory !== null}
                      locationId={form.getFieldValue(['location'])}
                      productCategory={productCategory}
                    />
                  </div>

                  {fields2.length > 0 && (
                    <>
                      <PageHeader
                        title="All Products"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />
                      <div className="flex gap-2 ml-2">
                        <Switch
                          defaultChecked={false}
                          onChange={onSwitchChange}
                          style={{ width: '2rem' }}
                        />
                        {reasonforall && (
                          <Select
                            placeholder="Select a reason for all!"
                            dropdownMatchSelectWidth={false}
                            allowClear
                            onChange={handleReasonforAll}>
                            {reasonList?.data.map((value: any) => {
                              return (
                                <Option value={value.id} key={value.id}>
                                  {value.name}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-9'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'sellingPrice']}
                            hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                onChange={() => {
                                  onUnitChange(name2), onUnitandQuantityChange(name2);
                                }}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                // onChange={onLocationChange}
                                dropdownMatchSelectWidth={false}
                                onChange={(value) => onLotChange(name2, value)}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item {...restField2} label="HS Code" name={[name2, 'hsCode']}>
                            <Input disabled />
                          </Form.Item>
                          {/* <Form.Item
                            {...restField2}
                            name={[name2, 'priceGroupId']}
                            label="Price Group"
                            rules={[
                              {
                                required: false,
                                message: 'Select Price Group'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select price group"
                                dropdownMatchSelectWidth={false}
                                onChange={() => onPriceGroupChange(name2)}
                                allowClear>
                                {pricegroupDropdown?.map((value: any) => (
                                  <Option value={value.id} key={value.id}>
                                    {value.name}
                                  </Option>
                                ))}
                              </Select>
                            }
                          </Form.Item> */}
                          <ReusableQuantity
                            name={name2}
                            restField={restField2}
                            onChangeData={() => onUnitandQuantityChange(name2)}
                            onPressEnterData={(e) => {
                              if (autofocusRef.current) {
                                autofocusRef.current.focus();
                              }
                            }}
                            rules={[
                              { required: true, message: 'Please add a Valid Quantity!' },
                              () => ({
                                validator(_: any, value: any) {
                                  const checked = checkLotQuantity(name2);
                                  // console.log('value', value);
                                  // console.log(quantity);
                                  if (!value) {
                                    return Promise.reject();
                                  }
                                  if (value < 0)
                                    return Promise.reject(`Please Input valid quantity!`);
                                  if (checked == null) {
                                    form.setFieldValue(['lines', name2, 'quantity'], null);
                                    return Promise.reject(`Please select Lot!`);
                                  }
                                  if (value > checked.quantity)
                                    return Promise.reject(
                                      `Please Input valid quantity! Currently in stock: ${checked.quantity} ${checked.shortName}`
                                    );
                                  return Promise.resolve();
                                }
                              })
                            ]}
                          />
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[
                              { required: true, message: 'Please add Rate!' },
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.reject('Please input valid Rate!');
                                  }
                                  if (value == 0) return Promise.reject('Please Input valid Rate!');

                                  return Promise.resolve();
                                }
                              })
                            ]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              controls={false}
                              min={0}
                              disabled
                              // className="font-bold"
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          {fornewreason[name2] ? (
                            <Form.Item
                              label="New Reason Name"
                              name={[name2, 'name']}
                              rules={[{ required: true, message: 'Please add name!' }]}>
                              <Input />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              label="Reason"
                              name={[name2, 'reasonId']}
                              rules={[{ required: true, message: 'Please select reason!' }]}>
                              <Select
                                placeholder="Select a reason!"
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {reasonList?.data.map((value: any) => {
                                  return (
                                    <Option value={value.id} key={value.id}>
                                      {value.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          )}
                          <Checkbox
                            onChange={(value) => {
                              // setIsNewReason(value.target.checked);
                              const newArray: any = fornewreason.map((curr: any, ind: number) => {
                                if (ind == name2) {
                                  return value.target.checked;
                                } else {
                                  return curr;
                                }
                              });
                              setfornewreason(newArray);
                            }}>
                            Create New Reason?
                          </Checkbox>
                          <Form.Item
                            label="Note"
                            name={[name2, 'note']}
                            rules={[{ required: false, message: 'Add note' }]}
                            className="col-span-2">
                            <Input />
                          </Form.Item>
                          {/* <div className="flex justify-center items-center"> */}
                          {fields2.length > 0 ? (
                            // <MinusCircleOutlined
                            //   className="dynamic-delete-button flex justify-center items-center"
                            //   onClick={() => remove2(name2)}
                            // />
                            <div className="flex items-center justify-start">
                              <CustomButton
                                backgroundColor="white"
                                text="Remove"
                                textColor="green"
                                // marginTop={responsize ? '10px' : '20px'}
                                onClick={() => {
                                  remove2(name2);
                                  const newArray = fornewreason.filter((curr: any, ind: number) => {
                                    return ind != name2;
                                  });
                                  setfornewreason(newArray);
                                }}
                              />
                            </div>
                          ) : null}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {fields2.length > 0 ? (
                  <Card
                    style={{ borderRadius: '10px', marginBottom: '10px' }}
                    className="grid grid-cols-2">
                    <Form.Item name={['grandtotal']} label="Grand Total">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                  </Card>
                ) : null}
              </>
            )}
          </Form.List>
          <Divider />

          <div className="flex justify-end mt-5">
            <Form.Item>
              <DebounceButton disabled={isloading} type="primary" onClick={form.submit}>
                Submit
              </DebounceButton>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateAdjustments;
