import { Modal } from 'antd';
import { AxiosResponse } from 'axios';
import getErrorMessage from './getError';
import downloadErrorLogsAsExcel from './downloadErrorLogsAsExcel';
import { getParsedJSON } from '.';
import moment from 'moment';

export interface ErrorLog {
  endpoint: string;
  requestData?: unknown;
  errorResponse: AxiosResponse;
  startTime: moment.Moment;
  endTime: moment.Moment;
  message: string;
}

/**
 * Wrap an API call with error handling, logging, and modal display.
 * @param apiCall - A promise representing the API call.
 * @param options - Options for error handling and modal display.
 */

export async function apiWrapper(
  apiCall: Promise<AxiosResponse>,
  options?: { showError?: boolean }
): Promise<AxiosResponse> {
  const startTime = moment();
  try {
    // Wait for the API call to resolve
    const response = await apiCall;
    return response;
  } catch (error: any) {
    if (options?.showError) {
      const axiosResponse = error?.response as AxiosResponse;

      // Extract endpoint and requestData from the AxiosResponse
      const requestConfig = axiosResponse?.config;
      const endpoint = requestConfig?.url ?? 'Unknown endpoint';
      const requestData = getParsedJSON(requestConfig?.data) ?? undefined;

      const errorEntry: ErrorLog = {
        endpoint,
        requestData: requestData,
        errorResponse: axiosResponse,
        startTime,
        endTime: moment(),
        message: error.message
      };

      const errors = axiosResponse?.data?.message || getErrorMessage(error);

      let content: React.ReactNode = errors;
      if (Array.isArray(errors)) {
        content = (
          <ul className="pl-5">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );
      }

      Modal.error({
        title: 'Error',
        width: 600,
        closable: true,
        onOk: (closeFn) => {
          downloadErrorLogsAsExcel(errorEntry);
          return false;
        },
        content,
        okText: 'Download Logs'
      });
    }

    throw error;
  }
}
