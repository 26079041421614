import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { ESchedularSuccessStatus, SchedulerStatusType } from '@/services/schedule/enum';
import { ISchedulerResponse, ISchedulerStatus } from '@/services/schedule/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { Menu, Tag, Tooltip } from 'antd';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  isLoading?: boolean;
  data: ISchedulerResponse;
  pagination: { page: number; size: number };
  onRerun: (type: SchedulerStatusType, isFailed: boolean) => void;
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

const color = {
  [ESchedularSuccessStatus.SUCCESS]: 'green',
  [ESchedularSuccessStatus.FAIL]: 'red',
  [ESchedularSuccessStatus.RUNNING]: 'blue'
};

function Table({ isLoading, data, pagination, onPagination, onRerun }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ISchedulerStatus>>({});

  const handleChange: TableProps<ISchedulerStatus>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<ISchedulerStatus>);
  };

  const columns: ColumnsType<ISchedulerStatus> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 12,
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null
    },
    {
      title: 'Runned At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 7,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 5,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      render: (text: ESchedularSuccessStatus) => <Tag color={color[text]}>{text}</Tag>
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (_, record) => {
        return (
          <ActionDropdown
            menu={
              <Menu>
                <Menu.Item
                  key="view"
                  onClick={() =>
                    onRerun(record.type, record.status === ESchedularSuccessStatus.FAIL)
                  }>
                  <Tooltip title="Rerun Schedule">Rerun</Tooltip>
                </Menu.Item>
              </Menu>
            }
          />
        );
      }
    }
  ];

  return (
    <GenericTable
      columns={columns}
      data={data.results}
      isLoading={isLoading}
      hideDefaultPagination
      tableName={'scheduler-status-list'}
      pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
      toSort={handleChange}
    />
  );
}

export default Table;
