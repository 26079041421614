import { IClickValueTypes } from '../../services/source/types';
import { ACLTypes } from '../../routes/acl';
import DashboardIcon from '../icons/Dashboard.svg';
import UserIcon from '../icons/Users.svg';
import ProductIcon from '../icons/Products.svg';
import OfferIcon from '../icons/Offers.svg';
import SellIcon from '../icons/Sell.svg';
import PurchaseIcon from '../icons/Purchases.svg';
import AdjustmentIcon from '../icons/Adjustments.svg';
import ReportIcon from '../icons/Reports.svg';
import TaskIcon from '../icons/Tasks.svg';
import ChannelIcon from '../icons/Channel.svg';
import RoutesIcon from '../icons/Routes.svg';
import SettingIcon from '../icons/Settings.svg';
import LocationIcon from '../icons/Location.svg';
import {
  AccountBookOutlined,
  BankOutlined,
  BookOutlined,
  BranchesOutlined,
  CalendarOutlined,
  CarOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  InboxOutlined,
  LineChartOutlined,
  MoneyCollectOutlined,
  PayCircleOutlined,
  SwapOutlined,
  TransactionOutlined,
  UnlockOutlined
} from '@ant-design/icons';

import { IoCreateOutline, IoRadioOutline } from 'react-icons/io5';
import { PiToolboxLight } from 'react-icons/pi';

import DashboardIconWrapper from '@/components/Common/DashboardIconWrapper';

export interface MenuItemsType {
  key: string;
  title: string;
  icon?: JSX.Element;
  label: JSX.Element;
  keyword: string;
  onClick?: (val: IClickValueTypes) => void;
  children?: MenuItemsType[];
}

export interface IMenuMisc {
  key: string;
  icon?: JSX.Element;
  aclCheck?: ACLTypes;
}

export const menuItemsMisc: IMenuMisc[] = [
  {
    key: 'dashboard',
    icon: <img src={DashboardIcon} className="menu-icons" />
  },
  {
    key: 'create',
    icon: <DashboardIconWrapper icon={IoCreateOutline} />
  },
  {
    key: 'tracking',
    icon: <DashboardIconWrapper icon={IoRadioOutline} />
  },
  {
    key: 'utility',
    icon: <DashboardIconWrapper icon={PiToolboxLight} />
  },
  {
    key: 'inventory',
    icon: <ContainerOutlined className="menu-icons" />
  },
  {
    key: 'inventory-report',
    icon: <ContainerOutlined className="menu-icons" />
  },
  {
    key: 'opening-stock-entry',
    icon: <InboxOutlined className="menu-icons" />
  },
  {
    key: 'transaction',
    icon: <DashboardIconWrapper icon={DollarOutlined} />
  },
  {
    key: 'userManagement',
    icon: <img src={UserIcon} className="menu-icons" />
  },
  {
    key: 'hrManagement',
    icon: <img src={UserIcon} className="menu-icons" />
  },
  {
    key: 'cronSchedule',
    icon: <FieldTimeOutlined className="menu-icons" />,
    aclCheck: 'ADMIN'
  },
  {
    key: 'wallet',
    icon: <MoneyCollectOutlined className="menu-icons" />
  },
  {
    key: 'logistic',
    icon: <CarOutlined className="menu-icons" />
  },
  {
    key: 'locations',
    icon: <img src={LocationIcon} className="menu-icons" />
  },
  {
    key: 'bank',
    icon: <BankOutlined className="menu-icons" />
  },
  {
    key: 'session',
    icon: <FieldTimeOutlined className="menu-icons" />
  },
  {
    key: 'accounts',
    icon: <TransactionOutlined className="menu-icons" />
  },
  {
    key: 'products',
    icon: <img src={ProductIcon} className="menu-icons" />
  },
  {
    key: 'product-report',
    icon: <img src={ProductIcon} className="menu-icons" />
  },
  {
    key: 'offers',
    icon: <img src={OfferIcon} className="menu-icons" />
  },
  {
    key: 'sell',
    icon: <img src={SellIcon} className="menu-icons" />
  },
  {
    key: 'sales-report',
    icon: <img src={SellIcon} className="menu-icons" />
  },
  {
    key: 'purchases',
    icon: <img src={PurchaseIcon} className="menu-icons" />
  },
  {
    key: 'purchase-report',
    icon: <img src={PurchaseIcon} className="menu-icons" />
  },
  {
    key: 'vat-report',
    icon: <BankOutlined className="menu-icons" />
  },
  {
    key: 'ird',
    icon: <BankOutlined className="menu-icons" />
  },
  {
    key: 'expense',
    icon: <MoneyCollectOutlined className="menu-icons" />
  },
  {
    key: 'others-report',
    icon: <FileTextOutlined className="menu-icons" />
  },
  {
    key: 'logs',
    icon: <FileTextOutlined className="menu-icons" />
  },
  {
    key: 'adjustments',
    icon: <img src={AdjustmentIcon} className="menu-icons" />
  },
  {
    key: 'transfers',
    icon: <SwapOutlined className="menu-icons" />
  },
  {
    key: 'reports',
    icon: <img src={ReportIcon} className="menu-icons" />
  },
  {
    key: 'tasks',
    icon: <img src={TaskIcon} className="menu-icons" />
  },
  {
    key: 'tickets',
    icon: <img src={ChannelIcon} className="menu-icons" />
  },
  {
    key: 'routes',
    icon: <img src={RoutesIcon} className="menu-icons" />
  },
  {
    key: 'epay',
    icon: <PayCircleOutlined className="menu-icons" />
  },
  {
    key: 'settings',
    icon: <img src={SettingIcon} className="menu-icons" />
  },
  {
    key: '/acl',
    aclCheck: 'ADMIN'
  },
  {
    key: '/users',
    aclCheck: 'READ_USER'
  },
  {
    key: '/users/user-type',
    aclCheck: 'READ_USER'
  },
  {
    key: '/users/vendors',
    aclCheck: 'READ_VENDOR'
  },
  {
    key: '/vendor-type',
    aclCheck: 'READ_VENDOR_TYPE'
  },
  {
    key: '/users/customers',
    aclCheck: 'READ_CUSTOMER'
  },
  {
    key: '/offer/customer-group',
    aclCheck: 'READ_CUSTOMER_GROUP'
  },
  {
    key: 'wallet',
    aclCheck: 'READ_WALLET'
  },
  {
    key: '/users/all-live',
    aclCheck: 'READ_USER'
  },
  {
    key: '/vehicle',
    aclCheck: 'READ_VEHICLE'
  },
  {
    key: '/bank',
    aclCheck: 'READ_BANK'
  },
  {
    key: '/session',
    aclCheck: 'ADMIN'
  },
  {
    key: '/session/money',
    aclCheck: 'ADMIN'
  },
  {
    key: '/settings/global',
    aclCheck: 'CREATE_GLOBAL_SETTINGS'
  },
  {
    key: '/products',
    aclCheck: 'READ_PRODUCT'
  },
  {
    key: '/products/archive',
    aclCheck: 'READ_PRODUCT'
  },
  {
    key: '/products/category',
    aclCheck: 'READ_PRODUCT_CATEGORY'
  },
  {
    key: '/products/lots',
    aclCheck: 'READ_PRODUCT_LOTS'
  },
  {
    key: '/products/previous-lots',
    aclCheck: 'READ_PRODUCT_LOTS'
  },
  {
    key: '/products/convert-lots',
    aclCheck: 'CONVERT_LOTS'
  },
  {
    key: '/products/totallots',
    aclCheck: 'READ_PRODUCT_LOTS'
  },
  {
    key: '/price-groups-new',
    aclCheck: 'CREATE_PRICE_GROUP'
  },
  {
    key: '/price-groups-upload',
    aclCheck: 'CREATE_PRICE_GROUP'
  },
  {
    key: '/price-groups-download',
    aclCheck: 'READ_PRICE_GROUP'
  },
  {
    key: '/offer/discount',
    aclCheck: 'READ_DISCOUNT'
  },
  {
    key: '/offer/discount-product',
    aclCheck: 'READ_DISCOUNT'
  },
  {
    key: '/offer/discount-category',
    aclCheck: 'READ_DISCOUNT'
  },
  {
    key: '/sell',
    aclCheck: 'READ_SELL'
  },
  {
    key: '/sell/order',
    aclCheck: 'READ_SELL_ORDER'
  },
  {
    key: '/sell/return',
    aclCheck: 'READ_SELL_RETURN'
  },
  {
    key: '/sell/pos-create-v2',
    aclCheck: 'CREATE_POS'
  },
  {
    key: '/sell/sell-list',
    aclCheck: 'READ_SELL'
  },
  {
    key: '/purchase',
    aclCheck: 'READ_PURCHASE'
  },
  {
    key: '/purchase/purchase-list',
    aclCheck: 'READ_PURCHASE'
  },
  {
    key: '/purchase/purchase-list/view',
    aclCheck: 'READ_PURCHASE'
  },
  {
    key: '/sell-estimate-vs-purchase-demand'
  },
  {
    key: '/ird/materialized-report',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird/sales-register',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird/sales-return-register',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird/purchase-register',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird/purchase-return-register',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird/logs',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird-logs/events',
    aclCheck: 'ADMIN'
  },
  {
    key: '/ird/vat-accounts',
    aclCheck: 'ADMIN'
  },
  {
    key: '/expense',
    aclCheck: 'READ_EXPENSE'
  },
  {
    key: '/expense-category',
    aclCheck: 'READ_EXPENSE_CATEGORY'
  },
  {
    key: '/logs',
    aclCheck: 'READ_LOGS'
  },
  {
    key: '/log-events',
    aclCheck: 'READ_LOGS'
  },
  {
    key: '/logs/backup',
    aclCheck: 'READ_LOGS'
  },
  {
    key: '/adjustments',
    aclCheck: 'READ_ADJUSTMENT'
  },
  {
    key: 'adjustments/reasons',
    aclCheck: 'READ_REASON'
  },
  {
    key: '/transfers',
    aclCheck: 'READ_TRANSFER'
  },
  {
    key: '/transfer-in',
    aclCheck: 'READ_TRANSFER'
  },
  {
    key: '/channel',
    aclCheck: 'READ_CHANNEL'
  },
  {
    key: '/channel/closed',
    aclCheck: 'READ_CHANNEL'
  },
  {
    key: '/channel/reasons',
    aclCheck: 'READ_REASON'
  },
  {
    key: '/routes',
    aclCheck: 'READ_ROUTE'
  },
  {
    key: '/fonepay',
    aclCheck: 'ADMIN'
  },
  {
    key: '/accounts/financial-year',
    icon: <CalendarOutlined className="menu-icons" />
  },
  {
    key: '/accounts-rule',
    aclCheck: 'READ_ACCOUNT',
    icon: <BookOutlined className="menu-icons" />
  },
  {
    key: '/accounts',
    aclCheck: 'READ_ACCOUNT',
    icon: <TransactionOutlined className="menu-icons" />
  },
  {
    key: '/accounts/opening',
    aclCheck: 'READ_ACCOUNT',
    icon: <UnlockOutlined className="menu-icons" />
  },
  {
    key: '/accounts/journal',
    aclCheck: 'READ_ACCOUNT',
    icon: <AccountBookOutlined className="menu-icons" />
  },
  {
    key: '/accounts/receive-payable',
    aclCheck: 'READ_ACCOUNT',
    icon: <DollarCircleOutlined className="menu-icons" />
  },
  {
    key: '/accounts/journal-lines',
    aclCheck: 'READ_ACCOUNT',
    icon: <BankOutlined className="menu-icons" />
  },
  {
    key: '/accounts/detailed-ledger',
    aclCheck: 'READ_ACCOUNT',
    icon: <AccountBookOutlined className="menu-icons" />
  },
  {
    key: '/accounts/new-journal-lines',
    aclCheck: 'READ_ACCOUNT',
    icon: <CreditCardOutlined className="menu-icons" />
  },
  {
    key: '/accounts/view',
    aclCheck: 'READ_ACCOUNT',
    icon: <TransactionOutlined className="menu-icons" />
  },
  {
    key: '/ledger',
    aclCheck: 'READ_ACCOUNT',
    icon: <CreditCardOutlined className="menu-icons" />
  },
  {
    key: '/trial-balance',
    aclCheck: 'READ_ACCOUNT',
    icon: <BranchesOutlined className="menu-icons" />
  },
  {
    key: '/profit-loss',
    aclCheck: 'READ_ACCOUNT',
    icon: <LineChartOutlined className="menu-icons" />
  },
  {
    key: '/balance-sheet',
    aclCheck: 'READ_ACCOUNT',
    icon: <CreditCardOutlined className="menu-icons" />
  }
];
